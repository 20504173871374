import React, { Component, useState, useEffect } from 'react';
import {
  FormGroup, Label, Button,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  Row
} from 'reactstrap';
import { ModelPropertiesParser } from '../commons/modelPropertiesParser';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';
import JSONPath from 'jsonpath';
import User from '../commons/user/index';
import Avatar from 'react-avatar';
import Gauge from 'react-svg-gauge';
import TextStyleResolver from './TextStyleResolver/index';
import RichhTextRenderer from './RichhTextRenderer';
import RenderIcon from '../commons/RenderIcon';
import PreviewFile from '../commons/PreviewFile';
import * as Elements from './index';
import { getFormValues } from 'redux-form/immutable';
import { connect } from 'react-redux';
import { toJS } from '../../containers/to-js';
import config from '../../config';
import { save, updateWorkflowData } from '../../actions/workflow-item';
import RatingComp from 'react-rating';
import moment from 'moment';
import ReactPlayer from 'react-player';
import client from '../../client';
import OtpInput from 'react-otp-input';
import { fromJS } from 'immutable';

import DataStore from '../../utils/DataStore'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'


const NotAvailable = props => {
  return <span className='text-dark'>Not Available</span>;
};

const buildTextStyleConfig = itemProperties => {
  let config = null;
  if (
    itemProperties.hasOwnProperty('contextual_text_color') &&
    itemProperties.contextual_text_color !== null
  ) {
    config = {
      contextual_text_color: itemProperties.contextual_text_color,
    };
  }
  return config;
};

const DefaultText = props => {
  const {
    data,
    inlineNextState,
    layoutItem: { itemProperties },
    workflow: { currentItem: { layoutProperties}},
    customIcons
  } = props;
  const layoutItemStyle = layoutProperties && layoutProperties.layoutItemStyle && layoutProperties.layoutItemStyle;
  if (inlineNextState && inlineNextState.isLoading) {
    return <p className='text-muted'>Loading...</p>;
  }
  const { beforeIcon, textColor, textAlignment } = itemProperties;
  let additionalClassName = textColor ? `text-${textColor.value}` : '';
  if (textAlignment) additionalClassName += ` text-${textAlignment.value}`;
  if (beforeIcon) {
    return (
      <div className={additionalClassName}>
        <span className='mr-2'>
          <RenderIcon customIcon={customIcons} config={beforeIcon} />
        </span>
        <TextStyleResolver
          text={data}
          config={buildTextStyleConfig(itemProperties)}
          customIcons={customIcons}
          showAsBadge={layoutItemStyle === 'rowWise'}
        />
      </div>
    );
  }
  return (
    <div className={additionalClassName}>
      <TextStyleResolver
        text={data}
        config={buildTextStyleConfig(itemProperties)}
        customIcons={customIcons}
        showAsBadge={layoutItemStyle === 'rowWise'}
      />
    </div>
  );
};

const PrintUser = props => {
  const { data, users } = props;
  if (users && users.data && users.data.data && users.data.data.length) {
    const matchedUser = users.data.data.filter(
      user => user.id === data || (data && user.id === data.id)
    );
    let user = null;
    if (matchedUser && matchedUser.length) {
      user = matchedUser[0];
      return (
        <div className='mt-2'>
          <User user={user} displayMode='SingleLine' />
        </div>
      );
    }
  }
  return <p className='text-muted'>User details not available</p>;
};

const PrintRichtext = props => {
  const { data, ...rest } = props;
  const {
    layoutItem: { itemProperties },
    placeholder,
  } = props;
  if (!data) {
    if (placeholder && placeholder.show) {
      return <div>{placeholder.text}</div>;
    } else {
      return (
        <div>
          <NotAvailable />
        </div>
      );
    }
  }
  const { beforeIcon, textColor, textAlignment } = itemProperties;
  let additionalClassName = textColor ? `text-${textColor.value}` : '';
  if (textAlignment) additionalClassName += ` text-${textAlignment.value}`;
  if (beforeIcon) {
    return (
      <div className={additionalClassName}>
        <span className='mr-2'>
          <RenderIcon config={beforeIcon} />
        </span>
        <RichhTextRenderer fromSession={true} rawContent={data} {...rest} />
      </div>
    );
  }
  return <RichhTextRenderer fromSession={true} rawContent={data} {...rest} />;
};

const PrintEmailWithAvatar = props => {
  const { data } = props;
  return (
    <div >
      <div>{data}</div>
    </div>
  );
};

const PrintDecimalOrCalculatedNumberorNumber = props => {
  const { data } = props;
  const {
    layoutItem: { itemProperties },
  } = props;
  const enableCommaSep = itemProperties && itemProperties.decimalConfig && itemProperties && itemProperties.decimalConfig.enableCommaSep ? itemProperties && itemProperties.decimalConfig :itemProperties && itemProperties.calculatedNumberConfig && itemProperties && itemProperties.calculatedNumberConfig.enableCommaSep ? itemProperties && itemProperties.calculatedNumberConfig :null;
  const commaNumber = require('comma-number')
  const ValueAfterCommaSeprate = commaNumber(data)
  return (
    <div >
      <div>{enableCommaSep ? ValueAfterCommaSeprate:data}</div>
    </div>
  );
};

const PrintFile = props => {
  const { data, ...rest } = props;
  if (Array.isArray(data)) {
    return data.map((file, index) => {
      return <PreviewFile key={index} data={file} {...rest} />;
    });
  }
  return <PreviewFile {...props} />;
};

const PrintPercentage = props => {
  const { data } = props;
  const style = {
    marginTop: '-40px',
  };
  const getHexColor = value => {
    let string = value.toString(16);
    return string.length === 1 ? '0' + string : string;
  };
  let red = Math.floor(255 - data * 2.55);
  let green = Math.floor(data * 2.55);
  let blue = 0;
  let colorHex =
    '#' + getHexColor(red) + getHexColor(green) + getHexColor(blue);
  //TODO: Configure height and width
  return (
    <div style={style}>
      <Gauge
        value={data}
        width={200}
        height={160}
        label=''
        color={colorHex}
        topLabelStyle
      />
    </div>
  );
};

const PrintUrl = props => {
  const {
    data,
    layoutItem: { itemProperties },
    showLabelOnly
  } = props;
  const { beforeIcon, textColor, showinline, urlConfig } = itemProperties;

  const label  = props.label&& props.label.show ? props.label.text : null
  const displayLabel = showLabelOnly && label ? label : props.data 
  if (beforeIcon) {
    return (
      <div className={textColor ? `text-${textColor.value}` : ''}>
        <span className='mr-2'>
          <RenderIcon config={beforeIcon} />
        </span>
        <a href={props.data} target='_blank'>
          <i className='fa fa-external-link' area-hidden='true'></i>
          {'  '}
          {displayLabel}
        </a>
      </div>
    );
  }
  if (showinline || (urlConfig && urlConfig.showinline)) {
    let url = props.data;
    if (url) {
      const globalSearch = DataStore.get("globalSearch");
      if (globalSearch) {
        if (url.indexOf('?') == -1) url = url + "qs=" + globalSearch;
        else url = url + "&qs=" + globalSearch;
      }
    }
    return (
      url ? <div className={textColor ? `text-${textColor.value} readone-url-li` : 'readone-url-li'}>
        <iframe
          src={url}
          width='100%'
          height={`${window.innerHeight}`}
        ></iframe>
      </div> : null
    );
  }
  return (
    <a
      href={props.data}
      target='_blank'
      className={textColor ? `text-${textColor.value}  readone-url-li` : ' readone-url-li'}
    >
      <i className='fa fa-external-link' area-hidden='true'></i>
      {'  '}
      {displayLabel}
    </a>
  );
};

const LocationMapWithCurrentLocation = withScriptjs(
  withGoogleMap(props => {
    const { currentLocationdata } = props;
    const dataTemp = currentLocationdata && currentLocationdata.split(',');
    const coordinates = {
      lat: parseFloat(dataTemp[0]),
      lng: parseFloat(dataTemp[1]),
    };
    const customMapStyle = props && props.customMapStyle
    const silverMapStyles = customMapStyle ? [{ "elementType": "geometry", "stylers": [{ "color": "#f5f5f5" }] }, { "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] }, { "elementType": "labels.text.fill", "stylers": [{ "color": "#616161" }] }, { "elementType": "labels.text.stroke", "stylers": [{ "color": "#f5f5f5" }] }, { "featureType": "administrative.land_parcel", "elementType": "labels.text.fill", "stylers": [{ "color": "#bdbdbd" }] }, { "featureType": "poi", "elementType": "geometry", "stylers": [{ "color": "#eeeeee" }] }, { "featureType": "poi", "elementType": "labels.text.fill", "stylers": [{ "color": "#757575" }] }, { "featureType": "poi.park", "elementType": "geometry", "stylers": [{ "color": "#e5e5e5" }] }, { "featureType": "poi.park", "elementType": "labels.text.fill", "stylers": [{ "color": "#9e9e9e" }] }, { "featureType": "road", "elementType": "geometry", "stylers": [{ "color": "#ffffff" }] }, { "featureType": "road.arterial", "elementType": "labels.text.fill", "stylers": [{ "color": "#757575" }] }, { "featureType": "road.highway", "elementType": "geometry", "stylers": [{ "color": "#dadada" }] }, { "featureType": "road.highway", "elementType": "labels.text.fill", "stylers": [{ "color": "#616161" }] }, { "featureType": "road.local", "elementType": "labels.text.fill", "stylers": [{ "color": "#9e9e9e" }] }, { "featureType": "transit.line", "elementType": "geometry", "stylers": [{ "color": "#e5e5e5" }] }, { "featureType": "transit.station", "elementType": "geometry", "stylers": [{ "color": "#eeeeee" }] }, { "featureType": "water", "elementType": "geometry", "stylers": [{ "color": "#c9c9c9" }] }, { "featureType": "water", "elementType": "labels.text.fill", "stylers": [{ "color": "#9e9e9e" }] }] : []
    return (
      <GoogleMap
        options={{
          mapTypeControl: false,
          scrollwheel: false,
          styles: silverMapStyles
        }}
        defaultZoom={16}
        defaultCenter={coordinates}
      >
        <Marker position={coordinates} />
      </GoogleMap>
    );
  })
);
const LocationMap = withScriptjs(
  withGoogleMap(props => {
    const { data } = props;
    const dataTemp = data && data.split(',');
    const coordinates = {
      lat: parseFloat(dataTemp[0]),
      lng: parseFloat(dataTemp[1]),
    };
    const customMapStyle = props && props.customMapStyle
    const silverMapStyles = customMapStyle ? [{ "elementType": "geometry", "stylers": [{ "color": "#f5f5f5" }] }, { "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] }, { "elementType": "labels.text.fill", "stylers": [{ "color": "#616161" }] }, { "elementType": "labels.text.stroke", "stylers": [{ "color": "#f5f5f5" }] }, { "featureType": "administrative.land_parcel", "elementType": "labels.text.fill", "stylers": [{ "color": "#bdbdbd" }] }, { "featureType": "poi", "elementType": "geometry", "stylers": [{ "color": "#eeeeee" }] }, { "featureType": "poi", "elementType": "labels.text.fill", "stylers": [{ "color": "#757575" }] }, { "featureType": "poi.park", "elementType": "geometry", "stylers": [{ "color": "#e5e5e5" }] }, { "featureType": "poi.park", "elementType": "labels.text.fill", "stylers": [{ "color": "#9e9e9e" }] }, { "featureType": "road", "elementType": "geometry", "stylers": [{ "color": "#ffffff" }] }, { "featureType": "road.arterial", "elementType": "labels.text.fill", "stylers": [{ "color": "#757575" }] }, { "featureType": "road.highway", "elementType": "geometry", "stylers": [{ "color": "#dadada" }] }, { "featureType": "road.highway", "elementType": "labels.text.fill", "stylers": [{ "color": "#616161" }] }, { "featureType": "road.local", "elementType": "labels.text.fill", "stylers": [{ "color": "#9e9e9e" }] }, { "featureType": "transit.line", "elementType": "geometry", "stylers": [{ "color": "#e5e5e5" }] }, { "featureType": "transit.station", "elementType": "geometry", "stylers": [{ "color": "#eeeeee" }] }, { "featureType": "water", "elementType": "geometry", "stylers": [{ "color": "#c9c9c9" }] }, { "featureType": "water", "elementType": "labels.text.fill", "stylers": [{ "color": "#9e9e9e" }] }] : []
    return (
      <GoogleMap
        options={{
          mapTypeControl: false,
          scrollwheel: false,
          style: silverMapStyles
        }}
        defaultZoom={16}
        defaultCenter={coordinates}
      >
        <Marker position={coordinates} />
      </GoogleMap>
    );
  })
);

const PrintLocation = props => {
  const {
    layoutItem: {
      itemProperties: { locationConfig },
    }, data
  } = props;
  const customMapStyle = locationConfig && locationConfig.customMapStyle
  if (locationConfig && locationConfig.displayAsStaticMap && locationConfig.autoCapture) {
    return (
      <LocationMapWithCurrentLocation
        currentLocationdata={data}
        isMarkerShown
        googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyBfhuMCKv2c1jH_lJcxJ1iNjg0mmHXY_zU&v=3.exp&libraries=geometry,drawing,places'
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `400px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        customMapStyle={customMapStyle}
      />
    );
  }
  if (locationConfig && locationConfig.displayAsStaticMap) {
    return (
      <LocationMap
        data={data}
        isMarkerShown
        googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyBfhuMCKv2c1jH_lJcxJ1iNjg0mmHXY_zU&v=3.exp&libraries=geometry,drawing,places'
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `400px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        customMapStyle={customMapStyle}
      />
    );
  }
  return (
    <a href={`https://maps.google.com/?q=${props.data}`} target='_blank'>
      <i className='fa fa-external-link' area-hidden='true'></i>
      {'  Map'}
    </a>
  );
};

const PrintBit = props => {
  const iconName = props.data ? 'fa-check-circle' : 'fa-times-circle';
  const color = props.data ? 'green' : 'red';
  return (
    <i
      className={`fa ${iconName}`}
      area-hidden='true'
      style={{ color: color }}
    ></i>
  );
};

const PrintImage = props => {
  let imageUrl = null;
  const { layoutItemWidth, isWorkflowCard } = props;
  const imageHeight = props.layoutItem.itemProperties && props.layoutItem.itemProperties.imageConfig && props.layoutItem.itemProperties.imageConfig.imageHeight ? props.layoutItem.itemProperties.imageConfig.imageHeight : null
  let imgCollection = [];
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === imgCollection.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? imgCollection.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const data = props.data;

  if (data && Array.isArray(data)) {
    data.map(item => {
      imageUrl =
        item &&
        (item.startsWith('http')
          ? item
          : config.assetsBasePath + item);
      imgCollection.push(imageUrl)
    })
  }
  const slides = imgCollection && Array.isArray(imgCollection) && imgCollection.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
      >
        <img src={item} height={layoutItemWidth / 2} width={layoutItemWidth} />
      </CarouselItem>
    );
  });
  if (imgCollection.length > 0) {
    return <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      className='carousel-image'
    >
      <CarouselIndicators items={imgCollection} activeIndex={activeIndex} onClickHandler={goToIndex} />
      {slides}
      <CarouselControl className='control' direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
    </Carousel>
  }
  if (!Array.isArray(data)) {
    imageUrl =
      props.data  &&  (props.data.startsWith('http')
          ? props.data
          : config.assetsBasePath + props.data);
  }
  if (!imageUrl) {
    // imageUrl="https://st.depositphotos.com/2101611/3925/v/600/depositphotos_39258143-stock-illustration-businessman-avatar-profile-picture.jpg";
    return null;
  }

  return <img src={imageUrl} height={imageHeight} width='100%' />;
};

const PrintPhone = props => {
  const [verified, changeVerify] = useState(false);
  const [attempts, changeAttempts] = useState(3);
  const [resendButton, changeResendButton] = useState(false);
  const [key, setKey] = useState(0);
  const [otp, changeOTP] = useState("");
  const { data, layoutItem: { itemProperties: { phoneConfig } }, __form__values } = props;
  const isVerified = phoneConfig && __form__values[phoneConfig.verifiedPhoneField];
  const interval = null;
  const emailField = phoneConfig && phoneConfig.verifyEmailField;
  const generateOTP = () => {
    
    const payload = emailField ? {
      phoneNumber: data,
      email: ModelPropertiesParser(`{{${emailField}}}`, __form__values)
    } : {
      phoneNumber: data
    }
    client().post(`${config.publicAPI}/generateotp`, payload).then((res) => {
      changeVerify(true);
    }).catch((error) => {
      console.log("Error", error);
      changeVerify(false);
    });
    changeResendButton(false);
    setKey(prevKey => prevKey + 1)
    setTimeout(() => {
      changeResendButton(true);
    }, 121000);
  }
  const verifyOTP = () => {
    const { workflowModel, sessionId } = props.workflow;
    const payload = emailField ? {
      otp: parseInt(otp),
      phoneNumber: data,
      email: ModelPropertiesParser(`{{${emailField}}}`, __form__values)
    } : {
      otp: parseInt(otp),
      phoneNumber: data,
    }
    client().post(`${config.publicAPI}/verifyotp`, payload).then((res) => {
      if (res.data && res.data.data) {
        phoneConfig && phoneConfig.verifiedPhoneField && props.change(phoneConfig.verifiedPhoneField, true);
        const newValues = __form__values;
        if (phoneConfig.verifiedPhoneField) newValues[phoneConfig.verifiedPhoneField] = true
        props.onSubmit(fromJS(newValues))
        changeOTP("")
      } else {
        if (attempts === 1) {
          phoneConfig && phoneConfig.verifiedPhoneField && props.change(phoneConfig.verifiedPhoneField, false);
          if (phoneConfig && phoneConfig.endworkflow) {
            props.onEndWorkflow({ workflowId: workflowModel, sessionId });
          } else {
            const newValues = __form__values;
            props.onSubmit(fromJS(newValues))
          }
          changeOTP("");
        } else {
          changeOTP("");
          changeAttempts(attempts - 1);
        }
      }
    }).catch((error) => {
      console.log("Error", error);
    });
  }
  useEffect(() => {
    if (phoneConfig && phoneConfig.verifyPhone && !isVerified) {
      setTimeout(() => {
        changeResendButton(true);
      }, 121000);
      generateOTP();
    }
  }, [])
  useEffect(() => {
    console.log(otp.length)
    if (otp.length === 6) {
      verifyOTP();
    }
  }, [otp])
  const renderTime = ({ remainingTime }) => {
    const minutes = Math.floor(remainingTime / 60)
    const seconds = remainingTime % 60
    if (remainingTime === 0) {
      return <div className="timer"> </div>;
    }
    return (
      <div className="timer">
        <div className="value">{`${minutes}:${seconds}`}</div>
      </div>
    );
  };

  if (phoneConfig && phoneConfig.verifyPhone) {
    const num = data.replace(/\d(?=\d{4})/g, "*");
    return (
      <div className="phone-container">
        {
          isVerified ? (
            <div>
              <span>{num}</span>
              <i className='fa fa-check phone-verified' color="red" aria-hidden='true'></i>
            </div>

          ) : (
            <div className="phone-otp-container">
              <span>Please enter the OTP sent to {num}</span>
              {
                attempts < 3 && attempts > 0 && <div>
                  <span className="text-danger">{attempts} attempts left</span>
                </div>
              }
              <OtpInput
                value={otp}
                isInputNum={true}
                isInputSecure={true}
                onChange={(otp) => {
                  changeOTP(otp);
                }}
                numInputs={6}
                inputStyle={{
                  width: '40px',
                  height: '40px'
                }}
                containerStyle={{
                  marginTop: '30px'
                }}
                separator={<span className="otp-spacer">-</span>}
              />
              { <div>
              { !resendButton && <Row style={{marginTop:10,marginLeft:-10,marginBottom:-10, alignItems:"center",justifyContent:"center"}}>
                <span style={{color:"red",marginRight:10,fontSize:14}}>{'You can resend the OTP after'}</span>
                  <CountdownCircleTimer
                    isPlaying
                    strokeLinecap="square"
                    key={key}
                    size={50}
                    strokeWidth={3}
                    duration={120}
                    colors={[
                      ['#004777', 0.33],
                      ['#F7B801', 0.33],
                      ['#A30000', 0.33],
                    ]}
                  >
                       {renderTime}
                  </CountdownCircleTimer>
                  </Row>}
                 <Button onClick={() => generateOTP()} disabled={!resendButton} className="resend-button" size="sm">Resend OTP</Button>
              </div>
              }
            </div>
          )
        }
      </div>
    )
  }
  return <div>{data}</div>
}


const PrintVideo = props => {
  const { layoutItem, webhookCall, workflowData } = props;
  const { itemProperties } = layoutItem;
  const { videoConfig, webhookConfig } = itemProperties;
  let fileAttr = { attributes: { controlsList: 'nodownload' } };
  let payload = { data: {} };
  if (webhookConfig && webhookConfig.allow) {
    payload.url = webhookConfig.url && webhookConfig.url;
    payload.data = { eventName: "Downloaded", eventModel: { callrecordingid: workflowData.id } }
  }
  if (videoConfig && videoConfig.audio)
    fileAttr.forceAudio = true;
  const videoLink =
    props.data &&
    (props.data.startsWith('http')
      ? props.data
      : config.assetsBasePath + props.data);
  return videoLink ? <div ><ReactPlayer controls width={'100%'} height={videoConfig && videoConfig.audio ? '60px' : '360px'}
    url={videoLink} config={{ file: fileAttr }} onPlay={() => {
      console.log('play');
      if (webhookConfig && webhookConfig.allow) {
        payload.data.eventModel.eventtype = 'Played'
        webhookCall(payload)
      }
    }} />
    {videoConfig && videoConfig.download ? <div className='mt-1 ml-1'>Can not play ? <a href={`${videoLink}`} download onClick={() => {
      console.log('download');
      if (webhookConfig && webhookConfig.allow) {
        payload.data.eventModel.eventtype = 'Downloaded'
        webhookCall(payload)
      }
    }}>Download</a></div> : ''}
  </div> : null;
};

const PrintRating = props => {
  const {
    layoutItem: {
      itemProperties: { ratingConfig },
    },
  } = props;
  if (!ratingConfig) return <span>{props.data}</span>;
  const { ratingType, ratingLength, ratingStep } = ratingConfig;
  return (
    <div className='rating-container'>
      <RatingComp
        start={0}
        stop={parseInt(ratingLength) || 5}
        initialRating={props.data}
        fractions={ratingType.value === 'star-half' ? 2 : 1}
        step={parseInt(ratingStep) || 1}
        emptySymbol={`fa fa-${ratingType.value === 'star-half'
            ? 'star-o'
            : ratingType.value === 'thumbs-up'
              ? 'thumbs-o-up'
              : ratingType.value + '-o'
          } fa-2x ${ratingType.value}`}
        fullSymbol={`fa fa-${ratingType.value === 'star-half' ? 'star' : ratingType.value
          } fa-2x ${ratingType.value}`}
        readonly
      />
    </div>
  );
};

const PrintTime = props => {
  const DATE = moment(props.data + ' GMT');
  const {
    data,
    layoutItem: { itemProperties },
  } = props;
  const { beforeIcon, textColor } = itemProperties;
  if (beforeIcon) {
    return (
      <div className={textColor ? `text-${textColor.value}` : ''}>
        <span className='mr-2'>
          <RenderIcon config={beforeIcon} />
        </span>
        {DATE.format('h:mm a')}
      </div>
    );
  }
  return DATE.format('h:mm a');
};

const PrintDateTime = props => {
  const {
    companyDetails,
    type,
    data,
    layoutItem: { itemProperties },
  } = props;
  let dateformat =
    companyDetails && companyDetails.data && companyDetails.data != null
      ? companyDetails.data.companySettings.dateformat
      : '';
  dateformat = dateformat ? dateformat.toUpperCase() : '';
  const tempDate = type === 'DATE' ? moment(data ) : moment(data + ' GMT'); 
  let DATE = tempDate.isValid ? tempDate : moment(props.data);
  const { beforeIcon, textColor } = itemProperties;
  if (type === 'DATE') {
    DATE = dateformat ? DATE.format(dateformat) : DATE.format('DD-MM-YYYY');
  } else if (type === 'DATETIME') {
    DATE = dateformat
      ? DATE.format(dateformat + ' hh:mm a')
      : DATE.format('DD-MM-YYYY hh:mm a');
  }
  if (beforeIcon) {
    return (
      <div className={textColor ? `text-${textColor.value}` : ''}>
        <span className='mr-2'>
          <RenderIcon config={beforeIcon} />
        </span>
        {DATE}
      </div>
    );
  }
  return <span className={tempDate._isValid ? "valid_date" : "invalid_date"}>{data ? DATE : ''}</span>;
};

const PrintStaticWebContent = (props) => {
  const data = JSON.parse(props.data);
  let html = data.html;
  const variables = data.variables;

  if(Array.isArray(variables)) {
    html = ModelPropertiesParser(html, props.workflowData);
  }
  return (
    <div dangerouslySetInnerHTML={{__html: html}}>
    </div>
  )
}

class PrintReadOnly extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      isSaving: false,
    };
  }
  setEditable(isEditing) {
    this.setState({ isEditing });
  }
  onEditSaved() {
    const {
      __form__values,
      saveInline,
      workflow: {
        currentItem: { typeModel },
      },
    } = this.props;
    const info = {
      typeModelName: typeModel.safeName,
      customerName: typeModel.customerName,
    };
    this.setState({ isSaving: true });
    saveInline(__form__values, info);
  }
  componentWillReceiveProps(nextProps) {
    const {
      inlineSaveData,
      workflow: { workflowData },
      updateWorkflowData,
    } = nextProps;
    if (!inlineSaveData.isLoading && this.state.isSaving) {
      this.setState({ isSaving: false });
    }
    if (inlineSaveData.data && workflowData) {
      if (inlineSaveData.data.version !== workflowData.version) {
        updateWorkflowData(inlineSaveData.data, nextProps.workflow);
        this.setState({ isEditing: false });
      }
    }
  }

  render() {
    const {
      collection,
      workflow: { workflowData },
      workflow: { currentItem: { layoutProperties}},
      classProperty,
      layoutItem,
      layoutItem: {
        itemProperties: {
          modelSelector,
          label,
          isLayoutItemEditable,
          maskValue,
          showLastdigits,
          field: { type },
        },
      },
      inlineNextState,
      customIcons,
      translate
    } = this.props;
    const { isEditing, isSaving } = this.state;
    let isMultipleUsers = false;
    let PrintComponent = DefaultText;
    const EditingComponent = Elements[type];
    const layoutItemStyle = layoutProperties && layoutProperties.layoutItemStyle && layoutProperties.layoutItemStyle;
    let additionalStyle = {};
    let additionalStyleLabel = {};
    let className = 'd-flex';
    let translatedLabel = label.text;
    if(label.languageKey) {
      translatedLabel = translate(`label.${label.languageKey.toLowerCase().replace(/ /g, "_")}`);
      translatedLabel = translatedLabel.startsWith("Missing translation") ? label.text: translatedLabel;
    }
    if (type === 'USER') {
      PrintComponent = PrintUser;
      const users = workflowData[classProperty];
      if (users && Array.isArray(users) && users.length) {
        isMultipleUsers = true;
      }
    }
    if (type === 'FILE') {
      PrintComponent = PrintFile;
    }
    if (type === 'EMAIL') {
      PrintComponent = PrintEmailWithAvatar;
    }
    if (type === 'DECIMAL' || type === 'CALCULATED_NUMBER' || type==='NUMBER') {
      PrintComponent = PrintDecimalOrCalculatedNumberorNumber;
    }
    if (type === 'LARGETEXT') {
      PrintComponent = PrintRichtext;
    }
    if (type === 'PERCENTAGE') {
      PrintComponent = PrintPercentage;
    }
    if (type === 'URL') {
      PrintComponent = PrintUrl;
    }
    if (type === 'LOCATION') {
      PrintComponent = PrintLocation;
    }
    if (type === 'RATING') {
      PrintComponent = PrintRating;
    }
    if (type === 'BIT') {
      PrintComponent = PrintBit;
      additionalStyleLabel = {
        display: `inline-block`,
      };
      additionalStyle = {
        display: `inline-block`,
        marginRight: '10px',
        float: 'left',
      };
      className = '';
    }
    if (type === 'IMAGE') {
      PrintComponent = PrintImage;
    }
    if (type === 'DATE' || type === 'DATETIME') {
      PrintComponent = PrintDateTime;
    }
    if (type === 'TIME') {
      PrintComponent = PrintTime;
    }
    if (type === 'VIDEO') {
      PrintComponent = PrintVideo;
    }
    if (type === 'PHONE') {
      PrintComponent = PrintPhone;
    }

    if (type === 'STATICWEBLAYOUT') {
      PrintComponent = PrintStaticWebContent;
    } 
    const newClassProperty = classProperty
      ? classProperty.replace(' ', '_')
      : classProperty;
    let datum =
      classProperty &&
      workflowData &&
      JSONPath.value(workflowData, `$.${newClassProperty}`);

      if (maskValue && datum){
        datum = datum.toString().substring(0, 3) + '*****' + datum.toString().substring(datum.toString().length-4, datum.toString().length)
      }

      if (showLastdigits && datum){
        let num = datum.toString().substring(0, datum.toString().length-4);
        let cross = '*'
        for (let index = 0; index < num.length-1; index++) {
           cross = cross + '*'
        }
        datum =  cross + datum.toString().substring(datum.toString().length-4, datum.toString().length)
      }

    if (type === 'MULTISELECT') {
      datum = datum ? datum.join(', ') : datum;
    }
    if (!collection || isMultipleUsers || type === 'FILE' || (type === 'IMAGE' && collection))
      return (
        <FormGroup className={layoutItemStyle === 'rowWise'?'rowWiseStyleLI':''}>
          {!isEditing && label.show ? (
            <div className={`${className}`} style={additionalStyleLabel}>
              <Label className='text-muted text-capitalize mb-0'>
                {translatedLabel}
              </Label>
              {isLayoutItemEditable === true ? (
                <Button
                  onClick={this.setEditable.bind(this, true)}
                  size='sm'
                  color='link'
                  className='pt-0 text-muted edit-layout-item-button'
                >
                  <i className='fa fa-pencil' aria-hidden='true'></i>
                </Button>
              ) : (
                ''
              )}
            </div>
          ) : null}
          {workflowData ? (
            <div className={`${className}`} style={additionalStyle}>
              {isEditing ? (
                <div className={`${isEditing ? 'is-editing-layout-item' : ''}`}>
                  <EditingComponent {...this.props} isReadOne={false} />
                  <div className='d-flex justify-content-end'>
                    <Button
                      disabled={isSaving}
                      onClick={this.onEditSaved.bind(this)}
                      color='secondary'
                      size='sm'
                    >
                      {isSaving ? 'Saving...' : 'Save'}
                    </Button>
                  </div>
                </div>
              ) : (
                <div className='w-100'>
                  {isMultipleUsers ? (
                    <div className='w-100'>
                      {datum.map((data, index) => {
                        return (
                          <PrintComponent {...this.props} data={data.id} />
                        );
                      })}
                    </div>
                  ) : (
                    <PrintComponent {...this.props} data={datum} />
                  )}
                </div>
              )}
            </div>
          ) : (
            ''
          )}
        </FormGroup>
      );

    if (inlineNextState && inlineNextState.isLoading) {
      return <p className='text-muted'>Loading...</p>;
    }

    return (
      <div>
        {datum &&
          datum.map((data, index) => {
            return (
              <p key={index}>{ModelPropertiesParser(modelSelector, data)}</p>
            );
          })}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    __form__values: getFormValues(ownProps.form)(state),
    inlineSaveData: state.get('save'),
    companyDetails: state.getIn(['company', 'details']),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    saveInline: (payload, info) => {
      dispatch(save(payload, info, false));
    },
    updateWorkflowData: (workflowData, workflow) => {
      dispatch(updateWorkflowData(workflowData, workflow));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(PrintReadOnly));