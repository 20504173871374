import React, { Component } from 'react';
import { Cell } from 'fixed-data-table-2';
import NoDataCell from '../NoDataCell';
import moment from 'moment';

class DateCell extends Component {
    render() {
        const { rows, column, columnKey, rowIndex, width, config, dateformat } = this.props;
		const type = this.props.column.field.type;
        const date = rows.getObjectAt(rowIndex)[columnKey];
        //const tempDate = type === 'TIME'?moment(date):moment(date + ' GMT');
		const tempDate = type === "DATE" ? moment(date) : moment(date + ' GMT');
        const DATE = tempDate.isValid? tempDate: moment(date);
        let cellAlignment;
        let cellStyle;
       
        if(config && config.styles && config.styles) {
            const styles = config.styles
            cellAlignment = styles.alignment && styles.alignment.value;
            cellStyle = {
                color: styles.color,
                textDecoration: styles.lineThrough ? 'line-through' : 'inherit'
            }
        }
        if (!DATE.isValid()) {
            return <NoDataCell {...this.props}/>
        }
        // let value = DATE.format('MMMM Do, YYYY');
		
        let value = DATE;
		value = dateformat ? DATE.format(dateformat) : DATE.format('DD-MM-YYYY');
		if (type === 'DATETIME') {
			value = dateformat ? DATE.format(dateformat + ' hh:mm a') : DATE.format('DD-MM-YYYY hh:mm a');
			// value = DATE.format('MMMM Do, YYYY h:mm a');
		}
		if (type === 'TIME') {
			value = DATE.format('h:mm a');
		}
        
        return (
            <Cell style={cellStyle} width={width} align={cellAlignment ? cellAlignment : 'left'}>
                { value } 
            </Cell>
        );
    }
}

export default DateCell;