import axios from 'axios';
import config from './config';
import { request } from 'http';
import { generateUUID } from './utils/uuid';
import { store } from './index';
import { setUser } from './actions/user';
import { fetchNavWorkflows } from './actions/workflows'

let tokens = [];
function resolve(options){
	let headers = {
		'Accept': 'application/json',
		'Content-Type': 'application/json',
		'X-Requested-With': 'XMLHttpRequest'
	};
	
	let user = window.user ? window.user : (localStorage.getItem('user') || null);
	console.log(window.user);
	if(typeof user === 'string') {
		user = JSON.parse(user);
	}
	
	/*if (user !== null && user !== false && user.token !== null){
		headers['X-AUTH-TOKEN'] = user.token;
	}
	if (typeof options === 'object' && options.hasOwnProperty('headers')) {
		headers = Object.assign(headers, options.headers);
	}*/
	const _client = axios.create({
		baseURL: config.appBaseUrl,
		headers
	});
	_client.interceptors.request.use((request) => {
		const url = request.url;
		const newUUID = generateUUID();
		request.headers['x-traceid'] = newUUID;
		console.log('API END HIT TO ::: ', request.url);
			if (user && user['token']) {
				if(!tokens.includes(user['token'])) {
					tokens.push(user['token']);
				}
				request.headers['X-AUTH-TOKEN'] = user['token'];
				if(request.data && request.data.logout) {
					localStorage.setItem('user',{})
					store.dispatch(setUser({
						isAnonymous: true,
						user: null
					}))
				}
			}
			if(url.endsWith('/public/workflow/link/start')) {
				delete request.headers['X-AUTH-TOKEN'];
			}
		return request;
	})
	_client.interceptors.response.use((response) => {
		const latestToken = response.data.latestToken || response.data.userSessionId;
		//if (latestToken && typeof latestToken === 'string' && (window.location.pathname === '/' || window.location.pathname.startsWith('/public/link/') ||  window.location.pathname.startsWith('/app')) ) {
		if (latestToken && typeof latestToken === 'string' && ( !user || latestToken !== user.token) && !tokens.includes(latestToken)) {
			if(!tokens.includes(latestToken)) {
				tokens.push(latestToken);
			}
			if(tokens.length > 1) {
				console.log("UpdatedToken", latestToken);
				axios.post(`${config.authAPI}/user/profile`, {},{
					headers: {
						...headers,
						'X-AUTH-TOKEN': latestToken
					}
				}).then(userProfile => {
					//console.log("User Profile -> ", userProfile.data);
					let newUser = userProfile.data && userProfile.data.data ? userProfile.data.data : user ? user : {};
					newUser.token = latestToken;
					user = newUser;
					if(userProfile.data != "") {
						store.dispatch(setUser({
							isAnonymous: false,
							user: userProfile.data.data
						}))
						if(!window.location.href.includes("/public/link/")){
							// window.location.href = '/app';
						}
					}
					store.dispatch(fetchNavWorkflows());
					// localStorage.setItem('user', JSON.stringify(newUser));
					window.user = newUser;

					if(newUser.id) {
						axios
						.post(`${config.authAPI}/g`, {
							id: 1280
						}, {
							headers: {
							...headers,
							"X-AUTH-TOKEN": response.data.token
							}
						})
						.then(globalData => {
							localStorage.setItem('globalData', JSON.stringify(globalData.data.data));
							globalData.data.data && localStorage.setItem('products', JSON.stringify(globalData.data.data.products));
						}).catch(globalDataError => {
							console.error(globalDataError);
						})
					}
				}).catch(userErr => {
					console.log("UserErr", userErr)
				})
			}
			let newUser = user ? user : {};
			newUser.token = latestToken;
			if(!user || !user.email) {
				localStorage.setItem('user', JSON.stringify(newUser));
			}
			window.user = newUser;
		}
		return response;
	},(error) => {
		console.log(error);
		if (error && error.response && error.response.status === 401) {
			localStorage.removeItem('user');
			if(user && user.email) {
				window.location.href = '/';
			} else {
				const url = window.location.href;
				if(url.indexOf('setpassword') > -1) {	}
				else {
					window.location.reload(true);
				}
			}
		}
		return Promise.reject(error);
	});

	return _client;
}

export default resolve;