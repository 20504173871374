import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Field } from 'redux-form/immutable';
import Select from 'react-select';
import moment from 'moment';

class DatepickerComponent extends Component {
    constructor(props) {
        super(props);
        const { filterStateMap, filterIndex } = props;
        const savedFilterState = filterStateMap ? filterStateMap[`filter_${filterIndex}`] : '';
        this.state = {
            date: null,
            fromDate: null,
            toDate: null
        }
    }
    componentWillReceiveProps(nextProps) {
        const { filterStateMap, filterIndex } = nextProps;
        const savedFilterState = filterStateMap[`filter_${filterIndex}`];
        if (!savedFilterState) {
            this.setState({
                date: null,
                fromDate: null,
                toDate: null
            })
        }
    }
    triggerFilterChange(value, fromValue, toValue) {
        const { onFilterChange, filter, filterIndex } = this.props;
        const { fromDate, toDate } = this.state;
        const { dateFilterType, leafType } = filter;
        this.setState({ value });
        let filterValue = [];
        const filterConfig = {
            filter,
            filterIndex
        }
        if (dateFilterType == 'DATERANGE') {
            const fromValueNew = fromValue ? this.convertToUtc(fromValue) : fromDate ? this.convertToUtc(fromDate):'';
            const toValueNew = toValue ? this.convertToUtc(toValue) : toDate ? this.convertToUtc(toDate):''
            let from = fromValueNew && (leafType == 'DATETIME' ? fromValueNew.format('YYYY-MM-DD HH:mm') : fromValueNew.format('YYYY-MM-DD'));
            from = !from ? '' : from;
            let to = toValueNew && (leafType == 'DATETIME' ? toValueNew.format('YYYY-MM-DD HH:mm') : toValueNew.format('YYYY-MM-DD'));
            to = !to ? '' : to;
            filterValue = [from, to];
        } else {
            filterValue = [value];
        }
        onFilterChange(filterConfig, filterValue);
    }
    handleChange(date) {
        const { filter : { leafType } } = this.props;
        this.setState({ date });
        let dateValue = this.convertToUtc(date)
        dateValue = dateValue ? leafType == 'DATETIME' ? dateValue.format('YYYY-MM-DD HH:MM') : date.format('YYYY-MM-DD') : '';
        dateValue = !dateValue ? '' : dateValue;
        this.triggerFilterChange(dateValue);
    }
    handleFromChange(date) {
        this.setState({ fromDate: date });
        this.triggerFilterChange(null, date);
    }
    handleToChange(date) {
        let dt = date;
        const { filter : { leafType, defaultTime } } = this.props;
        if(leafType == 'DATETIME' && defaultTime){
            dt.set({ hour: 23, minute: 59, second: 59 });
        }
        this.setState({ toDate: dt });
        this.triggerFilterChange(null, null, dt);
    }

    convertToUtc(date){
        let updatedTime = date.clone();
        const offSet = date.utcOffset();
        updatedTime = updatedTime.subtract(offSet, 'minutes');
        const year = updatedTime.toDate().getFullYear();
        const month = updatedTime.toDate().getMonth();
        const day = updatedTime.toDate().getDate();
        const hour = updatedTime.toDate().getHours();
        const min = updatedTime.toDate().getMinutes();
        const value = `${year}-${(month > 9 ? '' : '0') + (month + 1)}-${(day > 9 ? '' : '0') + day} ${(hour > 9 ? '' : '0') + hour}:${(min > 9 ? '' : '0') + min}`;
        return moment(value);
    }

    render() {
        const { filter, elem_id, companyDetails } = this.props;
        const { showClickableHorizontalView, dateFilterType, leafType, defaultTime } = filter;
        const { date, fromDate, toDate, selectedOption } = this.state;
        let dateFormat = companyDetails && companyDetails.data && companyDetails.data != null ? companyDetails.data.companySettings.dateformat : 
            '';
        dateFormat = dateFormat ? dateFormat.toUpperCase() : '';
        if (dateFilterType == 'DATERANGE') {
            return (
                <Col xs="12" className='w-100 p-0'>
                    {
                        leafType == 'DATETIME' ? <div>
                            <DatePicker
                                selected={fromDate}
                                onChange={this.handleFromChange.bind(this)}
                                placeholderText='Select From Date...'
                                className='form-control w-100'
                                popperPlacement='auto-right'
                                showTimeSelect={defaultTime ? false : true}
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                timeCaption="time"
                                dateFormat={dateFormat ? `${dateFormat} hh:mm a` : "MM/DD/YYYY hh:mm a"}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                            />
                            <DatePicker
                                selected={toDate}
                                onChange={this.handleToChange.bind(this)}
                                placeholderText='Select To Date...'
                                className='form-control w-100'
                                popperPlacement='auto-right'
                                showTimeSelect={defaultTime ? false : true}
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                timeCaption="time"
                                dateFormat={dateFormat ? `${dateFormat} hh:mm a` : "MM/DD/YYYY hh:mm a"}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                            />
                        </div> : <div>
                            <DatePicker
                                selected={fromDate}
                                onChange={this.handleFromChange.bind(this)}
                                placeholderText='Select From Date...'
                                className='form-control w-100'
                                popperPlacement='auto-right'
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                dateFormat= {dateFormat ? dateFormat : 'MM/DD/YYYY'}
                            />
                            <DatePicker
                                selected={toDate}
                                onChange={this.handleToChange.bind(this)}
                                placeholderText='Select To Date...'
                                className='form-control w-100'
                                popperPlacement='auto-right'
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                dateFormat= {dateFormat ? dateFormat : 'MM/DD/YYYY'}
                            />
                        </div>
                    }
                </Col>
            );
        }
        return (
            <Col xs="12" className='w-100 p-0'>
                {
                    leafType == 'DATETIME' ? <DatePicker
                        selected={date}
                        onChange={this.handleChange.bind(this)}
                        placeholderText='Select Date...'
                        className='form-control w-100'
                        popperPlacement='auto-right'
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="time"
                        dateFormat="MM/DD/YYYY hh:mm a"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                    /> : <DatePicker
                        selected={date}
                        onChange={this.handleChange.bind(this)}
                        placeholderText='Select Date...'
                        className='form-control w-100'
                        popperPlacement='auto-right'
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                    />
                }
            </Col>
        );
    }
}

const DateFilter = (props) => {
    const { ...rest } = props;
    return (
        <div>
            <Field
                name="dateFilter"
                component={DatepickerComponent}
                type="date"
                {...rest}
            />
        </div>
    );
}


export default DateFilter;