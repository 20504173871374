import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../containers/to-js';
import { getTranslate } from 'react-localize-redux';
import { endWorkflow } from '../../actions/workflows';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Container, Row, Col, Alert } from 'reactstrap';
import { next, reset } from '../../actions/workflow-item';
import ReduxWrapperHoc from './ReduxWrapperHoc';
import { reduxFormKeys } from '../../dto/workflow';

class ModalWorkflow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            disableSaveAndNext:false
        }
        this.toggle = this.toggle.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        const { modalWorkflow } = nextProps;
        this.setState({ showModal: !modalWorkflow || modalWorkflow === undefined ? false : true });
    }
    toggle() {
        const { modalWorkflow: { workflowModel, sessionId }, endWorkflow } = this.props;
        this.setState({ showModal: !this.state.showModal }, () => {
            endWorkflow({ workflowId: workflowModel, sessionId });
        });
    }
    toggleSaveandNextState = (value)=>{
        this.setState({disableSaveAndNext:value})
       }
    submitForm(values) {
        const { onSubmit, modalWorkflow } = this.props;
        onSubmit(modalWorkflow, values);
    }
    render() {
        const { modalWorkflow, handleSubmit, workflowData, endWorkflow } = this.props;
        const { showModal } = this.state;
        if (!modalWorkflow) return null;
        const { currentItem: { name }, workflowError } = modalWorkflow;
        
        return (
            <div>
                <Modal size='lg' isOpen={showModal} toggle={this.toggle} className='workflow-modal-wrapper'>
                    <ModalBody>
                        <CloseModal toggle={this.toggle} />
                        {
                            (workflowError && workflowError.hasError) ? (
                                <Container>
                                    <Row>
                                        <Col>
                                            <Alert style={{ marginTop: 10 }} color="danger">
                                                {workflowError.message}
                                            </Alert>
                                        </Col>
                                    </Row>
                                </Container>
                            ) : (
                                <ReduxWrapperHoc
                                    onSubmit={this.submitForm.bind(this)}
                                    jumptoSubmit={this.submitForm.bind(this)}
                                    initialValues={workflowData || {}}
                                    workflowData={workflowData}
                                    form={reduxFormKeys.modal}
                                    className='modal-workflow-form'
                                    isBase={false}
                                    workflowForContainer={modalWorkflow}
                                    toggleSaveandNextState={this.toggleSaveandNextState}
                                    disableSaveAndNext={this.state.disableSaveAndNext}
                                    onEndWorkflow={(payload) => {
                                        // endWorkflow(payload); not required
                                        this.toggle();
                                    }}
                                    modalClose={this.toggle}
                                />
                            )
                        }
                        
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

const CloseModal = (props) => {
    const { toggle } = props;
    return (
        <a onClick={toggle} href='#' className='workflow-modal-close'>
            <i className="fa fa-times" aria-hidden="true"></i>
        </a>
    )
}

const mapStateToProps = (state, ownProps) => {
    const modalWorkflow = state.getIn(['workflow', 'modalWorkflow']);
    const workflowData = modalWorkflow ? modalWorkflow.get('workflowData') : null;
    return {
        translate: getTranslate(state.get('locale')),
        modalWorkflow,
        workflowData,
        updatedVersion: workflowData ? workflowData.get('version') : null
    };
};

const mapDisptachToProps = (dispatch) => {
    return {
        endWorkflow: (payload) => {
            dispatch(endWorkflow(payload));
        },
        onSubmit: (modalWorkflow, values) => {
            dispatch(next(modalWorkflow, values));
            dispatch(reset());
        }
    }
}

export default connect(mapStateToProps, mapDisptachToProps)(toJS(ModalWorkflow));