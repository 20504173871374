import React, { Component } from 'react';
import { Cell } from 'fixed-data-table-2';
import ActionContainer from '../../../../containers/workflow/ActionContainer';
import { Dropdown, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class ActionCell extends Component {
	constructor(props) {
        super(props);
        this.state = {
            actionsDDOpen: false
        }
    }
	toggle(key) {
        this.setState({ [key]: !this.state[key] });
    }
    componentDidMount() {
        const element = this.cell;
        const { rowHeight, colWidth } = this.props;
        // const hasOverflowingChildren = element && element.offsetHeight > rowHeight || element.offsetWidth > colWidth;
    }

    render() {
        const { actions,keyValue, rows, workflow, rowIndex, columnKey,classNameAction, menuTypeInlineAction,template2Table,
			selectedTemplate, typeModelId, containerId, columnIndex } = this.props;
        let row = rows.getObjectAt(rowIndex);
		let wfData = rows.getObjectAt(rowIndex);
        let tid = columnKey && rows.getObjectAt(rowIndex)[columnKey];
		if(keyValue){
			 tid = columnKey && rows.getObjectAt(keyValue)[columnKey];
			 wfData = rows.getObjectAt(keyValue);
			  row = rows.getObjectAt(keyValue);
		}

        if(selectedTemplate === 'Template3'){
      return  (  <div ref={(el) => { this.cell = el }}> <div className='ml-1 d-inline-block'>
       
						<Dropdown size="sm" isOpen={this.state.actionsDDOpen} toggle={this.toggle.bind(this, 'actionsDDOpen')} className='action_dropdown'>
							<DropdownToggle color='link' className='action_dropdown_menu-icon'>
								<i className="fa fa-ellipsis-v fa-lg" aria-hidden="true"></i>
							</DropdownToggle>
							<DropdownMenu className={'action_dropdown_menu'}>
								{
									actions.map((action, index) => {
										if (action.actionProperties.hasOwnProperty('systemGenerated') &&  action.actionProperties.systemGenerated === true) return null;
										const { actionProperties } = action;
										if(actionProperties && actionProperties.actionDisabled) {
											return null;
										}
										return <DropdownItem className={`mr-1 d-inline-block ${classNameAction}`} >
											<ActionContainer  wfData={wfData} key={index} workflow={workflow} action={action} 
											tid={tid} row={row} showAsPlaneText={true} typeModelId={typeModelId} containerId={containerId} columnIndex={columnIndex}/>
										</DropdownItem>
									})
								}
							</DropdownMenu>
						</Dropdown>
					</div> </div> )
        }
        if(template2Table){
            return <div ref={(el) => { this.cell = el }}>
         
                {
                    actions.map((action, index) => {
                        if (action.actionProperties.hasOwnProperty('systemGenerated') &&  action.actionProperties.systemGenerated === true) return null;
                        const { actionProperties } = action;
                        if(actionProperties && actionProperties.actionDisabled || actionProperties.elementType == 'MENULINK') {
                            return null;
                        }
                        return <div key={index} className={`mr-1 d-inline-block`}>
                            <ActionContainer wfData={wfData} key={index} workflow={workflow} action={action} tid={tid} row={row} 
							typeModelId={typeModelId} containerId={containerId} columnIndex={columnIndex} />
                        </div>
                    })
                }
				{/* {
					menuTypeInlineAction && menuTypeInlineAction.length > 0  && <div className='ml-1 d-inline-block'>
						<Dropdown size="sm" isOpen={this.state.actionsDDOpen} toggle={this.toggle.bind(this, 'actionsDDOpen')}>
							<DropdownToggle color='link'>
								<i className="fa fa-ellipsis-h fa-lg" aria-hidden="true"></i>
							</DropdownToggle>
							<DropdownMenu right>
								{
									menuTypeInlineAction.map((action, index) => {
										if (action.actionProperties.hasOwnProperty('systemGenerated') &&  action.actionProperties.systemGenerated === true) return null;
										const { actionProperties } = action;
										if(actionProperties && actionProperties.actionDisabled) {
											return null;
										}
										return <DropdownItem >
											<ActionContainer key={index} workflow={workflow} action={action} tid={tid} row={row} />
										</DropdownItem>
									})
								}
							</DropdownMenu>
						</Dropdown>
					</div>
				} */}
         
        </div> 
        }
        return <div ref={(el) => { this.cell = el }}>
            <Cell className='actions-cell'>
                {
                    actions.map((action, index) => {
                        if (action.actionProperties.hasOwnProperty('systemGenerated') &&  action.actionProperties.systemGenerated === true) return null;
                        const { actionProperties } = action;
                        if(actionProperties && actionProperties.actionDisabled || actionProperties.elementType == 'MENULINK') {
                            return null;
                        }
                        return <div key={index} className='mr-1 d-inline-block'>
                            <ActionContainer wfData={wfData} key={index} workflow={workflow} action={action} tid={tid} 
							row={row} typeModelId={typeModelId} containerId={containerId} columnIndex={columnIndex}/>
                        </div>
                    })
                }
				{
					menuTypeInlineAction && menuTypeInlineAction.length > 0  && <div className='ml-1 d-inline-block'>
						<Dropdown size="sm" isOpen={this.state.actionsDDOpen} toggle={this.toggle.bind(this, 'actionsDDOpen')}>
							<DropdownToggle color='link'>
								<i className="fa fa-ellipsis-h fa-lg" aria-hidden="true"></i>
							</DropdownToggle>
							<DropdownMenu right>
								{
									menuTypeInlineAction.map((action, index) => {
										if (action.actionProperties.hasOwnProperty('systemGenerated') &&  action.actionProperties.systemGenerated === true) return null;
										const { actionProperties } = action;
										if(actionProperties && actionProperties.actionDisabled) {
											return null;
										}
										return <DropdownItem >
											<ActionContainer wfData={wfData} key={index} workflow={workflow} action={action} 
											tid={tid} row={row} typeModelId={typeModelId} containerId={containerId} columnIndex={columnIndex}/>
										</DropdownItem>
									})
								}
							</DropdownMenu>
						</Dropdown>
					</div>
				}
            </Cell>
        </div>
    }
}

export default ActionCell;