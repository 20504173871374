import React from 'react';
import LayoutItemContainer from '../../../../../containers/workflow/LayoutItemContainer';
import { Row, Col } from 'reactstrap';

const BSRow = (props) => {
    const { columns, breakpoint, layoutItems, workflow, ...rest } = props;
    return (
        <Row className='container-row'>
            {
                columns.map((column, index) => {
                    const config = { size: column.w, offset: column.x };
                    const windowWidth = window.innerWidth;
                    const oneColumnWidth = windowWidth / 12;
                    const layoutItemWidth = oneColumnWidth * column.w;
                    if (index > 0) {
                        const prevColumn = columns[index - 1];
                        config.offset = column.x - (prevColumn.x + prevColumn.w);
                    }
                    //if(workflow.workflowModel == 220) config.size = 12;
                    return <Col key={`${index}_${workflow.workflowModel}_${workflow.currentItem.id}`} md={config} className='container-column'>
                        <LayoutItemContainer
                            layoutItem={layoutItems[Number(column.i)]}
                            itemIndex={index}
                            workflow={workflow}
                            layoutItemWidth={layoutItemWidth}
                            { ...rest }
                        />
                    </Col>
                })
            }
        </Row>
    )
}

export default BSRow;