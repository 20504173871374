import React, { Component } from 'react';
import PropTypes from 'prop-types';
import WorkflowCardLayout from '../WorkflowCardLayout';
import { Label, Button, ButtonGroup } from 'reactstrap';
import ActionContainer from '../../../containers/workflow/ActionContainer';
import DataListStore from '../../workflow/operations/filters/DataListStore';
import DataListWrapper from '../../workflow/operations/filters/DataListWrapper';
import FilterWrapperHOC from '../../workflow/operations/filters/FilterWrapperHOC';

const findActionByCode = (actions, code) => {
    let matchedAction = null;
    for (let i = 0; i < actions.length; i += 1) {
        if (actions[i].actionProperties.generatedActionCode && actions[i].actionProperties.generatedActionCode === code) {
            matchedAction = actions[i]
        }
    }
    return matchedAction;
}

class TypeModelWorkflowCard extends Component {
    constructor(props) {
      super(props);
    }
    

    render() {
        const { workflow, cards, label, inlineActions, viewMode, typeModelViewMode, onChangeTypeModelViewMode, 
            layoutItem: { itemProperties }, menuTypeInlineAction,additionalModelData } = this.props;
        
        return (
            <div>
            <div className='d-flex justify-content-between mb-2'>
                {
                    label.show && (
                <div>
                    <Label className='text-muted text-capitalize mb-0'>{label.text}</Label>
                </div>)
                }
                <div>
                    {
                        itemProperties && itemProperties.allowAdditionalTypeModelViewMode && <ButtonGroup className="mr-4">
                            <Button onClick={onChangeTypeModelViewMode.bind(this, typeModelViewMode.value)} color='primary' outline={viewMode !== typeModelViewMode.value} size="sm">
                                <i className="fa fa-th-list" aria-hidden="true"></i>
                            </Button>
                            <Button onClick={onChangeTypeModelViewMode.bind(this, itemProperties.additionalTypeModelViewMode.value)} color='primary' outline={viewMode !== itemProperties.additionalTypeModelViewMode.value} size="sm">
                                <i className="fa fa-th-large" aria-hidden="true"></i>
                            </Button>
                        </ButtonGroup>
                    }
                    <TypeModelCreateAction workflow={workflow} itemProperties={itemProperties} actions={workflow.currentItem.inlineAction} />
                </div>
            </div>

            <WorkflowCardLayout 
                workflowCards={cards}
                workflowId={workflow.workflowModel}
                inlineActions={inlineActions}
                workflowCardConfig={itemProperties.readOneWorkflowUniqueCode ? {readOneWorkflowUniqueCode: itemProperties.readOneWorkflowUniqueCode} : null}
                workflow={workflow}
                editActionId={itemProperties.allowEditing ? itemProperties.generatedActionCodeForAllowEditing : null}
                menuTypeInlineAction={[]}
                additionalModelData={additionalModelData}
            />

        </div>
        );
    }
}

const TypeModelCreateAction = (props) => {
    const { actions, itemProperties, workflow } = props;
    const { allowAddition, generatedActionCodeForAllowAddition } = itemProperties;
    if (!allowAddition) return null;
    const action = findActionByCode(actions, generatedActionCodeForAllowAddition);
    if (!action) return null;
    const { actionProperties } = action;
    if(actionProperties && actionProperties.actionDisabled) {
        return null;
    }
    return (
        <ActionContainer workflow={workflow} action={action} />
    )
}

TypeModelWorkflowCard.defaultProps = {
    cards: []
}

TypeModelWorkflowCard.propTypes = {
    workflow: PropTypes.object,
    cards: PropTypes.array,
    label: PropTypes.object
};

export default TypeModelWorkflowCard;