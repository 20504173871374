import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SubWorkflowTrigger from './SubWorkflowTrigger';
import ContextualTextColor from './ContextualTextColor';
import { analyseEvaluatedText } from '../../commons/evaluatedTextVerfier';
import DisplayFile from './DisplayFile';

class TextStyleResolver extends Component {
    render() {
        const { config, text, cellStyle, customIcons,keyValue, toggleImageModal,showAsBadge, showInModal, companyDetails, ...rest } = this.props;
        if (!config && !text) return null;
        if (!config) return text;
        const { isTextualActionTriggerEnabled, contextual_text_color } = config;
        if(config.fileType && config.fileType.value) {
            return <DisplayFile  config={config} text={text} toggleImageModal={toggleImageModal} companyDetails={companyDetails}  />
        }
        if (contextual_text_color) {
            return <ContextualTextColor customIcons={customIcons} text={text} config={config} showAsBadge={showAsBadge}  {...rest} />
        }
        if (isTextualActionTriggerEnabled) {
            return <SubWorkflowTrigger text={text} config={config} keyValue={keyValue} {...rest} />
        }
        const analysedText = analyseEvaluatedText(text);
        let showAsLink = false;
        try {
            showAsLink = analysedText && (analysedText.indexOf('http:') != -1 || analysedText.indexOf('https:') != -1
                || analysedText.indexOf('www.') != -1);
        } catch (error) { }
        return (// DEFAULT PRINT
            showAsLink ? showInModal ? <a href="#" onClick={this.props.toggleModal}>
                {analysedText}
            </a> : <a href={(analysedText.indexOf('http:') == -1 && analysedText.indexOf('https') == -1 ? "//" : "") + analysedText} target="_blank">{analysedText}</a> :
                <span title={analysedText} style={cellStyle}>{analysedText}</span>
        )
    }
}

TextStyleResolver.defaultProps = {
    config: null,
    text: null
}

TextStyleResolver.propTypes = {
    config: PropTypes.object
};

export default TextStyleResolver;