import client from '../client';
import config from '../config';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';

export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

export const GET_USERS_PROFILE = 'GET_USERS_PROFILE_PROFILE';
export const GET_USERS_PROFILE_SUCCESS = 'GET_USERS_PROFILE_SUCCESS';
export const GET_USERS_PROFILE_FAILURE = 'GET_USERS_PROFILE_FAILURE';

export const SET_USER = 'SET_USER_AUTH';

export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const RESET_PASSWORD_RESET_STATE = 'RESET_PASSWORD_RESET_STATE';

export const SET_PASSWORD = 'SET_PASSWORD';
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';
export const SET_PASSWORD_FAILURE = 'SET_PASSWORD_FAILURE';
export const SET_PASSWORD_RESET_STATE = 'SET_PASSWORD_RESET_STATE';

export const SAVE_USER_PREFERENCE = 'SAVE_USER_PREFERENCE';
export const SAVE_USER_PREFERENCE_SUCCESS = 'SAVE_USER_PREFERENCE_SUCCESS';
export const SAVE_USER_PREFERENCE_FAILURE = 'SAVE_USER_PREFERENCE_FAILURE';

export const SAVE_USER_DASHBOARD = 'SAVE_USER_DASHBOARD';
export const SAVE_USER_DASHBOARD_SUCCESS = 'SAVE_USER_DASHBOARD_SUCCESS';
export const SAVE_USER_DASHBOARD_FAILURE = 'SAVE_USER_DASHBOARD_FAILURE';

export const LOAD_USER_DASHBOARD = 'LOAD_USER_DASHBOARD';
export const LOAD_USER_DASHBOARD_SUCCESS = 'LOAD_USER_DASHBOARD_SUCCESS';
export const LOAD_USER_DASHBOARD_FAILURE = 'LOAD_USER_DASHBOARD_FAILURE';

export const login = (payload, successCallback) => {
  return dispatch => {
    dispatch({
      type: LOGIN_USER,
      payload: {},
    });
    dispatch(showLoading());
    client()
      .post(`${config.publicAPI}/login`, payload)
      .then(res => {
        if (!payload.authenticateUsingPlugins) {
          // localStorage.setItem('user', JSON.stringify(res.data));
          window.user = res.data
          dispatch({
            type: LOGIN_USER_SUCCESS,
            payload: res.data,
          });
          dispatch(hideLoading());
        }
        localStorage.setItem("_captcha_details", JSON.stringify({
          count: 0,
          date: new Date()
        }));
        successCallback && successCallback();
      })
      .catch(error => {
      let reCaptchaDetails = localStorage.getItem("_captcha_details");
      reCaptchaDetails = JSON.parse(reCaptchaDetails);
      if(!reCaptchaDetails) {
        localStorage.setItem("_captcha_details", JSON.stringify({
          count: 0,
          date: new Date()
        }));
      } else {
        const lastSetDate = reCaptchaDetails.date;
        const currentDate = new Date();
        let diff = Math.abs(currentDate - lastSetDate);
        let minutes = Math.floor((diff/1000)/60);
        if(minutes > 30) {
          localStorage.setItem("_captcha_details", JSON.stringify({
            count: 0,
            date: new Date()
          }));
        } else {
          localStorage.setItem("_captcha_details", JSON.stringify({
            count: reCaptchaDetails.count + 1,
            date: new Date()
          }));
        }

      }
        dispatch({
          type: LOGIN_USER_FAILURE,
          payload: error.response.data,
        });
        dispatch(hideLoading());
      });
  };
};

export const signUp = payload => {
  return dispatch => {
    dispatch({
      type: SIGNUP,
      payload: {},
    });
    dispatch(showLoading());
    client()
      .post(`${config.publicAPI}/signup`, payload)
      .then(res => {
        dispatch({
          type: SIGNUP_SUCCESS,
          payload: res.data,
        });
        dispatch(hideLoading());
      })
      .catch(error => {
        dispatch({
          type: SIGNUP_FAILURE,
          payload: error,
        });
        dispatch(hideLoading());
      });
  };
};

export const resetPassword = payload => {
  return dispatch => {
    dispatch({
      type: RESET_PASSWORD,
      payload: {},
    });
    dispatch(showLoading());
    client()
      .post(`${config.publicAPI}/resetpassword`, payload)
      .then(res => {
        dispatch({
          type: RESET_PASSWORD_SUCCESS,
          payload: res.data,
        });
        dispatch(hideLoading());
      })
      .catch(error => {
        dispatch({
          type: RESET_PASSWORD_FAILURE,
          payload: error,
        });
        dispatch(hideLoading());
      });
  };
};

export const setPassword = payload => {
  return dispatch => {
    dispatch({
      type: SET_PASSWORD,
      payload: {},
    });
    dispatch(showLoading());
    client()
      .post(`${config.publicAPI}/setpassword`, payload)
      .then(res => {
        dispatch({
          type: SET_PASSWORD_SUCCESS,
          payload: res.data,
        });
        dispatch(hideLoading());
      })
      .catch(error => {
        dispatch({
          type: SET_PASSWORD_FAILURE,
          payload: error,
        });
        dispatch(hideLoading());
      });
  };
};

export const getUsers = () => {
  return dispatch => {
    dispatch({
      type: LOGIN_USER,
      payload: {},
    });
    dispatch(showLoading());
    client()
      .post(`${config.authAPI}/users/list`)
      .then(res => {
        dispatch({
          type: GET_USERS_SUCCESS,
          payload: res.data,
        });
        dispatch(hideLoading());
      })
      .catch(error => {
        dispatch({
          type: GET_USERS_FAILURE,
          payload: error,
        });
        dispatch(hideLoading());
      });
  };
};

export const getUserProfile = () => {
  return dispatch => {
    if(window.profileCache) {
      dispatch({
        type: GET_USERS_PROFILE_SUCCESS,
        payload: window.profileCache,
      });
      return;
    }
    dispatch({
      type: GET_USERS_PROFILE,
      payload: {},
    });
    dispatch(showLoading());
    client()
      .post(`${config.authAPI}/user/profile`)
      .then(res => {
        window.profileCache = res.data;
        let user = window.user ? window.user : localStorage.getItem('user') || null;
	      user = typeof user === 'string' ? JSON.parse(user) : user;
        if(user && res.data && res.data.data) {
          user = {
            ...user,
            ...res.data.data
          }
        }
        localStorage.setItem('user', JSON.stringify(user));
        window.user = null
        if(user.id) {
						client()
						.post(`${config.authAPI}/g`, {
							id: 1280
						})
						.then(globalData => {
							globalData.data.data && localStorage.setItem('globalData', JSON.stringify(globalData.data.data));
							globalData.data.data && localStorage.setItem('orderitems', JSON.stringify(globalData.data.data.orderitems));
						}).catch(globalDataError => {
							console.error(globalDataError);
						})
					}
        dispatch({
          type: GET_USERS_PROFILE_SUCCESS,
          payload: res.data,
        });
        dispatch(hideLoading());
      })
      .catch(error => {
        dispatch({
          type: GET_USERS_PROFILE_FAILURE,
          payload: error,
        });
        dispatch(hideLoading());
      });
  };
};

export const validateToken = () => {
  return dispatch => {
    return client().post(`${config.authAPI}/users/list`);
  };
};

export const logout = (history,companyDetails) => {
  const logoutUrl = companyDetails && companyDetails.data && companyDetails.data.logoutURL ? companyDetails.data.logoutURL : '/app/login'
  const logoutHistory = companyDetails && companyDetails.data && companyDetails.data.logoutURL ? companyDetails.data.logoutURL : '/app/login'
  return dispatch => {
    client()
      .post(`${config.authAPI}/user/logout`)
      .then(res => {
      })
      .catch(error => {
      });
      history && history.push(logoutHistory);
      localStorage.removeItem('user');
      window.location.href = logoutUrl;
  };
};

export const setUser = payload => {
  return dispatch => {
    dispatch({
      type: SET_USER,
      payload,
    });
  };
};

export const resetSetpasswordState = payload => {
  return dispatch => {
    dispatch({
      type: SET_PASSWORD_RESET_STATE,
      payload,
    });
  };
};

export const resetForgotPasswordState = payload => {
  return dispatch => {
    dispatch({
      type: RESET_PASSWORD_RESET_STATE,
      payload,
    });
  };
};

export const saveDashboard = payload => {
  return dispatch => {
    dispatch({
      type: SAVE_USER_DASHBOARD,
      payload: {},
    });
    dispatch(showLoading());
    client()
      .post(`${config.authAPI}/user/savedashboard`, payload)
      .then(res => {
        dispatch({
          type: SAVE_USER_DASHBOARD_SUCCESS,
          payload: res.data,
        });
        dispatch(hideLoading());
      })
      .catch(error => {
        dispatch({
          type: SAVE_USER_DASHBOARD_FAILURE,
          payload: error,
        });
        dispatch(hideLoading());
      });
  };
};

export const loadDashboard = () => {
  return dispatch => {
    dispatch({
      type: LOAD_USER_DASHBOARD,
      payload: {},
    });
    dispatch(showLoading());
    client()
      .post(`${config.authAPI}/user/loaddashboard`)
      .then(res => {
        dispatch({
          type: LOAD_USER_DASHBOARD_SUCCESS,
          payload: res.data,
        });
        dispatch(hideLoading());
      })
      .catch(error => {
        dispatch({
          type: LOAD_USER_DASHBOARD_FAILURE,
          payload: error,
        });
        dispatch(hideLoading());
      });
  };
};

export const syncWf = payload => {
  return dispatch => {
    // dispatch({
    //   type: SAVE_USER_DASHBOARD,
    //   payload: {},
    // });
    // dispatch(showLoading());
    client()
      .post(`${config.authAPI}/syncOfflineWorkflow`, payload)
      .then(res => {
        console.log(res)
        // dispatch({
        //   type: SAVE_USER_DASHBOARD_SUCCESS,
        //   payload: res.data,
        // });
        // dispatch(hideLoading());
      })
      .catch(error => {
        // dispatch({
        //   type: SAVE_USER_DASHBOARD_FAILURE,
        //   payload: error,
        // });
        // dispatch(hideLoading());
      });
  };
};
