import React, { Component } from 'react';
import * as ActionElements from '../../action-elements/index';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toJS } from '../../../containers/to-js';
import { getWorkflowFromCode } from '../../../actions/workflows';
import confirm from '../../commons/confirm/index';
import { toast } from 'react-toastify';
import { messages } from '../../../dto/user-messages';
import { ModelPropertiesParser } from '../../commons/modelPropertiesParser';

class ActionElement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            workflowId: null,
            hide: true,
			showToast: false
        }
    }
    componentDidMount() {
        const { workflow: { sessionId }, getWorkflowFromCode, invokeActionRules, action: { actionProperties: { workflow, inlineActionPlacement }, connector } } = this.props;
        //TODO: Need the below if... commenting it for now to try and remove async
        if(connector && connector.rules && connector.rules.length) {
            const self = this;
            if(inlineActionPlacement === 'INLINE') {
                if(!this.allMatch(connector.rules)) {
                    self.setState({ hide: true});
                } else {
                    self.setState({ hide: false});
                }
            } else {
                invokeActionRules({
                    connectorId: connector.id,
                    sessionId
                }).then(rules => {
                    if(rules.data) {
                        self.setState({ hide: !rules.data.data });
                    }
                });
            }
        } else {
            this.setState({hide: false});
        }
        
        if (this.resolveExternal()) {
            getWorkflowFromCode(workflow).then((res) => {
                const workflowId = res.data.data;
                this.setState({ workflowId });
            });
        }
    }

    allMatch(rules) {
        for(var rule in rules) {
            if(!this.ruleMatch(rules[rule]))
                return false;
        }
        return true;
    }

    ruleMatch(rule) {
        const { user } = this.props;
        var operator = rule.operator ? rule.operator : '';
        let chkParamRule = false
        if (rule && rule.resolveSession && rule.sessionParamCode == "HasRoleSessionParam") {
            chkParamRule = true;
            const userPermission = user && user.user && user.user.permissions;
            let staticValue = rule.value && rule.value.staticValue;
            let found = false;
            if(staticValue.indexOf('|') != -1){
              staticValue = staticValue.split('|');
              found = userPermission && userPermission.find(element => staticValue.includes(element));
            } else {
              found = userPermission && userPermission.find(element => staticValue == element);
            }
            
            if (found) {
              return true;
            }
        }
        if(!chkParamRule)
        {
            switch(operator.toUpperCase()) {
                case 'IN': 
                    return this.matchIn(rule);
                case 'NOTIN':
                    return !this.matchIn(rule);
                case 'EQUALS':
                    return this.match(rule);
                case 'NOTEQUALS':
                    return !this.match(rule);
                case 'LIKE':
                    return this.include(rule);
                case 'NOTLIKE':
                    return !this.include(rule);
                case 'NOTNULL':
                    return !this.match(rule);
                case 'ISNULL':
                    return this.match(rule);
                default:
                    return true;
            }
        }
    }

    matchIn(rule) {
        const { workflow: { currentItem: {typeModel: name}, workflowData: { __ADDITIONAL_MODEL_DATA }}, row} = this.props;
        const rightModelName = rule.value.typeModelName;
        const leftObjId = row.id;
        const rightObj = rightModelName === name? this.props.workflowData: __ADDITIONAL_MODEL_DATA[rightModelName];
        const rightObjCollection = rightObj && rightObj[rule.value.property];
        if(!rightObjCollection || !rightObjCollection.length) return false;
        for(var key in rightObjCollection) {
            var obj = rightObjCollection[key];
            if(obj.id === leftObjId) return true;
        }
        return false;
    }
	
	match(rule) {
        const { workflow: { currentItem: {typeModel: name}}, row, workflowData, user} = this.props;
        const rightModelName = rule.value && rule.value.typeModelName;
		let staticValue = rule.value && rule.value.staticValue;
        const value = rule.key? (row ? row[rule.key.property] : workflowData ? workflowData[rule.key.property] : null):null;
        if(typeof value === 'undefined' || value === null) {
            if(rule.operator === 'IsNull') return true;
            return false;
        }
		if(!staticValue) return false;
		staticValue = staticValue.indexOf('{{') == -1 ? staticValue : this.resolveStaticValue(staticValue, user);
        if(staticValue == value) {
            return true;
        }
        return false;
    }
	include(rule) {
        const { workflow: { currentItem: {typeModel: name}}, row, workflowData, user} = this.props;
        const rightModelName = rule.value.typeModelName;
		let staticValue = rule.value.staticValue;
		if(!staticValue) return false;
		staticValue = staticValue.indexOf('{{') == -1 ? staticValue : this.resolveStaticValue(staticValue, user);
		const value = rule.key? (row ? row[rule.key.property] : workflowData ? workflowData[rule.key.property] : null):null;
        if(value && value.includes(staticValue)) {
            return true;
        }
        return false;
    }
	
	resolveStaticValue(staticValue, user) {
        staticValue = ModelPropertiesParser(staticValue, user);
        return staticValue;
    }

    componentWillReceiveProps(nextProps) {
        const { actionState, clearActionTriggeredState, action: { actionProperties: { actionSuccessMessage } } } = nextProps;
		const { showToast } = this.state;
        if (actionState && !actionState.isLoading && showToast) {
            if (!actionState.hasError) {
                toast.success(actionSuccessMessage ? actionSuccessMessage : messages.defaultSuccessMessage);
            }
            else {
                toast.error(messages.defaultActionErrorMessage);
            }
			this.setState({ showToast: false });
            clearActionTriggeredState(nextProps.action);
        }
    }
    resolveExternal() {
        const { action: { actionType, actionProperties: { displayMode } }, workflow } = this.props;
        let openInExternal = false;
        // if (actionType === 'SubWorkflow') {
        //     openInExternal = workflow.displayMode !== 'DEFAULT' && workflow.displayMode !== displayMode;
        // }
        return openInExternal;
    }
    checkBeforeTriggering() {
        const { triggerAction, action: { code } } = this.props;
        if (code === 'DeleteActionProcessor') {
            this.confirmDelete();
        }
        else {
            triggerAction();
        }

    }
    confirmDelete() {
        const { triggerAction, workflow: { currentItem: { typeModel: { name } } } } = this.props;
        const deleteOptions = {
            heading: `Delete ${name}`,
            proceed: {
                text: 'Delete',
                iconClass: 'fa fa-trash',
                color: 'danger'
            }
        }
        confirm('Are you sure you want to delete this record?', deleteOptions).then(
            (ev) => {
                this.props.triggerAction();
				this.setState({ showToast: true });
                // window.location.reload()
            },
            (ev) => { }
        )
    }
    render() {
        const { action, triggerAction, workflow, actionState, workflowData ,customIcons, wfData,showAsPlaneText} = this.props;
        const { actionProperties: { elementType, hideInWeb } } = action;
        const { workflowId } = this.state;
        const Element = ActionElements[elementType || 'BUTTON'];
		if(hideInWeb) return null;
        if(this.state.hide) return null;
        if (this.resolveExternal()) {
            const navigateTo = this.context.router.route.match.path.replace(':id', workflowId);
            return <ActionElements.EXTERNAL_LINK
                action={action}
                navigateTo={navigateTo}
            />
        }
        return (
            <Element wfData={wfData} showAsPlaneText={showAsPlaneText} actionState={actionState} customIcons={customIcons} action={action} triggerAction={this.checkBeforeTriggering.bind(this)} workflow={workflow} workflowData={workflowData}/>
        );
    }
}

ActionElement.contextTypes = {
    router: PropTypes.object
}

const mapStateToProps = (state, ownProps) => {
  return { 
        user: state.get('user')
      };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getWorkflowFromCode: (code) => {
            return dispatch(getWorkflowFromCode(code));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(ActionElement));