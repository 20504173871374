import React from 'react';
import config2 from '../../../config';

const DisplayFile = (props) => {

    const { text,toggleImageModal, config, companyDetails } = props;
    const columnImageConfig = config.columnImageConfig;
    
    let imagePath = text;
    if(config && config.fileType && config.fileType.value === "IMAGE") {
        if(text && text.startsWith('file:///data/user') && companyDetails && companyDetails.data && companyDetails.data.name) {
            const split = text.split("/");
            const imageName = split[split.length - 1];
            const companyName = companyDetails.data.name.toLowerCase();
            imagePath = `${companyName}/${imageName}`
        }

        const height = columnImageConfig && columnImageConfig.height ? parseInt(columnImageConfig.height) : 80;
        const width = columnImageConfig && columnImageConfig.width ? parseInt(columnImageConfig.width) : 80;
        return (
            <div style={{cursor: 'pointer'}} 
                onClick={toggleImageModal}>
                <img src={`${config2.assetsBasePath}${imagePath}`} height={height} width={width} alt="TableImage" />
            </div>
        )
    }
    
    return <div>{}</div>
}

export default DisplayFile;