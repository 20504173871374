import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Col } from 'reactstrap';

class DropdownFilter extends Component {
    constructor(props) {
        super(props);
        const { filterStateMap, filterIndex } = props;
        const savedFilterState = filterStateMap[`filter_${filterIndex}`];
        this.state = {
            selectedOption: savedFilterState ? this.getSelectedOption(savedFilterState) : '',
            options: []
        }
    }
	componentWillReceiveProps(nextProps) {
		const { filterStateMap, filterIndex } = nextProps;
        const savedFilterState = filterStateMap[`filter_${filterIndex}`];
		this.setState({
            selectedOption: savedFilterState ? this.getSelectedOption(savedFilterState) : ''
        })
	}
	
    triggerFilterChange(value) {
        const { onFilterChange, filter, filterIndex } = this.props;
        this.setState({ value });
        const filterConfig = {
            filter,
            filterIndex
        }
		value != "" ? onFilterChange(filterConfig, [value]) : onFilterChange(filterConfig, []);
    }
    handleChange(selectedOption) {
        //const { filter, onFilterChange } = this.props;
        selectedOption = !selectedOption ? '' : selectedOption;
        this.setState({ selectedOption });
		this.triggerFilterChange(selectedOption.value);
        
    }
    componentDidMount() {
        const { getSuggestions, filter } = this.props;
        getSuggestions().then((res) => {
            const values = res.data;
            const options = values.map((val) => {
                const customText = filter[`${val.filterValue}`]
                return {
                    value: val.filterValue,
                    label: customText ? customText : val.filterValue
                };
            });
            options.unshift({
                value: '',
                label: 'All' 
            });
            this.setState({ options });
        })
    }

    getSelectedOption(savedState) {
        const { filterBy } = savedState;
        return filterBy[0];
    }
    
    render() {
        const { filter, elem_id } = this.props;
        const { selectedOption, options } = this.state;
        const { showClickableHorizontalView } = filter;
        return (
            <Col xs="12" className='w-100 p-0'>
                <Select
                    name={elem_id}
                    value={selectedOption}
                    onChange={this.handleChange.bind(this)}
                    options={options}
                    clearable={false}
                />
            </Col>
        );
    }
}

DropdownFilter.propTypes = {

};

export default DropdownFilter;