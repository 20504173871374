import React, { useState, useEffect } from 'react';
import { Label } from 'reactstrap';
import { BarChart , Bar, YAxis, XAxis, ResponsiveContainer,Legend, Cell, Tooltip } from 'recharts';
import COLORS from '../../../dto/chart-colors';
import { ModelPropertiesParser } from '../../commons/modelPropertiesParser';

const _HBarChart = props => {
  const [filters, onChangeFilters] = useState([]);
  const [data, onChangeData] = useState([]);
  const {
    reportConfig,
    label,
    graphHeight,
    getReportDataByAction,
    workflow,
  } = props;
  useEffect(() => {
    if (
      reportConfig &&
      Array.isArray(reportConfig.filters) &&
      reportConfig.filters[0]
    ) {
      getReportDataByAction({
        workflowSessionId: workflow.sessionId,
        actionUniqueId: reportConfig.filters[0].actionGuid.value,
        filters,
        actionName: reportConfig.filters[0].actionGuid.label
      })
        .then(res => {
          try {
            const responseDataArray = res.data.data;
            const updatedData = Object.assign([], data);
            for (let index = 0; index < responseDataArray.length; index++) {
              const responseData = res.data.data[index];
              const label = ModelPropertiesParser(reportConfig.columns[0].value, responseData);
              const value = ModelPropertiesParser(reportConfig.columns[1].value, responseData);
              const entry = {
                label: label,
                value: parseInt(value),
              };
              updatedData.push(entry);
            }
/*            const responseData = res.data.data[0];
            const modelKeys = Object.keys(responseData);
            try {
              modelKeys.map((k, i) => {
                responseData[k.replace(' ', '_')] = JSON.parse(responseData[k]);
              });
            }catch(ee) {
              console.log(ee);
            }
            const updatedData = Object.assign([], data);
            reportConfig.columns &&
              reportConfig.columns.map((col, colIndex) => {
                const value = ModelPropertiesParser(col.value, responseData);
                console.log(col.value);
                const entry = {
                  label: col.label,
                  value: parseInt(value),
                };
                updatedData.push(entry);
              });*/
            onChangeData(updatedData);
          } catch (e) {
            console.log(e, 'JSON PARSE ERROR');
          }
        })
        .catch(ex => {
          onChangeData([]);
        });
    }
  }, [filters]);

  return (
    <div
      style={{ width: '100%', padding: 10 }}
      className='bg-white mt-3 rounded-lg'
    >
      {label.show && <Label>{label.text}</Label>}

      {data.length ? (
        <ResponsiveContainer height={graphHeight} width={'99%'}>
          <BarChart height={graphHeight} data={data} layout='vertical'>
          <XAxis  type='number'/>
          <YAxis dataKey="label" type="category"/>
          <Tooltip />
          <Legend />
          <Bar dataKey="value" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 100,
          }}
        >
          <img
            src='https://cn-mumbai-assets.s3.ap-south-1.amazonaws.com/public/nojobsfound-1.png'
            height={100}
            width={200}
          ></img>
        </div>
      )}
    </div>
  );
};

const responseDataDDD = {
  data: [
    {
      'total persons': { name: 'rakes', age: 5 },
    },
  ],
};

export default _HBarChart;
