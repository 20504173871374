import { connect } from 'react-redux';
import SetPassword from '../components/pages/login/SetPassword';
import { getTranslate } from 'react-localize-redux';
import { toJS } from './to-js';
import { setPassword, resetSetpasswordState } from '../actions/user';
import { getCompanyDetails } from '../actions/company';



const mapStateToProps = (state) => {
    return {
        setPasswordState: state.getIn(['user', 'setPassword']),
        translate: getTranslate(state.get('locale')),
		companyDetails: state.getIn(['company', 'details']),
        user: state.get('user'),
    };
    };


const mapDispatchToProps = (dispatch) => {
    return {
        onSubmit: (values) => {
            dispatch(setPassword(values));
        },
        resetSetpasswordState: () => {
            dispatch(resetSetpasswordState());
        },
		getCompanyDetails: () => {
            dispatch(getCompanyDetails());
        }
    }
};



export default connect(mapStateToProps, mapDispatchToProps)(toJS(SetPassword));