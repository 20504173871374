import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SubmenuItem from './SubmenuItem';
import SubmenuItem3 from './SubmenuItem3';
import { Row,Col, Dropdown, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, InputGroup, InputGroupAddon, Input, UncontrolledTooltip } from 'reactstrap';


class WorkflowSubMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            actionsDDOpen: false,
            containersDDOpen: false,
            moreOptionsDDOpen: false
        }
    }
    toggle(key) {
        this.setState({ [key]: !this.state[key] });
    }
    componentWillUnmount() {
        this.props.onSubmenuClicked(0);
    }
    componentWillReceiveProps(nextProps) {
        const { onSubmenuClicked, submenuActiveIndex, workflow: {currentItem: {layoutProperties: { workflowSubmenuConfig: { items } }} }} = nextProps;
        if (submenuActiveIndex >= items.length) {
            onSubmenuClicked(0);
        }
    }
    
    render() {
        const { workflow: { currentItem: { layoutProperties: { mainContainerWidth, workflowSubmenuConfig, pageHeaderConfig } } }, submenuActiveIndex, onSubmenuClicked,customIcons,hasUnusedContainers ,companyDetails,user} = this.props;
        if (!workflowSubmenuConfig || (workflowSubmenuConfig && !workflowSubmenuConfig.isActive)) return null;
        const { items, tabPosition, subMenuUITemplate } = workflowSubmenuConfig;
        const showLimited = workflowSubmenuConfig && workflowSubmenuConfig.showLimited ? workflowSubmenuConfig.showLimited : null 
        const noOfSubmenu = workflowSubmenuConfig && workflowSubmenuConfig.noOfSubmenu ? workflowSubmenuConfig.noOfSubmenu : null 
        const intNoOfSubmenu = parseInt(noOfSubmenu)
        if (!items || (items && !items.length)) return null;
        const additionalClass = pageHeaderConfig && pageHeaderConfig.hidePageTitle?'no-header':'';
		const tabPositionValue = tabPosition ? tabPosition.value.toLowerCase() : 'top';
		const subMenuUITemplateValue = subMenuUITemplate ? subMenuUITemplate.value : null;
		const tabPositionClass = tabPositionValue+'-tab-position';
		let containerWidth = mainContainerWidth ? Number(mainContainerWidth) : '';
        const menuType = companyDetails && companyDetails.data && 
        (user && user.user && user.user.email?companyDetails.data.menuType:companyDetails.data.publicMenuType);

        const topMenuEnabled= companyDetails && companyDetails.data && companyDetails.data.companySettings && companyDetails.data.companySettings.topBarEnabled
    
		const style = {
			width: `${window && window.innerWidth <= 920 ? 100 : containerWidth}%`,
			margin: hasUnusedContainers?'auto':'none',
            marginTop:hasUnusedContainers?'auto' :topMenuEnabled ? 120:60,
			position: hasUnusedContainers?'relative':'fixed',
			height: tabPositionValue === 'left' || tabPositionValue === 'right' ? 'auto' : '40px',
            marginLeft:menuType === 'SIDEBARWITHTOPBAR' ? 45 :0
		};

        const style2 = {
			width: `${window && window.innerWidth <= 920 ? 100 : containerWidth}%`,
			margin: hasUnusedContainers?'auto':'none',
            marginTop:hasUnusedContainers?'auto' :topMenuEnabled ? 120:60,
			position: hasUnusedContainers?'relative':'fixed',
            marginLeft:menuType === 'SIDEBARWITHTOPBAR' ? 45 :0
		};

        if(subMenuUITemplateValue === 'Template3'){
            return (
                <div className={`workflow-submenu-main-3 ${additionalClass} ${tabPositionClass} ${subMenuUITemplateValue === 'Template2' && 'workflow-submenu-main-2'}`}  style={containerWidth ? style2 : menuType === 'SIDEBARWITHTOPBAR' ? {marginLeft:45} :{}} >
                
                {
                  !showLimited &&  <Row className="nav nav-pills"> {items.map((item, index) => {
                            return <SubmenuItem3 
                                active={submenuActiveIndex === index} 
                                submenuItem={item} 
                                submenuItems={items}
                                onSubmenuClicked={onSubmenuClicked.bind(this, index)}
                                customIcons={customIcons}
                                key={index} 
                            />
                        })}
                        </Row>
                    }
    
            </div>
            )
        }
        return (
            <div className={`workflow-submenu-main ${additionalClass} ${tabPositionClass} ${subMenuUITemplateValue === 'Template2' && 'workflow-submenu-main-2'} ${hasUnusedContainers && 'usedContainer-submenu'}`}  style={containerWidth  || hasUnusedContainers ? style : menuType === 'SIDEBARWITHTOPBAR' ? {marginLeft:45} :{}} >
                
                {
                  !showLimited &&  <ul className="nav nav-pills"> {items.map((item, index) => {
                            return <SubmenuItem 
                                active={submenuActiveIndex === index} 
                                submenuItem={item} 
                                submenuItems={items}
                                onSubmenuClicked={onSubmenuClicked.bind(this, index)}
                                customIcons={customIcons}
                                key={index} 
                            />
                        })}
                        </ul>
                    }
                   {showLimited && intNoOfSubmenu > 1 &&  <ul className="nav nav-pills"> 
                     {
                        items.map((item, index) => {
                            if(index < intNoOfSubmenu )
                            return <SubmenuItem 
                                active={submenuActiveIndex === index} 
                                submenuItem={item} 
                                submenuItems={items}
                                onSubmenuClicked={onSubmenuClicked.bind(this, index)}
                                customIcons={customIcons}
                                key={index} 
                            />
                        })
                    }
                <Dropdown size="sm" isOpen={this.state.moreOptionsDDOpen} toggle={this.toggle.bind(this, 'moreOptionsDDOpen')}>   
                    <DropdownToggle color='link'>
                        <i className="fa fa-ellipsis-v fa-lg" aria-hidden="true"></i>
                    </DropdownToggle>
                    <DropdownMenu bottom>
                    {items.map((item, index) => {  
                        if(index >= intNoOfSubmenu )
                   return    <div> <DropdownItem tag='a' onClick={onSubmenuClicked.bind(this, index)}>
                             {item.label}
                             </DropdownItem>
                             <DropdownItem divider />
                             </div>
                        })}
                    </DropdownMenu>
                </Dropdown>
                </ul>}
    
            </div>
        );
    }
}

WorkflowSubMenu.propTypes = {
    workflow: PropTypes.object
};

export default WorkflowSubMenu;