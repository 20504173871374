import PieChart from './PieChart';
import TableReport from './TableReport';
import CardReport from './CardReport';
import BarChart from './BarChart';
import HBarChart from './HBarChart';

export const PIECHART = PieChart;
export const TABLE = TableReport;
export const CARDREPORT = CardReport;
export const BARCHART = BarChart;
export const HBARCHART = HBarChart;