import React from 'react';
import { Collapse } from 'reactstrap';
import NavWorkflowsContainer from '../../containers/NavWorkflowsContainer';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import RenderIcon from '../commons/RenderIcon';

class SidebarWorkflowMenuItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: null,
      activeParentMenu: null,
      activeMenu: null,
      activeIndex:null,
      activeChildIndex:null
    }
  }

  componentDidMount() {
    this.props.fetchWorkflows(this.props.user);
  }

  componentWillReceiveProps(nextProps) {
    const { activeTab } = this.state;
    const { router } = this.context;
    const { items } = nextProps;
    if(!activeTab) {
      items && items.map((item, itemIndex) => {
        if(`/app/${item.friendlyUrl.toLowerCase()}` === router.route.location.pathname) {
          this.setState({ activeTab: item.friendlyUrl.toLowerCase()})
        }
      });
    }
  }

  setActiveMenu = (e,activeMenu, prevent,parentIndex,childIndex) => {
    if(prevent) {
      e.stopPropagation();
    }
    this.setState({ activeMenu,activeIndex:parentIndex,activeChildIndex:childIndex});
  }

  resolveIsActive = (match, location) => {
    if (!match || !location) {
        return false
    }
    const { path } = match;
    const { pathname } = location;
    if (pathname === path) {
        return true;
    }
    return false;
  }

  activateChildMenu = (menuId) => {
    console.log(menuId);
    if ( this.state.activeParentMenu === menuId ) {
      this.setState({ activeParentMenu: null });
    } else {
      this.setState({ activeParentMenu: menuId });
    }
  }

  setActiveTab = (tabIndex) => {
    this.setState({ activeTab: tabIndex });
  }
  render() {
    const { router } = this.context;
    const {activeIndex,activeChildIndex} = this.state
    const { isActive, isPublic, baseWorkflowRoute, isScreenerMonkeyApp, items, logout ,customIcons,data} = this.props;
    const sideMenuConfig = data && data.companySettings ? data.companySettings : null 
    const onlySignOut = sideMenuConfig && sideMenuConfig.sideMenuDefaultLogout ? sideMenuConfig.sideMenuDefaultLogout : null 
    return (
      <div className="sidebar-workflow-menu-items">
        <ul className="sidebar-workflow-menu-list">
          {
            items && items.map((parentItem, parentIndex) => {
              let parentItemProperties = parentItem && parentItem.properties ? parentItem.properties : null
              if (parentItemProperties !== null) {
                var obj = JSON.parse(parentItemProperties);
                var parentItemIconObject = obj
              }
              const parentItemIcon = parentItemIconObject && parentItemIconObject.web && parentItemIconObject.web.webMenuIcon ? parentItemIconObject.web.webMenuIcon : null
              const parentItemCustomIcon = parentItemIconObject && parentItemIconObject.web && parentItemIconObject.web.customWebIcon ? parentItemIconObject.web.customWebIcon : null
              const parentItemCustomIconSize = parentItemIconObject && parentItemIconObject.web && parentItemIconObject.web.webIconSize ? parentItemIconObject.web.webIconSize : null
              const mobileOnly = parentItemIconObject && parentItemIconObject.displayMode ? parentItemIconObject.displayMode : null
              const iconConfig = {
                cssClass: parentItem.menuIcon,  // for nenne-admin fontawesome icons
                cssClass2: parentItemIcon,      // for diy-admin  fontawesome icons
                cssCustomIconMenu:parentItemCustomIcon, // custom menu icons  
                webIconSizeMenu:parentItemCustomIconSize // custom icon Size  
              }
              if (parentItem.hideInMenu) return null;
              if (mobileOnly && mobileOnly.value === 2) return null;
              const disableMenuWeb = parentItemIconObject && parentItemIconObject.web && parentItemIconObject.web.disableInWeb ? parentItemIconObject.web.disableInWeb : null
              if (disableMenuWeb) return null;
              const hasChildren = parentItem.childMenus && parentItem.childMenus.length > 0;
              const routerBase = router.route.match.path;
              const parentPath = `${baseWorkflowRoute}/${parentItem.friendlyUrl.toLowerCase()}`;
              const { title } = parentItem;
              return hasChildren ? (
                <li className="sidebar-workflow-menu-parent-item" key={parentIndex} onClick={() => this.activateChildMenu(`sidebar-workflow-menu-${parentIndex}`)}>
                  <a className="sidebar-workflow-menu-parent-item-link d-flex ">
                    <span className="sidebar-workflow-menu-parent-item-icon">
                      <RenderIcon config={iconConfig} customIcons={customIcons} />
                    </span>
                    <span style={{marginTop: 6}}>{parentItem.title}</span>
                    <i className={`hasChild ml-auto fa ${this.state.activeParentMenu === `sidebar-workflow-menu-${parentIndex}` ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                  </a>
                  <Collapse isOpen={this.state.activeParentMenu === `sidebar-workflow-menu-${parentIndex}`} >
                    <ul className="sidebar-workflow-child-menu-list">
                      {
                        parentItem.childMenus && parentItem.childMenus.map((childItem, childIndex) => {
                          const childIconConfig = {
                            cssClass: childItem.menuIcon
                          }
                          if (childItem.hideInMenu) return null;
                          const path = `${baseWorkflowRoute}/${childItem.friendlyUrl.toLowerCase()}`;
                          const childPath = `${path}`;
                          return (
                            <li className={`sidebar-workflow-menu-child-item  ${activeIndex == parentIndex && activeChildIndex == childIndex ? "active" : ""}`}  key={childIndex}>
                              <NavLink isActive={this.resolveIsActive}  onClick={(e) => this.setActiveMenu(e,childItem.friendlyUrl.toLowerCase(),true,parentIndex, childIndex) }   className="sidebar-workflow-menu-child-item-link d-flex" to={childPath}>
                                <span className="sidebar-workflow-menu-child-item-icon">
                                  <RenderIcon config={childIconConfig} customIcons={customIcons} />
                                </span>
                              <div style={{marginTop:6}}>  {childItem.title} </div>
                              </NavLink>
                            </li>
                          )
                        })
                      }
                    </ul>
                  </Collapse>
                </li>
              ) : (
                <li className={`sidebar-workflow-menu-parent-item  ${activeIndex == parentIndex ? "active" : ""}`} key={parentIndex}>
                  <NavLink isActive={this.resolveIsActive} onClick={(e) => this.setActiveMenu(e,parentItem.friendlyUrl.toLowerCase(),false, parentIndex,0) } className="sidebar-workflow-menu-parent-item-link d-flex" to={parentPath}>
                    <span className="sidebar-workflow-menu-parent-item-icon">
                      <RenderIcon config={iconConfig} customIcons={customIcons}/>
                    </span>
                    <span style={{marginTop: 6}}>{parentItem.title}</span>
                  </NavLink>
                </li>
              )
            })
          }
          {
            isPublic ? (
              <li className="sidebar-workflow-menu-parent-item">
                <NavLink isActive={this.resolveIsActive} onClick={ this.setActiveMenu.bind(this, 'login') } className="sidebar-workflow-menu-parent-item-link" to="/app/login">
                  <span className="sidebar-workflow-menu-parent-item-icon">
                    <i className='fa fa-sign-out'></i>
                  </span>
                  Sign-In
                </NavLink>
              </li>
            ) : ( onlySignOut === "onlySignOut" ?
            <li className="sidebar-workflow-menu-parent-item  onlySignOut">
                <NavLink isActive={this.resolveIsActive} onClick={ this.setActiveMenu.bind(this, 'signout') } className="sidebar-workflow-menu-parent-item-link" to="#" onClick={() => this.props.logout()}>
                  <span className="sidebar-workflow-menu-parent-item-icon">
                    <i className='fa fa-sign-out'></i>
                  </span>
                  Sign Out
                </NavLink>
              </li>:
              <li className="sidebar-workflow-menu-parent-item" onClick={() => this.activateChildMenu(`sidebar-account`)}>
                <a className="sidebar-workflow-menu-parent-item-link">
                  <span className="sidebar-workflow-menu-parent-item-icon">
                    <i className='fa fa-user-md'></i>
                  </span>
                    Account
                  <i className={`hasChild fa ${ this.state.activeParentMenu === `sidebar-account` ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                </a>
                <Collapse isOpen={this.state.activeParentMenu === `sidebar-account`} >
                  <ul className="sidebar-workflow-child-menu-list">
                    <li className="sidebar-workflow-menu-child-item">
                      <NavLink isActive={this.resolveIsActive} onClick={ this.setActiveMenu.bind(this, 'profile') } className="sidebar-workflow-menu-child-item-link" to="/profile">
                        <span className="sidebar-workflow-menu-child-item-icon">
                          <i className='fa fa-user'></i>
                        </span>
                        Profile
                      </NavLink>
                    </li>
                    <li className="sidebar-workflow-menu-child-item">
                      <NavLink isActive={this.resolveIsActive} onClick={ this.setActiveMenu.bind(this, 'signout') } className="sidebar-workflow-menu-child-item-link" to="#" onClick={() => this.props.logout()}>
                        <span className="sidebar-workflow-menu-child-item-icon">
                          <i className='fa fa-sign-out'></i>
                        </span>
                        Sign Out
                      </NavLink>
                    </li>
                  </ul>
                </Collapse>
              </li>
            )
          }
        </ul>
      </div>
    )
  }
}


SidebarWorkflowMenuItems.contextTypes = {
  router: PropTypes.object
}

export default SidebarWorkflowMenuItems;