import React, { Component } from 'react';
import ActionContainer from '../../../containers/workflow/ActionContainer';
import { Button } from 'reactstrap';
import { fromJS } from 'immutable'

class SubWorkflowTrigger extends Component {
    getMatchedAction() {
        const { workflow: { currentItem: { inlineAction } }, config: { textualActionTriggerCode } } = this.props;
        let matchedAction = null;
		for (let i = 0; i < inlineAction.length; i += 1) {
            if (inlineAction[i].actionProperties.hasOwnProperty('generatedActionCode') && inlineAction[i].actionProperties.generatedActionCode === textualActionTriggerCode) {
                matchedAction = inlineAction[i];
                break;
            }
        }
        return matchedAction
    }
	
	
    render() {
        const { workflow, rows, rowIndex, data, text,keyValue } = this.props;
		if (!workflow) return null;
        const action = this.getMatchedAction();
		if (!action) return null;
        let copyAction = fromJS(action);
        copyAction = copyAction.toJS();
        copyAction.actionProperties.elementText = text;
        copyAction.actionProperties.elementType = 'LINK';
		let tid = data ? data.id : rows.getObjectAt(rowIndex)['id'];
      
        let wfData = rows && rows.getObjectAt(rowIndex);
        if(keyValue){
            tid = rows.getObjectAt(keyValue)['id'];
            wfData = rows.getObjectAt(keyValue);
            
       }
        const { actionProperties } = copyAction;
        if(actionProperties && actionProperties.actionDisabled) {
            return null;
        }
		return (
            <div className='subwfactiontrigger'><ActionContainer wfData={wfData} workflow={workflow} action={copyAction} tid={tid} /></div>
        )
    }
}

export default SubWorkflowTrigger;