import PublicLayout from '../public-layout';
import { connect } from 'react-redux';
import { getTranslate, getActiveLanguage, setActiveLanguage, addTranslationForLanguage, setLanguages } from 'react-localize-redux';
import { clearWorkflowState } from '../actions/workflows'
import { getCompanyDetails,getCustomIcons, getLanguageTranslations} from '../actions/company';
import { fetchNavWorkflows } from '../actions/workflows';
import { toJS } from './to-js';

const mapStateToProps = (state) => {
    const locale = state.get('locale');
    const companySettings = state.getIn(['company', 'details', 'data', 'companySettings']);
    return {
        translate: getTranslate(locale),
        currentLanguage: getActiveLanguage(locale) ? getActiveLanguage(locale).code : 'en',
        isPublic: true,
        user: state.get('user'),
        companyDetails: state.getIn(['company', 'details']),
        availableLanguages : companySettings && companySettings.get('langauges'),
      };
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearWorkflowState: () => {
            dispatch(clearWorkflowState());
        },
        getCompanyDetails: () => {
            dispatch(getCompanyDetails());
        },
        getCustomIcons: payload => {
            dispatch(getCustomIcons(payload));
          },
        fetchNavWorkflows: (user) => {
            if(user) {
                dispatch(fetchNavWorkflows(user));
            }
        },
        setActiveLanguage : payload => {
            dispatch(setActiveLanguage(payload))
        },
        getLanguageTranslations : () => {
            return dispatch(getLanguageTranslations())
        },
        addTranslationForLanguage : (lang, code) => {
            dispatch(addTranslationForLanguage(lang, code));
        },
        setLanguages : (langs) => {
            dispatch(setLanguages(langs));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(PublicLayout));