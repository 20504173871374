import React, { Component } from 'react';
import config from '../../config';
import { Button, Alert } from 'reactstrap';
import fileExtension from 'file-extension';
import client from '../../client';

class PreviewFile extends Component {
    render() {
        const { data, layoutItem: { itemProperties: { fileOptions } } } = this.props;
        let DATA_HREF = `${config.assetsBasePath}${data}`;
        const iframeStyle = { border: 'none' };
        const extension = fileExtension(DATA_HREF);
        const showPreview = fileOptions && fileOptions.showPreview === true ? true : false;
        return (
            <div className={`file-preview-wrapper w-100 ${showPreview ? 'showing-preview mt-2' : 'mt-1'}`}>
                { data && data != null && <Button size={showPreview ? 'sm' : null} onClick={ () => {
                    if(data.startsWith("protected/")) {
                        client().get(`${config.playerApi}/${data}`).then(res => {
                            window.open(res.data);
                        }).catch(e => {
                            console.log(e)
                        })
                    } else {
                        
                        window.open((data && !data.startsWith('http')) ? (config.assetsBasePath + data) : data)
                    }
                }
                } color='link' className='p-0 view-file-btn'>
                        <i className="fa fa-external-link mr-1" aria-hidden="true"></i>View/Download ({data ? data.substr(data.lastIndexOf('/')) : ''})
                    </Button>
                }
                {
                    showPreview && (extension === 'pdf' || extension === 'png' || extension === 'jpg' || extension === 'gif' || extension === 'jpeg') ? <object data={DATA_HREF} width="100%" height={extension === 'pdf'?"600":"100%"}>
                        <iframe src={DATA_HREF} width="100%" height="100%" style={iframeStyle}>
                            This browser does not support PDFs. Please download the PDF to view it: <a href={DATA_HREF}>Download PDF</a>
                        </iframe>
                    
                    </object> : ''
                }
                {
                    showPreview && (extension === 'doc' || extension === 'docx') ? 
                        <Alert color='warning'>
                            Doc/Docx file preview not available yet. Please <a href={DATA_HREF}>Download</a> the and view file.
                        </Alert> : ''
                }

            </div>      
        );
    }
}

export default PreviewFile;