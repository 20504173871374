import React, { Component } from 'react';
import TypeModelCreateUpdate from './TypeModelCreateUpdate';
import CustomTypeModelCreateUpdate from './CustomTypeModelCreateUpdate';
import TypeModelReadOne from './TypeModelReadOne';
import { getFormValues } from 'redux-form/immutable';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { toJS } from '../../containers/to-js';
import { save, updateWorkflowData, getfindbyfieldname } from '../../actions/workflow-item';
import {onUIAction} from '../../utils/uiActions';

class TypeModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditing: false,
            isSaving: false,
            uiAction: null,
            paginationStart: 0,
            pageSize: 25
        }
    }

    callUIAction() {
        const { classProperty } = this.props;
        onUIAction(this.props.__form__values, this.props.dispatch, this.state.uiAction, this.props.change, classProperty);
    }

    componentDidMount(){
        const { workflow, classProperty, additionalModelData} = this.props;
        const uia = workflow && workflow.currentItem.layoutProperties.uiActions;
        console.log(workflow.workflowData,additionalModelData)
        if(Array.isArray(uia)) {
            uia.map((action, aIndex) => {
                if(classProperty === action.triggerActionOn) {
                    this.setState({uiAction:uia});
                }
            })
        }
    }
    setEditable(isEditing) {
        this.setState({ isEditing });
    }
    onEditSaved() {
        const { __form__values, saveInline, workflow: { currentItem: { typeModel } } } = this.props;
        const info = {
            typeModelName: typeModel.safeName,
            customerName: typeModel.customerName
        };
        this.setState({ isSaving: true });
        saveInline(__form__values, info);
    }
    componentWillReceiveProps(nextProps) {
        const { inlineSaveData, workflow: { workflowData }, updateWorkflowData } = nextProps;
        if (!inlineSaveData.isLoading && this.state.isSaving) {
            this.setState({ isSaving: false });
        }
        if (inlineSaveData.data && workflowData) {
            if (inlineSaveData.data.version !== workflowData.version) {
                updateWorkflowData(inlineSaveData.data, nextProps.workflow);
                this.setState({ isEditing: false });

            }
        }
    }

    onChangePage = (paginationStart, pageSize) => {
        this.setState({ paginationStart, pageSize });
    }
    onChangePageSize = (pageSize) => {
        this.setState({ pageSize, paginationStart: 0 });
    }
  
    render() {
        const { isReadOne, layoutItem,additionalModelData } = this.props;
        const { collection, workflow: { workflowData }, classProperty, layoutItem: { itemProperties: { showAsTextFilter, modelSelector, label, isLayoutItemEditable, field: { type } } }, inlineNextState } = this.props;
        const { isEditing, isSaving, paginationStart, pageSize } = this.state;
        if (!layoutItem) return null;
        let RenderComp = isReadOne ? TypeModelReadOne : TypeModelCreateUpdate;

        if(showAsTextFilter) {
            RenderComp = CustomTypeModelCreateUpdate;
        }

        return <div className="type-model">
                {
                    isEditing ? <div className={`${isEditing ? 'is-editing-layout-item' : ''}`}>
                        <TypeModelCreateUpdate isEditing={isEditing} isReadOne={false}  isLayoutItemEditable={isLayoutItemEditable} {...this.props} />
                        <div className='d-flex justify-content-end'>
                            <Button disabled={isSaving} onClick={this.onEditSaved.bind(this)} color='secondary' size='sm'>
                                {
                                    isSaving ? 'Saving...' : 'Save'
                                }
                            </Button>
                        </div>
                    </div> : <div className='w-100'>
                        <RenderComp paginationStart={paginationStart} pageSize={pageSize}  additionalModelData={additionalModelData}
                            onChangePageSize={this.onChangePageSize} onChangePage={this.onChangePage} callUIAction={this.callUIAction.bind(this)} {...this.props} />
                    </div>
                }
                {
                    isLayoutItemEditable === true && isEditing === false ? <Button onClick={this.setEditable.bind(this, true)} size='sm' color='link' className='pt-0 text-muted edit-layout-item-button'>
                    <i className="fa fa-pencil" aria-hidden="true"></i>
                </Button> : ''
                }
            </div>
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        __form__values: getFormValues(ownProps.form)(state),
        inlineSaveData: state.get('save')
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveInline: (payload, info) => {
            dispatch(save(payload, info, false));
        },
        updateWorkflowData: (workflowData, workflow) => {
            dispatch(updateWorkflowData(workflowData, workflow));
        },
        getfindbyfieldname: (payload) => {
            return dispatch(getfindbyfieldname(payload));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(toJS(TypeModel));
