import React from 'react';
import * as charts from '../dashboard/charts';
import { connect } from 'react-redux';
import { toJS } from '../../containers/to-js';
import { getReportDataByName } from '../../actions/reports';
import {
  Alert,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CHART_TYPES from '../../dto/chart-types';
import COLORS from '../../dto/chart-colors';
import * as FilterElements from './ReportFilter/index';
import FileDownload from './FileDownload';
import Pagination from '../commons/pagination';
import moment from 'moment';
import config from '../../config';

import  { Skeleton } from "antd";
import LoadingNumberReport from './LoadingSkelton/LoadingNumberReport';
import LoadingLineChart from './LoadingSkelton/LoadingLineChart';
import LoadingPieChart from './LoadingSkelton/LoadingPieChart';
import LoadingBarChart from './LoadingSkelton/LoadingBarChart';

class Report extends React.Component {
  constructor(props) {
    super(props);
    const {
      layoutItem: {
        itemProperties: {
          reportConfig: { reportDefaultType },
        },
      },
    } = props;
    this.state = {
      filterStateMap: {},
      paginationStart: 0,
      pageSize: 25,
      filterParam: '',
      dailyReport: null,
      weeklyReport: null,
      monthlyReport: null,
      fullReport: false,
      past10Report: false,
      past30Report: true,
      past60Report: false,
      reportName: null,
      imageDropdownOption: null,
      tableDropdownOption: null,
      chart: {
        isLoading: true,
        data: null,
        hasError: false,
        originalReportType: null,
        reportType: reportDefaultType
          ? reportDefaultType
          : {
            label: 'Line Chart',
            code: 'LINECHART',
            icon: 'fa fa-line-chart',
          },
      },
      startDate: null,
      endDate: null
    };

    this._onFilterChange = this._onFilterChange.bind(this);
    this.onFiltersApplied = this.onFiltersApplied.bind(this);
    this.onClearAppliedFilters = this.onClearAppliedFilters.bind(this);
  }

  onChangePage = (paginationStart, pageSize) => {
    this.setState({ paginationStart, pageSize }, () => {
      this.onFiltersApplied();
    });
  };
  onChangePageSize = pageSize => {
    this.setState({ pageSize, paginationStart: 0 }, () => {
      this.onFiltersApplied();
    });
  };

  resetFilterReport = () => {
    this.onChangeTableFilter(null);
    this.allReportClicked();
  };
  onFiltersApplied() {
    const filterMap = Object.assign({}, this.state.filterStateMap);
    let filterParam =
      'start=' +
      this.state.paginationStart +
      '&count=' +
      this.state.pageSize +
      '&';
    if (Object.keys(filterMap).length) {
      for (let filterKey in filterMap) {
        filterParam =
          filterParam + filterKey + '=' + filterMap[filterKey] + '&';
      }
    }
    filterParam = filterParam.substring(0, filterParam.lastIndexOf('&'));

    const {
      getReportDataByName,
      layoutItem: {
        itemProperties: {
          reportConfig: {
            report: { name },
            dailyReport,
            weeklyReport,
            monthlyReport,
          },
        },
      },
    } = this.props;
    let monthlyReportDefault = false;
    if (dailyReport && weeklyReport && monthlyReport) {
      let today = new moment();
      let beforeOneMonth = moment().subtract(30, 'd');
      beforeOneMonth.set({ hour: 0, minute: 0, second: 0 });
      const startDate = beforeOneMonth.format('YYYY-MM-DD HH:mm:ss');
      today.set({ hour: 23, minute: 59, second: 59 });
      const endDate = today.format('YYYY-MM-DD HH:mm:ss');

      filterParam = filterParam + '&startDate=' + startDate + '&';
      filterParam = filterParam + 'endDate=' + endDate;
      monthlyReportDefault = true;
    }
    this.setState({ filterParam: filterParam });
    getReportDataByName({
      name: this.state.reportName ? this.state.reportName : name,
      filterParam,
    })
      .then(res => {
        const chart = Object.assign({}, this.state.chart);
        chart.isLoading = false;
        chart.data = res && res.data && res.data.data ? res.data.data : {};
        chart.hasError = false;
        this.setState({ chart, monthlyReport: monthlyReportDefault });
      })
      .catch(ex => {
        const chart = Object.assign({}, this.state.chart);
        chart.isLoading = false;
        chart.data = null;
        chart.reportType = CHART_TYPES.LINECHART;
        chart.hasError = true;
        this.setState({ chart, monthlyReport: monthlyReportDefault });
      });
  }
  onClearAppliedFilters() {
    this.setState({ filterStateMap: {}, reportName: null });
    this.setState({ filterParam: '' });
    let filterParam =
      'start=' + this.state.paginationStart + '&count=' + this.state.pageSize;
    const {
      getReportDataByName,
      layoutItem: {
        itemProperties: {
          reportConfig: {
            report: { name },
          },
        },
      },
    } = this.props;
    getReportDataByName({ name, filterParam })
      .then(res => {
        const chart = Object.assign({}, this.state.chart);
        chart.isLoading = false;
        chart.data = res && res.data && res.data.data ? res.data.data : {};
        chart.hasError = false;
        this.setState({
          chart,
          dailyReport: false,
          weeklyReport: false,
          monthlyReport: false,
        });
      })
      .catch(ex => {
        const chart = Object.assign({}, this.state.chart);
        chart.isLoading = false;
        chart.data = null;
        chart.reportType = CHART_TYPES.LINECHART;
        chart.hasError = true;
        this.setState({ chart });
      });
  }
  _onFilterChange(filterParam, filterValue) {
    const { filterStateMap } = this.state;
    filterStateMap[`${filterParam}`] = filterValue;
    this.setState({ filterStateMap });
  }
  componentDidMount() {
    const {
      getReportDataByName,
      layoutItem: {
        itemProperties: {
          reportConfig: {
            report: { name },
            dailyReport,
            weeklyReport,
            monthlyReport,
          },
        },
      },
      uiFilters,
    } = this.props;
    let filterParam = this.state.filterParam;
    filterParam =
      filterParam +
      'start=' +
      this.state.paginationStart +
      '&count=' +
      this.state.pageSize;
    let monthlyReportDefault = false;
    if (dailyReport && weeklyReport && monthlyReport) {
      let today = new moment();
      let beforeOneMonth = moment().subtract(30, 'd');
      beforeOneMonth.set({ hour: 0, minute: 0, second: 0 });
      const startDate = beforeOneMonth.format('YYYY-MM-DD HH:mm:ss');
      today.set({ hour: 23, minute: 59, second: 59 });
      const endDate = today.format('YYYY-MM-DD HH:mm:ss');

      filterParam = filterParam + '&startDate=' + startDate + '&';
      filterParam = filterParam + 'endDate=' + endDate;
      monthlyReportDefault = true;
    }

    if (uiFilters) {
      const keys = Object.keys(uiFilters);
      keys.map(k => {
        filterParam =
          filterParam +
          `&${k}=${uiFilters[k]}`;
      });
    }

    getReportDataByName({ name, filterParam })
      .then(res => {
        const chart = Object.assign({}, this.state.chart);
        chart.isLoading = false;
        chart.data = res && res.data && res.data.data ? res.data.data : {};
        chart.hasError = false;
        this.setState({ chart, monthlyReport: monthlyReportDefault });
      })
      .catch(ex => {
        const chart = Object.assign({}, this.state.chart);
        chart.isLoading = false;
        chart.data = null;
        chart.reportType = CHART_TYPES.LINECHART;
        chart.hasError = true;
        this.setState({ chart, monthlyReport: monthlyReportDefault });
      });
  }

  componentWillReceiveProps(nextProps) {
    if (
      JSON.stringify(nextProps.uiFilters) !==
      JSON.stringify(this.props.uiFilters)
    ) {
      let filterStateMap = this.state.filterStateMap;
      if (nextProps.uiFilters) {
        const keys = Object.keys(nextProps.uiFilters);
        keys.map(k => {
          filterStateMap[k] =
            nextProps.uiFilters[k];
        });
      } else {
      }
      this.setState({ filterStateMap }, () => {
        console.log('==========');
        this.onFiltersApplied();
      });
    }
  }

  getLegendName(data) {
    if (data.columns && data.columns[1]) {
      let str = data.columns[1];
      return str;
    }
    return null;
  }
  getXLabel(data) {
    if (data.columns && data.columns.length === 2) {
      return data.columns[0];
    }
    if (data.columns && data.columns.length === 3) {
      return data.columns[1];
    }
    return null;
  }
  getYLabel(data) {
    if (data.columns && data.columns.length === 2) {
      return data.columns[1];
    }
    if (data.columns && data.columns.length === 3) {
      return data.columns[2];
    }
    return null;
  }

  getChartDisplayOptions() {
    const options = [];
    for (let key in CHART_TYPES) {
      const CHART = CHART_TYPES[key];
      const isActive = this.state.chart.reportType.code === CHART.code;
      options.push(
        <DropdownItem
          className={`${isActive ? 'text-success' : ''}`}
          key={key}
          onClick={() => {
            this.updateReportType(CHART);
          }}
        >
          <i className={`${CHART.icon} mr-2`} aria-hidden='true'></i>
          {CHART.label}
          {isActive ? (
            <i className={`fa fa-check ml-2`} aria-hidden='true'></i>
          ) : (
            ''
          )}
        </DropdownItem>
      );
    }
    return options;
  }

  updateReportType(type) {
    const chart = this.state.chart;
    chart.reportType = type;
    this.setState({ chart });
  }

  onDailyReportClicked() {
    const { filterStateMap } = this.state;
    let today = new moment();
    today.set({ hour: 0, minute: 0, second: 0 });
    const startDate = today.format('YYYY-MM-DD HH:mm:ss');
    today.set({ hour: 23, minute: 59, second: 59 });
    const endDate = today.format('YYYY-MM-DD HH:mm:ss');

    filterStateMap['startDate'] = startDate;
    filterStateMap['endDate'] = endDate;
    this.setState({
      filterStateMap,
      dailyReport: true,
      weeklyReport: false,
      monthlyReport: false,
    });
    this.onFiltersApplied();
  }

  onMonthlyReportClicked() {
    const { filterStateMap } = this.state;
    let today = new moment();
    let beforeOneMonth = moment().subtract(30, 'd');
    beforeOneMonth.set({ hour: 0, minute: 0, second: 0 });
    const startDate = beforeOneMonth.format('YYYY-MM-DD HH:mm:ss');
    today.set({ hour: 23, minute: 59, second: 59 });
    const endDate = today.format('YYYY-MM-DD HH:mm:ss');

    filterStateMap['startDate'] = startDate;
    filterStateMap['endDate'] = endDate;
    this.setState({
      filterStateMap,
      past30Report: true,
      monthlyReport: true,
      fullReport: false,
      past10Report: false,
      past60Report: false,
    });
    this.onFiltersApplied();
  }

  past10DaysReportClicked() {
    const { filterStateMap } = this.state;
    let today = new moment();
    let beforeOneMonth = moment().subtract(10, 'd');
    beforeOneMonth.set({ hour: 0, minute: 0, second: 0 });
    const startDate = beforeOneMonth.format('YYYY-MM-DD HH:mm:ss');
    today.set({ hour: 23, minute: 59, second: 59 });
    const endDate = today.format('YYYY-MM-DD HH:mm:ss');

    filterStateMap['startDate'] = startDate;
    filterStateMap['endDate'] = endDate;
    this.setState({
      filterStateMap,
      past30Report: false,
      fullReport: false,
      past10Report: true,
      past60Report: false,
    });
    this.onFiltersApplied();
  }

  past60DaysReportClicked() {
    const { filterStateMap } = this.state;
    let today = new moment();
    let beforeOneMonth = moment().subtract(60, 'd');
    beforeOneMonth.set({ hour: 0, minute: 0, second: 0 });
    const startDate = beforeOneMonth.format('YYYY-MM-DD HH:mm:ss');
    today.set({ hour: 23, minute: 59, second: 59 });
    const endDate = today.format('YYYY-MM-DD HH:mm:ss');

    filterStateMap['startDate'] = startDate;
    filterStateMap['endDate'] = endDate;
    this.setState({
      filterStateMap,
      past30Report: false,
      fullReport: false,
      past10Report: false,
      past60Report: true,
    });
    this.onFiltersApplied();
  }

  allReportClicked() {
    const { filterStateMap } = this.state;
    let today = new moment();
    let beforeOneMonth = moment().subtract(2000, 'd');
    beforeOneMonth.set({ hour: 0, minute: 0, second: 0 });
    const startDate = beforeOneMonth.format('YYYY-MM-DD HH:mm:ss');
    today.set({ hour: 23, minute: 59, second: 59 });
    const endDate = today.format('YYYY-MM-DD HH:mm:ss');

    filterStateMap['startDate'] = startDate;
    filterStateMap['endDate'] = endDate;
    this.setState({
      filterStateMap,
      past30Report: false,
      fullReport: true,
      past10Report: false,
      past60Report: false,
      monthlyReport: false,
      weeklyReport: false,
    });
    this.onFiltersApplied();
  }

  onChangeAudit = selectedOption => {
    this.setState({ imageDropdownOption: selectedOption });
  };

  onChangeTableFilter = selectedOption => {
    this.setState({ tableDropdownOption: selectedOption });
  };

  onWeeklyReportClicked() {
    const { filterStateMap } = this.state;
    let today = new moment();

    let beforeOneWeek = moment().subtract(7, 'd');
    beforeOneWeek.set({ hour: 0, minute: 0, second: 0 });
    const startDate = beforeOneWeek.format('YYYY-MM-DD HH:mm:ss');
    today.set({ hour: 23, minute: 59, second: 59 });
    const endDate = today.format('YYYY-MM-DD HH:mm:ss');

    filterStateMap['startDate'] = startDate;
    filterStateMap['endDate'] = endDate;
    this.setState({
      filterStateMap,
      weeklyReport: true,
      dailyReport: false,
      monthlyReport: false,
    });
    this.onFiltersApplied();
  }

  handleFromChange(date) {
    const { filterStateMap } = this.state;
    const startDate = date.format('YYYY-MM-DD HH:mm:ss');
    filterStateMap['startDate'] = startDate;
    this.setState({ startDate: date, filterStateMap });
  }
  handleToChange(date) {
    const { filterStateMap } = this.state;
    const endDate = date.format('YYYY-MM-DD HH:mm:ss');
    filterStateMap['endDate'] = endDate;
    this.setState({ endDate: date, filterStateMap });
  }

  render() {
    const {
      companyDetails,
      layoutItem: {
        itemProperties: {
          beforeIcon,
          placeholder,
          textColor,
          reportConfig: {
            report,
            reportCTA,
            modelParams,
            dailyReport,
            weeklyReport,
            monthlyReport,
            dateRange,
            showDropdownFilter,
            duplicateRowsInOneToMany,
            reportGroups,
            reportTitle,
            hideMenu,
            elementHeight,
            timelineconfig,
            locationConfig,
            imageCardConfig,
            barChartConfig,
            tableConfig,
            numberConfig,
          },
          reportConfig,
        },
      },
    } = this.props;
    const { chart, filterStateMap, startDate, endDate } = this.state;
    const { isLoading, reportType, data, hasError, originalReportType } = chart;
    const { paginationStart, pageSize } = this.state;
    const loaderTemplate =
    this.props.companyDetails &&
      this.props.companyDetails.data &&
      this.props.companyDetails.data.companySettings &&    this.props.companyDetails.data.companySettings.loaderTemplate 
      ? this.props.companyDetails.data.companySettings.loaderTemplate
      : null;
    console.log(loaderTemplate,"companyDetailscompanyDetails")
    const displayOnUi =
      modelParams && modelParams.find(element => element.displayOnUI == true);

    const reportPrimaryColor = reportConfig && reportConfig.reportPrimaryColor;
    const filters = modelParams && displayOnUi ? modelParams : [];
    const reportFilters =
      reportGroups && reportGroups[0] && reportGroups[0].isActive
        ? reportGroups[0].reportFilters
        : [];

        if ( isLoading && loaderTemplate === 'skeleton') {
          if(reportType && reportType.code === 'NUMBER'){
            return <LoadingNumberReport/>;
          }else if(reportType && reportType.code === 'PIECHART'){
            return <LoadingPieChart/>;
          }else if(reportType && reportType.code === 'BARCHART'){
            return <LoadingBarChart/>;
          }else if(reportType && reportType.code === 'LINECHART'){
            return <LoadingLineChart/>;
          }else {
            return <Skeleton active={true}  style={{paddingTop:20,paddingBottom:30,borderWidth:0.5,margin:20}}/>
          }
        
        }else if(isLoading) {
          return <p className='text-muted'>Loading...</p>;
        }
    let Component = reportType ? charts[reportType.code] : null;
    if (hasError) {
      return (
        <Alert color='danger'>
          <i className='fa fa-exclamation-triangle mr-1' aria-hidden='true'></i>
          Something went wrong while trying to get the report data!
        </Alert>
      );
    }

    let dateFormat = companyDetails && companyDetails.data && companyDetails.data != null ? companyDetails.data.companySettings.dateformat : 
            '';
        dateFormat = dateFormat ? dateFormat.toUpperCase() : '';

    const numberReportTemplate =
      numberConfig && numberConfig.numberReportTemplate.value;
    const reportBackgroundColor =
      numberConfig && numberConfig.reportBackgroundColor;
    const gradientColor =
      numberConfig && numberConfig.gradientColor;
    const template2 = numberReportTemplate === 'template2';
    const template3 = numberReportTemplate === 'template3';
    const template4 = numberReportTemplate === 'template4';
    const reportNameFinal = reportTitle
      ? reportTitle
      : placeholder.show
        ? placeholder.text
        : report.name;

    const filterDropdown = imageCardConfig && imageCardConfig.filterDropdown;
    const filterProperty =
      imageCardConfig &&
      imageCardConfig.filterProperty &&
      imageCardConfig.filterProperty.value;
    const imageFilterilterShowAs =
      imageCardConfig &&
      imageCardConfig.filterShowAs &&
      imageCardConfig.filterShowAs.value;

    const hideInPDFDownload =
      this.props.layoutItem && this.props.layoutItem.itemProperties
        ? this.props.layoutItem.itemProperties.hideInPDFDownload
        : null;

    const filterDropdownTable = tableConfig && tableConfig.filterDropdown;
    const showFilterAsDropdown = tableConfig && tableConfig.showFilterAsDropdown;
    const filterPropertyTable =
      tableConfig &&
      tableConfig.filterProperty &&
      tableConfig.filterProperty.value;


    const tableFIlterType =
      tableConfig && tableConfig.filterShowAs && tableConfig.filterShowAs.value;

    const typeOneLabel = imageCardConfig && imageCardConfig.typeOneLabel;
    const typeOneImageField =
      imageCardConfig &&
      imageCardConfig.typeOneImage &&
      imageCardConfig.typeOneImage.value;
    const typeTwoLabel = imageCardConfig && imageCardConfig.typeTwoLabel;
    const typeTwoImageField =
      imageCardConfig &&
      imageCardConfig.typeTwoImage &&
      imageCardConfig.typeTwoImage.value;
    const titleField =
      imageCardConfig && imageCardConfig.title && imageCardConfig.title.value;
    const subTitleField =
      imageCardConfig &&
      imageCardConfig.subTitle &&
      imageCardConfig.subTitle.value;
    const descriptionLabel =
      imageCardConfig && imageCardConfig.descriptionLabel;
    const descriptionField =
      imageCardConfig &&
      imageCardConfig.description &&
      imageCardConfig.description.value;

    const cards = [];
    Array.isArray(data.graphData) &&
      data.graphData.map((item, i) => {
        if (item.tableData[titleField]) {
          let subtitle = item.tableData[subTitleField];
          if (subtitle) {
            const date = moment(subtitle + ' GMT');
            if (date.isValid()) {
              subtitle = date.format('MMM DD YYYY hh:mm a');
            }
          }
          if (item.tableData[typeOneImageField]) {
            const card = {
              type: typeOneLabel,
              image: `${config.htmlTemplatePath}/${item.tableData[typeOneImageField]}`,
              title: item.tableData[titleField],
              subTitle: subtitle,
              descriptionLabel: descriptionLabel,
              description: item.tableData[descriptionField],
            };
            cards.push(card);
          }
          if (item.tableData[typeTwoImageField]) {
            const card = {
              type: typeTwoLabel,
              image: `${config.htmlTemplatePath}/${item.tableData[typeTwoImageField]}`,
              title: item.tableData[titleField],
              subTitle: subtitle,
              descriptionLabel: descriptionLabel,
              description: item.tableData[descriptionField],
            };
            cards.push(card);
          }
        }
      });

    let imageCardFilterOptions = [];

    Array.isArray(data.graphData) &&
      data.graphData.map((item, i) => {
        if (item.tableData[filterProperty]) {
          const imageCardOption = {
            option: item.tableData[filterProperty],
          };
          imageCardFilterOptions.push(imageCardOption);
        }
      });

    let tableFilterOptions = [];

    Array.isArray(data.graphData) &&
      data.graphData.map((item, i) => {
        if (item.tableData[filterPropertyTable]) {
          const tableOption = {
            option: item.tableData[filterPropertyTable],
          };
          tableFilterOptions.push(tableOption);
        }
      });

    const filterRepeatedOptions =
      tableFilterOptions &&
      tableFilterOptions.reduce((acc, current) => {
        const x = acc.find(item => item.option === current.option);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

    const filterRepeatedImageCardOptions =
      imageCardFilterOptions &&
      imageCardFilterOptions.reduce((acc, current) => {
        const x = acc.find(item => item.option === current.option);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

    const filterdImageArray = cards.filter(card => {
      return card.description === this.state.imageDropdownOption;
    });

    const filterdTableData =
      filterPropertyTable &&
      data.graphData &&
      data.graphData.filter(item => {
        return (
          item.tableData[filterPropertyTable] === this.state.tableDropdownOption
        );
      });

    return (
      <div>
        <div
          className={
            template2
              ? ' report-container report-container-template2'
              : template3
                ? 'report-container  report-container-template3' : template4
                  ? ' report-container-template3'
                  : 'report-container'
          }
          style={
            template2
              ? {
                backgroundImage: gradientColor ? gradientColor : `linear-gradient(to right, ${reportBackgroundColor}, ${reportBackgroundColor})`,
                marginTop: 10,
                border: `5px`,
              }
              : {}
          }
        >
          <div
            className={
              template2
                ? 'd-flex justify-content-center mb-1 chart-heading-template2'
                : template3
                  ? 'dispLayNone' : template4
                    ? 'dispLayNone' :
                    'd-flex justify-content-between mb-1 chart-heading'
            }
          >
            <div className='chart-heading-wrapper'>
              <p className={template2 ? 'dispLayNone' : 'text-capitalize mb-0'}>
                {reportTitle
                  ? reportTitle
                  : placeholder.show
                    ? placeholder.text
                    : report.name}
              </p>
            </div>
            <Row
              className='d-flex justify-content-center '
              style={template2 ? {} : { backgroundColor: '#fff' }}
            >
              <div className='buttonGropImageCardFilter'>
                {filterDropdown &&
                  filterProperty &&
                  imageFilterilterShowAs !== 'dropdown' &&
                  imageCardFilterOptions &&
                  filterRepeatedImageCardOptions.length < 4 && (
                    <Button
                      onClick={() => this.onChangeAudit(null)}
                      className={
                        this.state.imageDropdownOption === null
                          ? 'imageCard_ButtonSelected '
                          : 'imageCard_ButtonNotSelected'
                      }
                    >
                      All
                    </Button>
                  )}

                {filterDropdown &&
                  filterProperty &&
                  imageFilterilterShowAs !== 'dropdown' &&
                  filterRepeatedImageCardOptions.length < 4 &&
                  filterRepeatedImageCardOptions &&
                  filterRepeatedImageCardOptions.map((item, index) => {
                    return (
                      <Button
                        key={index}
                        onClick={() => this.onChangeAudit(item.option)}
                        className={
                          this.state.imageDropdownOption === item.option
                            ? 'imageCard_ButtonSelected '
                            : 'imageCard_ButtonNotSelected'
                        }
                      >
                        {filterProperty} {item.option}
                      </Button>
                    );
                  })}
                {((filterDropdown &&
                  filterProperty &&
                  imageFilterilterShowAs === 'dropdown') ||
                  filterRepeatedImageCardOptions.length > 4) && (
                    <UncontrolledDropdown
                      style={{ marginLeft: 2, marginRight: 2 }}
                      size='sm'
                      className='reportFilterDropdown imageFilterDropdown'
                    >
                      <DropdownToggle>{filterProperty}</DropdownToggle>
                      <DropdownMenu>
                        {filterRepeatedImageCardOptions &&
                          filterRepeatedImageCardOptions.map((item, index) => {
                            return (
                              <DropdownItem
                                onClick={() => this.onChangeAudit(item.option)}
                                key={index}
                              >
                                {item.option}
                              </DropdownItem>
                            );
                          })}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  )}
              </div>
            </Row>
            <Row
              className='d-flex justify-content-end reportFiltersContainer'
              style={template2 ? {} : { backgroundColor: '#fff' }}
            >
              {!showDropdownFilter && (
                <Col>
                  {dailyReport && (
                    <Button
                      onClick={this.onDailyReportClicked.bind(this)}
                      size='sm'
                      className='btn-link dailyReport'
                      style={{
                        background: 'none',
                        border: 'none',
                        color: `${this.state.dailyReport ? 'blue' : '#000'}`,
                      }}
                    >
                      Today
                    </Button>
                  )}
                  {weeklyReport && (
                    <Button
                      onClick={this.onWeeklyReportClicked.bind(this)}
                      size='sm'
                      className='btn-link weeklyReport'
                      style={{
                        background: 'none',
                        border: 'none',
                        color: `${this.state.weeklyReport ? 'blue' : '#000'}`,
                      }}
                    >
                      Past 7 Days
                    </Button>
                  )}
                  {monthlyReport && (
                    <Button
                      onClick={this.onMonthlyReportClicked.bind(this)}
                      size='sm'
                      className='btn-link monthlyReport'
                      style={{
                        background: 'none',
                        border: 'none',
                        color: `${this.state.monthlyReport ? 'blue' : '#000'}`,
                      }}
                    >
                      Past 30 Days
                    </Button>
                  )}
                  {dateRange && (
                    <div className='d-flex' style={{marginTop: '-1px'}}>
                      <DatePicker
                        selected={startDate}
                        onChange={this.handleFromChange.bind(this)}
                        placeholderText='Select From Date...'
                        className='form-control w-100'
                        popperPlacement='auto-right'
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        dateFormat={dateFormat ? dateFormat : 'MM/DD/YYYY'}
                      />
                      <DatePicker
                        selected={endDate}
                        onChange={this.handleToChange.bind(this)}
                        placeholderText='Select To Date...'
                        className='form-control w-100'
                        popperPlacement='auto-right'
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        dateFormat={dateFormat ? dateFormat : 'MM/DD/YYYY'}
                      />
                    </div>
                  )}
                  {/* in case all is needed vg */}

                  {/* {dailyReport && monthlyReport && weeklyReport && (
                <Button
                  onClick={this.allReportClicked.bind(this)}
                  size='sm'
                  className='btn-link weeklyReport'
                  style={{
                    background: 'none',
                    border: 'none',
                    color: `${this.state.fullReport ? 'blue' : '#000'}`,
                  }}
                >
                  All
                </Button>
              )} */}
                  {filters.length > 0 &&
                    filters.map((filter, index) => {
                      if (typeof filter.active === 'boolean' && !filter.active)
                        return null;
                      return (
                        <ReportFilter
                          filterIndex={index}
                          filter={filter}
                          key={index}
                          onFilterChange={(paramName, value) => {
                            this.setState({ reportName: null }, () => {
                              this._onFilterChange(paramName, value);
                            });
                          }}
                          filterStateMap={filterStateMap}
                          {...this.props}
                        />
                      );
                    })}
                  {reportFilters.length > 0 &&
                    reportFilters.map((filter, index) => {
                      return (
                        <ReportFilter
                          filterIndex={filters.length + index}
                          filter={{
                            type: filter.displayType,
                            paramLabel: filter.modelParams.paramLabel,
                            paramName: filter.modelParams.paramName,
                            ...filter,
                          }}
                          key={filters.length + index}
                          onFilterChange={(paramName, value) => {
                            this.setState(
                              { reportName: filter.dataSourceQuery },
                              () => {
                                this._onFilterChange(paramName, value);
                              }
                            );
                          }}
                          filterStateMap={filterStateMap}
                          {...this.props}
                        />
                      );
                    })}
                </Col>
              )}
              {dailyReport ||
                weeklyReport ||
                monthlyReport ||
                dateRange ||
                filters.length > 0 ? (
                <div className='ml-1'>
                  {!showDropdownFilter && (
                    <div
                      className='d-flex justify-content-end'
                      data-html2canvas-ignore='true'
                    >
                      <Button
                        onClick={this.onFiltersApplied}
                        size='sm'
                        color='primary'
                      >
                        <i className='fa fa-check mr-1' aria-hidden='true'></i>
                      </Button>
                      <Button
                        onClick={this.onClearAppliedFilters}
                        size='sm'
                        color='link'
                      >
                        <i className='fa fa-undo mr-1' aria-hidden='true'></i>
                      </Button>
                    </div>
                  )}
                </div>
              ) : null}

              {filterDropdownTable &&
                filterPropertyTable &&
                tableFIlterType === 'dropdown' && (
                  <UncontrolledDropdown
                    size='sm'
                    style={{ marginLeft: 2, marginRight: 2 }}
                    className=''
                  >
                    <DropdownToggle>{filterPropertyTable}</DropdownToggle>
                    <DropdownMenu className={'tableDropdownButtonDiv'}>
                      {filterRepeatedOptions &&
                        filterRepeatedOptions.map((item, index) => {
                          return (
                            <DropdownItem
                              key={index}
                              onClick={() =>
                                this.onChangeTableFilter(item.option)
                              }
                            >
                              {item.option}
                            </DropdownItem>
                          );
                        })}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )}

              {showDropdownFilter && (
                <UncontrolledDropdown
                  style={{ marginLeft: 2, marginRight: 2 }}
                  size='sm'
                  className=''
                >
                  {showFilterAsDropdown ? <DropdownToggle className={'daysFilterDropDown'}>Days Filter    <i className='fa fa-angle-down ml-1 mr-1' style={{ marginLeft: 1 }} aria-hidden='true'></i></DropdownToggle>
                    : <DropdownToggle >Days Filter</DropdownToggle>
                  } <DropdownMenu>
                    <DropdownItem
                      onClick={this.allReportClicked.bind(this)}
                      active={this.state.fullReport}
                    >
                      Full Report
                    </DropdownItem>
                    <DropdownItem
                      onClick={this.past10DaysReportClicked.bind(this)}
                      active={this.state.past10Report}
                    >
                      Past 10 Days
                    </DropdownItem>
                    <DropdownItem
                      onClick={this.onMonthlyReportClicked.bind(this)}
                      active={this.state.past30Report}
                    >
                      Past 30 Days
                    </DropdownItem>
                    <DropdownItem
                      onClick={this.past60DaysReportClicked.bind(this)}
                      active={this.state.past60Report}
                    >
                      Past 60 Days
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
              {((filterDropdownTable &&
                filterPropertyTable &&
                tableFIlterType === 'dropdown' &&
                filterRepeatedOptions) ||
                showDropdownFilter) && (
                  <Button
                    size='sm '
                    color='outline'
                    className='ml-0'
                    onClick={this.resetFilterReport.bind(this)}
                  >
                    <i className='fa fa-refresh mr-1' aria-hidden='true'></i>
                  </Button>
                )}
            </Row>
            <div className='d-flex filter_report_n' >
              {!hideMenu && (
                <UncontrolledDropdown>
                  <DropdownToggle
                    className='text-muted'
                    color={
                      textColor && textColor.value ? textColor.value : 'link'
                    }
                    size='sm'
                  >
                    <i
                      className={`fa ${beforeIcon && beforeIcon.cssClass
                          ? beforeIcon.cssClass
                          : chart.reportType.icon
                        }`}
                      aria-hidden='true'
                    ></i>
                  </DropdownToggle>
                  <DropdownMenu right>
                    {this.getChartDisplayOptions()}
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
              <FileDownload
                data={data}
                hideInPDFDownload={hideInPDFDownload}
                type='report'
                duplicateRowsInOneToMany={duplicateRowsInOneToMany}
              />
            </div>
          </div>
          {Component ? (
            <Component
              graphData={data.graphData}
              imageCardData={filterdImageArray}
              tableFilterdData={filterdTableData}
              legendName={this.getLegendName(data)}
              colors={COLORS}
              xLabel={this.getXLabel(data)}
              yLabel={this.getYLabel(data)}
              graphHeight={
                elementHeight ? Number(elementHeight) : this.props.graphHeight
              }
              rawData={data}
              textColor={textColor}
              reportCTA={reportCTA}
              reportPrimaryColor={reportPrimaryColor}
              originalReportType={originalReportType}
              timelineconfig={timelineconfig}
              locationConfig={locationConfig}
              imageCardConfig={imageCardConfig}
              numberConfig={numberConfig}
              barChartConfig={barChartConfig}
              tableConfig={tableConfig}
              beforeIcon={beforeIcon}
              reportNameFinal={reportNameFinal}
            />
          ) : (
            <Alert color='warning'>
              <i
                className='fa fa-exclamation-triangle mr-1'
                aria-hidden='true'
              ></i>
              Report Type is not available.
            </Alert>
          )}
        </div>
        {Component && reportType.code == 'TABLE' && (
          <Pagination
            start={paginationStart}
            hasNextPage={
              data.graphData ? data.graphData.length === pageSize : false
            }
            size={pageSize}
            onChangePage={this.onChangePage}
            onChangePageSize={this.onChangePageSize}
            reportPaginationClass={true}
          />
        )}
      </div>
    );
  }
}

class ReportFilter extends React.Component {
  render() {
    const { filter, onFilterChange, filterStateMap, filterIndex } = this.props;
    if (!filter) return null;
    const { paramLabel, paramName, type } = filter;
    const filterName = paramLabel ? paramLabel : paramName;
    const elem_id = `filter-elem-${type}`;
    //console.log(type)
    let filterType = type == 'DATETIME' ? 'DATE' : type; // temporary
    const ResolvedFilterElem = FilterElements[filterType] || null;
    //console.log(ResolvedFilterElem)
    if (!ResolvedFilterElem || ResolvedFilterElem.name === 'FallbackForFilter')
      return null;
    //let savedValue = filterStateMap[`filter_${filterIndex}`];
    //savedValue = savedValue ? savedValue.filterBy : '';

    return (
      <Col xs='12' md='3' xl='3'>
        <FormGroup className='mb-0'>
          <ResolvedFilterElem
            elem_id={elem_id}
            filterName={filterName}
            {...this.props}
          />
        </FormGroup>
      </Col>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getReportDataByName: (payload, filterParam) => {
      return dispatch(getReportDataByName(payload, filterParam));
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    graphHeight: 400,
    companyDetails: state.getIn(['company', 'details']),
    uiFilters: state.getIn(['uiFilters']),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(Report));
