import React from 'react';
import { FormGroup, Input, Label, FormFeedback, Row, Col, Button } from 'reactstrap';
import RememberMeComp from '../../AuthHelpers/RememberMeComp';
import { Link } from 'react-router-dom';
import OTPScreen from './OTPScreen';
import client from '../../../../client';
import config from '../../../../config';
class Template1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      isChecked: false,
      isShowPassword:false,
      twoFAStatus: null
    }
  }

  updateTwoFAStatus = (t) => {
    this.setState({twoFAStatus: t})
  }

  onLogin = () => {
    const { email, password, isChecked } = this.state;
    const { authenticationCallback, itemProperties, sessionId, twoFA } = this.props;
    if (isChecked && email !== "") {
      localStorage.username = email
      localStorage.password = password
      localStorage.checkbox = isChecked
    }
    const payload = {
      email,
      password
    };

    if(twoFA) {
      this.props.setError(false)
      this.props.login(payload, () => {
        const otpPayload = {
          phoneNumber: email
        }
        // client().post(`${config.publicAPI}/generateotp`, otpPayload).then((res) => {
        // }).catch((error) => {
        //   this.updateTwoFAStatus(null)
        // });
        this.updateTwoFAStatus("OTP")
      });
    } else {
      if (itemProperties && itemProperties.authenticateUsingPlugins) {
        payload['authenticateUsingPlugins'] = true;
        payload['sessionId'] = sessionId;
      }
      this.props.setError(false)
      this.props.login(payload, authenticationCallback);
    }
    
    
  }
  togglePasswordVisiblity =()=> {
    this.setState({ isShowPassword: !this.state.isShowPassword });
}
  render() {
    const { label,hasForgotPassword,showForgotPasswordAboveLoginButton,authenticationCallback ,authTemplateTopFormText ,twoFA} = this.props;
    const { itemProperties } = this.props;
    const rememberMe =
      itemProperties &&
      itemProperties.authConfig &&
      itemProperties.authConfig.rememberMe
    const showPassword =
    itemProperties &&
    itemProperties.authConfig &&
    itemProperties.authConfig.showPassword
    return (
      <div className='authTemplate1' >
     { authTemplateTopFormText &&  this.state.twoFAStatus !== "OTP" &&  <div className='template2loginToContiue'><h5>  {authTemplateTopFormText ?authTemplateTopFormText:' LOGIN TO CONTINUE '}</h5></div>}
        <FormGroup>
        {
                    this.state.twoFAStatus === "OTP" ? ( <OTPScreen authenticationCallback={authenticationCallback} requiredRole={itemProperties.requiredRole} phoneNumber={this.state.email}/>):(
                      <div>
          <Label for={'email'}>Email</Label>
          <Input
            value={this.state.email}
            onChange={(event) => this.setState({ email: event.target.value })}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                event.stopPropagation();
                this.onLogin();
              }
            }}
            placeholder={'Enter Email'}
            type={'text'}
          />
          </div>)}
        </FormGroup>
        {
          this.state.twoFAStatus === "OTP" ? <div></div> :  (   <FormGroup>
          <Label for={'password'}>Password</Label>
          <Input
            value={this.state.password}
            onChange={(event) => this.setState({ password: event.target.value })}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                event.stopPropagation();
                this.onLogin();
              }
            }}
            placeholder={'Enter password'}
            type={this.state.isShowPassword?'text':'password'}
          />
        </FormGroup>)}
        <Row>
       { showPassword &&  <Col>
          <div style={{padding:2}}>
            <input type="checkbox" checked={this.state.isShowPassword} className="showPassword-checkbox" style={{marginRight:12}} name="lsRememberMe" onChange={this.togglePasswordVisiblity} />
            <label className="showPassword-label" >Show Password</label>
          </div>
          </Col>}
          <Col>
         {rememberMe ? (
          <div className="remember-me-login">
            <RememberMeComp isCheckedProp={this.state.isChecked} />
          </div>
        ) : null   }
        </Col>
        </Row>
        {this.state.twoFAStatus === "OTP"?<div></div>:(
           ( hasForgotPassword && showForgotPasswordAboveLoginButton || twoFA )? (
              <div className='emailAuthFOrgotPassword'>
                <Link to='/app/forgotpassword'>Forgot password?</Link>
              </div>
            ) : null
        )}
           <div style={{marginBottom: 10}}>
            {this.props.reCaptchaComponent}
          </div>
        <div className="d-flex justify-content-end">
          {
          this.state.twoFAStatus === "OTP" ? <div></div> : (
          <Button
            disabled={this.props.displayReCaptcha}
            size="sm"
            className="pr-2 pl-2"
            color="primary"
            onClick={this.onLogin}
          >
            <i className="fa fa-lock pr-2" aria-hidden="true"></i>
            {
              label.show ? label.text : 'Login'
            }
          </Button>)}
        </div>
      </div>
    )
  }
}
export default Template1;