import React, { useEffect, useState } from 'react';
import PrintText from './PrintText';
import { Field } from 'redux-form/immutable';
import { FormGroup, Input, Label } from 'reactstrap';
import { toJS } from '../../containers/to-js';
import { connect } from 'react-redux';
import { ModelPropertiesParser } from '../commons/modelPropertiesParser';


const CalculatedNumber = (props) => {
    const [calculatedValue, setCalculatedValue] = useState(0);
    const { classProperty, calculateFrom, type, validations, value, isReadOne,layoutItem ,...rest } = props;
    useEffect(() => {

        if(!isReadOne) {
            const formula = layoutItem.field.properties.formula;

            try {
                let val = ModelPropertiesParser(formula, calculateFrom);
                val = eval(val);
                console.log(val);
                if(val !== calculatedValue) {
                    setCalculatedValue(val);
                    props.change(classProperty, val);
                }
                console.log(calculateFrom);
            } catch(e) {
                // console.log(e);
            }

        }

    }, [calculateFrom])

    return (
        <div>
            {
                isReadOne ?
                    <PrintText {...props} /> :
                    <Field
                        name={classProperty}
                        component={(props) => {
                            return (
                                <FormGroup>
                                    {
                                        props.label.show ? (<Label for={props.input.name}></Label>) : ""
                                    }
                                    <Input
                                        disabled={true}
                                        placeholder={props.placeholder.show && props.placeholder.text}
                                        type={"text"}
                                        {...props.input}
                                    />
                                </FormGroup>
                            )
                        }}
                        type="text"
                        disabled={true}
                        validate={validations}
                        value={calculatedValue}
                        fieldType={type}
                        {...rest}
                    />
            }
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {}
  };
  
  const mapStateToProps = (state, ownProps) => {
    const classProperty = ownProps.classProperty;
    const propE = new RegExp(/\[(.*?)\]/g);
    let path = classProperty;
    let index = undefined;
    const updatedClassProperties = classProperty.replace(propE, (pattern, matched) => {
        index = matched;
        path = path.split(pattern)[0];
        return "";
    })
    var calculateFrom = null;
    try {
        calculateFrom = state.getIn(['form', 'create-update-form-base', 'values', path, index]); 
    } catch(error) {
        console.log(error);
    }
    return {calculateFrom: calculateFrom?calculateFrom.toJS():null};
  };

export default connect(mapStateToProps, mapDispatchToProps)(toJS(CalculatedNumber));