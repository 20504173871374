import React, { useEffect, useState } from 'react';
import Autocomplete from 'react-autocomplete';
import { Label } from 'reactstrap';
import { Field } from 'redux-form';
import { ModelPropertiesParser } from '../commons/modelPropertiesParser';

const CustomTypeModelCreateUpdate = props => {
  const { classProperty, value, ...rest } = props;
  return (
    <Field
      name={classProperty}
      classProperty={classProperty}
      component={DropDown}
      {...rest}
    />
  );
};

const DropDown = ({
  modifier,
  initialValues,
  input,
  change,
  label,
  classProperty,
  getfindbyfieldname,
  layoutItem: {
    itemProperties,
    itemProperties: {
      field: { customModelName },
    },
  },
  workflow: {
    currentItem: {
      typeModel: { customerName, customerNameWithVersion, safeName },
    },
  },
  callUIAction
}) => {
  let { modelSelector, textFilterField, oneToOneModel, fieldToSave } =
    modifier === 'SMALLTEXT' ? itemProperties.smallTextConfig : itemProperties;
  if (!modelSelector) modelSelector = textFilterField;
  const [allValues, setAllValues] = useState([]);
  const [value, setValue] = useState('');
  const [selectedValue, setSelectedValue] = initialValues
    ? useState(initialValues[input.name])
    : useState(null);
  let fieldName = textFilterField.replace('{{', '');
  fieldName = fieldName.replace('}}', '');
  useEffect(() => {
    if (value.length > 2) {
      const typeModelName =
        modifier === 'SMALLTEXT' ? oneToOneModel.name : customModelName;
      const payload = {
        paramOne: value,
        customerName,
        fieldName,
        typeModelName: typeModelName.replaceAll(' ', '_'),
        customerNameWithVersion,
      };
      getfindbyfieldname(payload)
        .then(res => {
          setAllValues(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [value]);

  useEffect(() => {
    if (selectedValue) {
      if (modifier === 'SMALLTEXT' && typeof selectedValue != 'string') {
        change(
          classProperty,
          ModelPropertiesParser(`{{${fieldToSave}}}`, selectedValue)
        );
      } else {
        modifier === 'SMALLTEXT' && setValue(selectedValue)
        change(classProperty, selectedValue);
      }
      setTimeout(() => {
        callUIAction(true)
      },500)
      
    }
  }, [selectedValue]);
  return (
    <div className='mb-2'>
      <div>
        {label.show && (
          <Label className='text-capitalize mb-1'>{label.text}</Label>
        )}
      </div>
      <div>
        <Autocomplete
          items={allValues}
          getItemValue={item => ModelPropertiesParser(modelSelector, item)}
          renderItem={(item, isHighlighted) => (
            <div
              key={ModelPropertiesParser(modelSelector, item)}
              className={`autocomplete-list-item ${
                isHighlighted ? 'is-highlighted' : ''
              }`}
            >
              {ModelPropertiesParser(modelSelector, item)}
            </div>
          )}
          value={
            selectedValue
              ? typeof selectedValue != 'string'
                ? ModelPropertiesParser(modelSelector, selectedValue)
                : value
              : value
          }
          onChange={(e, value) => {
            setSelectedValue(null);
            setValue(e.target.value);
            
          }}
          onSelect={(value, item) => setSelectedValue(item)}
          renderInput={props => (
            <input
              className='form-control'
              placeholder={`Type ${classProperty} name`}
              {...props}
            />
          )}
          renderMenu={(items, value, style) => {
            return (
              <div
                className='autocomplete-menu-wrapper'
                style={{ ...style }}
                children={items}
              />
            );
          }}
          wrapperStyle={{
            width: '100%',
          }}
        />
      </div>
    </div>
  );
};

export default CustomTypeModelCreateUpdate;
