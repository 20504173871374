import React from 'react';
import { FormGroup, Input, Label, FormFeedback, Row, Col, Button } from 'reactstrap';
import RememberMeComp from '../../AuthHelpers/RememberMeComp';
import { Link } from 'react-router-dom';
import client from '../../../../client';
import config from '../../../../config';
import OTPScreen from './OTPScreen';

class Template3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      isChecked: false,
      isShowPassword:false,
      twoFAStatus: null
    }
  }

  updateTwoFAStatus = (t) => {
    this.setState({twoFAStatus: t})
  }
  onLogin = () => {
    const { email, password, isChecked } = this.state;
    const { authenticationCallback, itemProperties, sessionId, twoFA } = this.props;
    if (isChecked && email !== "") {
      localStorage.username = email
      localStorage.password = password
      localStorage.checkbox = isChecked
    }
    const payload = {
      email,
      password
    };

    if(twoFA) {
      this.props.setError(false)
      this.props.login(payload, () => {
        const otpPayload = {
          phoneNumber: email
        }
        // client().post(`${config.publicAPI}/generateotp`, otpPayload).then((res) => {
        // }).catch((error) => {
        //   this.updateTwoFAStatus(null)
        // });
        this.updateTwoFAStatus("OTP")
      });
    } else {
      if (itemProperties && itemProperties.authenticateUsingPlugins) {
        payload['authenticateUsingPlugins'] = true;
        payload['sessionId'] = sessionId;
      }
      this.props.setError(false)
      this.props.login(payload, authenticationCallback);
    }
    
    
  }

  togglePasswordVisiblity =()=> {
    this.setState({ isShowPassword: !this.state.isShowPassword });
}
  render() {
    const { label,hasForgotPassword,authTemplate3SideImageUrlComplete,authTemplateTopFormText,companySettings,logoUrlComplete,authTemplateSideImageUrlComplete,authTemplateBackgroundColor,authTemplateFontColor } = this.props;
    const { itemProperties, authenticationCallback } = this.props;
    const rememberMe =
      itemProperties &&
      itemProperties.authConfig &&
      itemProperties.authConfig.rememberMe
    const showPassword =
    itemProperties &&
    itemProperties.authConfig &&
    itemProperties.authConfig.showPassword

    return (
      <div className='authTemplate3'  style={{backgroundColor:authTemplateBackgroundColor? authTemplateBackgroundColor:'#fff',backgroundImage:authTemplateBackgroundColor? authTemplateBackgroundColor:'#fff',color:authTemplateFontColor?authTemplateFontColor:"#000"}}>
          <div className='template3Main'>
                <div className='rightLoginComponentTemplate3'>
                    {true && <div className='template3loginToContiue'><h3 style={{fontWeight:'bold'}} > {authTemplateTopFormText ?authTemplateTopFormText:'LOGIN'} </h3 ></div>}
                <FormGroup className={'formTemplate3'} >
                  {
                    this.state.twoFAStatus === "OTP" ? (
                      <OTPScreen authenticationCallback={authenticationCallback} requiredRole={itemProperties.requiredRole} phoneNumber={this.state.email}/>
                    ) : (
                      <div>
                      <Label for={'email'}>Email</Label>
                      <Input
                        value={this.state.email}
                        onChange={(event) => this.setState({ email: event.target.value })}
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                            event.stopPropagation();
                            this.onLogin();
                          }
                        }}
                        placeholder={'Enter Email'}
                        type={'text'}
                      />
                    </div>
                    )
                  }
                  
        </FormGroup>
        {
          this.state.twoFAStatus === "OTP" ? <div></div> : (
            <FormGroup className={'formTemplate3'}>
                <div className='passwordTemplate3'>
              <Label for={'password'}>Password</Label>
            { hasForgotPassword ?  <Link className='forgotPasswordTemplate3' to='/app/forgotpassword'>Forgot password?</Link>:<div></div>}
                </div>
              <Input
                value={this.state.password}
                onChange={(event) => this.setState({ password: event.target.value })}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    event.stopPropagation();
                    this.onLogin();
                  }
                }}
                placeholder={'Enter password'}
                type={this.state.isShowPassword?'text':'password'}
              />
            </FormGroup>
          )
        }
        
         {showPassword &&   <div className='showPasswordTemplate3'>
            <input type="checkbox" checked={this.state.isShowPassword} className="showPassword-checkbox" style={{marginRight:12}} name="lsRememberMe" onChange={this.togglePasswordVisiblity} />
            <label className="showPassword-label" >Show Password</label>
          </div>}
        {
          this.state.twoFAStatus === "OTP" ? <div></div> : (
            <div className="template3LoginButton">
              <div style={{marginBottom: 10}}>
                {this.props.reCaptchaComponent}
              </div>
              <Button
                disabled={this.props.displayReCaptcha}
                size="sm"
                className="pr-2 pl-2"
                color="primary"
                onClick={this.onLogin}
              >
                <i className="fa fa-lock pr-2" aria-hidden="true"></i>
                {
                  label.show ? label.text : 'Login'
                }
              </Button>
            </div>
          )
        }
        
                </div>
            </div>
        {authTemplateSideImageUrlComplete && <div className='authTemplate3Logo'>
            <img src={authTemplateSideImageUrlComplete}  alt="Image" />
            </div>}
        <Row>
        </Row>
      </div>
    )
  }
}
export default Template3;