import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Container, Row, Col } from 'reactstrap';
import { toJS } from '../../containers/to-js';
import { getTranslate } from 'react-localize-redux';
import {
  startWorkflow,
  getWorkflowFromCode,
  toggleRefreshBaseWorkflow,
  clearWorkflowState,
  endWorkflow,
  fetchNavWorkflows,
  resetSortAndPagination,
} from '../../actions/workflows';
import { next, reset, clearNextState } from '../../actions/workflow-item';
import { clearBreadcrumbs } from '../../actions/breadcrumbs';
import PropTypes from 'prop-types';
import AppBreadcrumbs from '../commons/AppBreadcrumbs';
import ReduxWrapperHoc from './ReduxWrapperHoc';
import { reduxFormKeys } from '../../dto/workflow';
import { toast } from 'react-toastify';
import { messages } from '../../dto/user-messages';
import { Redirect } from 'react-router-dom';
import TypeModelBulletedList from '../elements/TypeModelHelpers/TypeModelBulletedList';
import { fromJS } from 'immutable';
import { updateValues } from '../../utils/uiActions';

async function resolveIdFromCode(code, method) {
  if (code) {
    return await method(code);
  }
  return null;
}

class BaseWorkflow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      endOfWorkflow: false,
      disableSaveAndNext: false,
    };
  }

  toggleSaveandNextState = value => {
    this.setState({ disableSaveAndNext: value });
  };

  componentDidMount() {
    //window.scrollTo(0, 0);
    this.scrollTop();
    this.loadOrRefreshWorkflow(this.props);
    this.successToastId = null;
    this.errorToastId = null;
  }
  loadOrRefreshWorkflow(props, forceRefresh) {
    const { isPublic, isDefaultWorkflow, companyDetails, user } = props;
    const logoutUrl = companyDetails && companyDetails.data && companyDetails.data.logoutURL;
    const {
      router: {
        route: {
          match: {
            params: { _URL },
          },
        },
      },
    } = this.context;
    let {
      router: {
        route: {
          location: { search },
        },
        history,
      },
    } = this.context;
    let tId = null;
    if (search) {
      search = search.substring(1);
      const params = search.split('&');
      for (let i = 0; i < params.length; i++) {
        var tokens = params[i].split('=');
        if (tokens[0] === 'tid') {
          tId = tokens[1];
        }
      }
    }
    const workflowIdPromise = this.getWorkflowId(_URL);
    if (!isPublic && !isDefaultWorkflow) {
      if (!workflowIdPromise) return;
      workflowIdPromise.then(res => {
        if (res) {
          const id = res && res.data.data;
          res &&
            this.props.start(
              {
                id,
                isBase: true,
                displayMode: 'DEFAULT',
                tid: tId,
                forceRefresh,
              },
              '/workflow/start'
            );
        } else {
          if(!user)
            logoutUrl ? history.push('/') : history.push('/app/login');
        }
      });
    } else {
      if (!isPublic && isDefaultWorkflow) {
        this.props.start(
          { isBase: true, displayMode: 'DEFAULT', forceRefresh },
          '/workflow/loaddefault'
        );
        history.push('/app');
      }
    }
  }
  scrollTop() {
    const sidebarContent =
      document.getElementsByClassName('sidebar-content')[0];
    if (sidebarContent) {
      sidebarContent.scrollTop = 0;
    }
  }
  getCodeFromURL(_URL) {
    const { navigation } = this.props;
    let code = null;
    if (!navigation) return null;
    for (let i = 0; i < navigation.length; i += 1) {
      const item = navigation[i];
      if (item.friendlyUrl && item.friendlyUrl.toLowerCase() === _URL) {
        code = item.workflowCode;
        break;
      }
    }
    return code;
  }
  async getId(_URL) {
    const workflowCode = this.getCodeFromURL(_URL);
    return resolveIdFromCode(workflowCode, this.props.getWorkflowFromCode);
  }
  getWorkflowId(_URL) {
    return this.getId(_URL);
  }
  componentWillReceiveProps(nextProps) {
    if (!this.props.isDefaultWorkflow && nextProps.isDefaultWorkflow) {
      this.loadOrRefreshWorkflow(nextProps);
    }
    const { nextState, clearNextState, baseWorkflow } = nextProps;
    if (this.props && nextProps && nextProps.match) {
      const nextURL = nextProps.match.params._URL;
      if (
        (this.props.match && this.props.match.params._URL) !== nextURL ||
        nextProps.refreshBaseWorkflow
      ) {
        const id_promise = this.getWorkflowId(nextURL);
        if (id_promise) {
          id_promise.then(res => {
            this.props.start(
              { id: res.data.data, isBase: true, displayMode: 'DEFAULT' },
              '/workflow/start'
            );
            this.scrollTop();
          });
        }
        this.props.toggleRefreshBaseWorkflow(false);
        this.props.clearBreadcrumbs();
      }
    }
    if (nextState && Object.keys(nextState).length && !nextState.isLoading) {
      if (
        !nextState.hasError &&
        !nextState.pristine &&
        !toast.isActive(this.successToastId)
      ) {
        this.successToastId = toast.info(messages.defaultNextSaved);
        clearNextState();
      } else if (nextState.hasError && !toast.isActive(this.errorToastId)) {
        // this.errorToastId = toast.error(messages.defaultErrorMessage);
        clearNextState();
      }
    }
    if (this.props.baseWorkflow && baseWorkflow && baseWorkflow.active) {
      if (
        this.props.baseWorkflow.sessionId !== baseWorkflow.sessionId ||
        this.props.baseWorkflow.currentItem.id !== baseWorkflow.currentItem.id
      ) {
        this.scrollTop();
      }
    } else if (baseWorkflow && !baseWorkflow.active) {
      this.setState({ endOfWorkflow: true });
    }
  }
  submitForm(values, pristine) {
    const { onSubmit, baseWorkflow, reset } = this.props;
    if (
      baseWorkflow &&
      baseWorkflow.currentItem &&
      baseWorkflow.currentItem.layoutProperties.uiActions &&
      baseWorkflow.currentItem.layoutProperties.uiActions.length
    ) {
      const actions = baseWorkflow.currentItem.layoutProperties.uiActions;
      const updatedValues = updateValues(actions, values);
      onSubmit(baseWorkflow, updatedValues, pristine);
    } else {
      onSubmit(baseWorkflow, values, pristine);
    }
  }

  jumptoSubmit = (values, pristine) => {
    const { onSubmit, baseWorkflow, reset } = this.props;
    onSubmit(baseWorkflow, fromJS(values), pristine);
  };

  refreshWF = () => {
    this.loadOrRefreshWorkflow(this.props, true);
  };
  render() {
    const {
      user,
      baseWorkflow,
      nextState,
      endWorkflow,
      clearNextState,
      companyDetails,
      isPublic,
      ...rest
    } = this.props;
    const workflowData = this.props.workflowData;
    const loaderUrl = this.props.companyDetails && this.props.companyDetails.data && this.props.companyDetails.data.loaderURL ? this.props.companyDetails.data.loaderURL : null ;
    const disableRightClick = this.props.companyDetails && this.props.companyDetails.data && this.props.companyDetails.data.companySettings && this.props.companyDetails.data.companySettings.disableRightClick ? this.props.companyDetails.data.companySettings.disableRightClick  : null ;
    const logoutUrl = this.props.companyDetails && this.props.companyDetails.data && this.props.companyDetails.data.logoutURL;
    const hideMenu = companyDetails && companyDetails.data && companyDetails.data.hideMenuOnLanding
    if (user && user.isAnonymous && baseWorkflow && !baseWorkflow.isPublic) {
      // if(logoutUrl){
      //   return <Redirect to='/' />;
      // }
      return <Redirect to='/app/login' />;
    }
    if (this.state.endOfWorkflow) {
      if (
        user &&
        user.user &&
        user.user.email &&
        window.location.pathname === '/app'
      ) {
        window.location.reload(false);
      } else {
        return user && user.user && user.user.email ? (
          <Redirect to='/app' />
        ) : window.location.pathname === '/' ? (
          window.location.reload(false)
        ) : (
          <Redirect to='/' />
        );
      }
    }

    if (!window.companyDetailsDetailsFetched) {
      return null;
    }

    const isTurboTradeCompany =   window && window.location && window.location.hostname.includes('turbotradeplus') ||   window && window.location && window.location.hostname.includes('bemycredapp')

    
    if (
      !(
        this.props.companyDetails &&
        this.props.companyDetails.data &&
        this.props.companyDetails.data.loginWorkflowCode
      )
    ) {
      return (
        <>
      { !isTurboTradeCompany ? <div className='h-100 serverDown' style={{ margin: 70 }}>
          <span style={{ color: 'red', fontSize: 80 }}> :-( </span>
          <h1 style={{ color: 'red' }}> Oops! Something went wrong! </h1>
          <h3> We are trying to reach the servers </h3>
          <h6>Please try after sometime</h6>
        </div>:   <div className='h-100 maintainancePage'>
       <section className="page_404">
        <div className="container">
            <div className="row">
                <div className="col-sm-12 col_page404">
                    <div className="col-sm-10 col-sm-offset-1  text-center">
                        <p className="text-center header">We are under maintenance !</p>
                        <div className="four_zero_four_bg">
                        </div>

                        <div className="contant_box_404">
                            <h3>Please take a short break and come back !</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
        </div>}
        </>
      
      );
    }


    if (!baseWorkflow)
      return (
        <div>
          {!loaderUrl ? (
            <div className='loader-container'>
              <span className='loader_3'></span>
            </div>
          ) : (
            <div className='preloader d-flex align-items-center justify-content-center flex-column'>
              <img width='400px' height='238px' src={loaderUrl}></img>
            </div>
          )}
        </div>
      );
    const workflowError = baseWorkflow.workflowError;
    if (workflowError && workflowError.hasError)
      return (
        <Container>
          <Row>
            <Col>
              <Alert style={{ marginTop: 100, marginLeft: 50 }} color='danger'>
                {workflowError.message}
              </Alert>
            </Col>
          </Row>
        </Container>
      );
    return (
      <div className={`h-100 w_${baseWorkflow.workflowModel} uniqueCode_${baseWorkflow.workflowCode} ${!isPublic ? 'isPrivate':''}  ${isPublic ? 'isPublic':''} ${hideMenu ? 'hideMenu':''}` } 
      style={hideMenu && isPublic ?{marginTop:-62}:{}}
      onContextMenu={(e) =>{ 
              disableRightClick &&  e.preventDefault() ;}}
      >
        {/* <AppBreadcrumbs /> */}
        <ReduxWrapperHoc
          jumptoSubmit={this.jumptoSubmit}
          onSubmit={this.submitForm.bind(this)}
          initialValues={workflowData || {}}
          workflowData={workflowData}
          form={reduxFormKeys.base}
          toggleSaveandNextState={this.toggleSaveandNextState}
          disableSaveAndNext={this.state.disableSaveAndNext}
          updatedVersion={
            workflowData && workflowData.hasOwnProperty('version')
              ? workflowData.version
              : null
          }
          className={
            `baseworkflow-form h-100 w_${baseWorkflow.workflowModel}  uniqueCode_${baseWorkflow.workflowCode}` +
            (user && user.user && user.user.email
              ? ' workflow-custom-class'
              : '')
          }
          isBase={true}
          workflowForContainer={baseWorkflow}
          onEndWorkflow={endWorkflow}
          refreshWF={this.refreshWF}
        />
      </div>
    );
  }
  componentWillUnmount() {
    this.props.clearWorkflowState();
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { baseWorkflow, user } = ownProps;
  return {
    start: (payload, endPoint) => {
      dispatch(startWorkflow(payload, endPoint));
      dispatch(resetSortAndPagination());
    },
    onSubmit: (baseWorkflow, values, pristine) => {
      dispatch(next(baseWorkflow, values, null, pristine));
      dispatch(reset());
    },
    getWorkflowFromCode: code => {
      return dispatch(getWorkflowFromCode(code));
    },
    toggleRefreshBaseWorkflow: flag => {
      dispatch(toggleRefreshBaseWorkflow(flag));
    },
    clearBreadcrumbs: () => {
      dispatch(clearBreadcrumbs());
    },
    clearNextState: () => {
      dispatch(clearNextState());
    },
    clearWorkflowState: () => {
      dispatch(clearWorkflowState());
    },
    endWorkflow: payload => {
      dispatch(endWorkflow(payload));
    },
    fetchNavWorkflows: () => {
      dispatch(fetchNavWorkflows(user));
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  const baseWorkflow = state.getIn(['workflow', 'baseWorkflow']);
  const workflowData = baseWorkflow ? baseWorkflow.get('workflowData') : {};
  const refreshBaseWorkflow = state.getIn(['workflow', 'refreshBaseWorkflow']);
  const companyDetails = state.getIn(['company', 'details']);
  return {
    translate: getTranslate(state.get('locale')),
    baseWorkflow,
    workflowData,
    companyDetails,
    saveResponse: state.getIn(['save', 'data']),
    refreshBaseWorkflow,
    nextState: state.get('next'),
    workflowError: state.getIn(['workflow', 'error']),
    user: state.get('user'),
  };
};

BaseWorkflow.defaultProps = {
  isPublic: false,
  isDefaultWorkflow: false,
  workflowId: null,
};

BaseWorkflow.propTypes = {
  isPublic: PropTypes.bool,
  isDefaultWorkflow: PropTypes.bool,
  workflowId: PropTypes.number,
};

BaseWorkflow.contextTypes = {
  router: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(BaseWorkflow));
