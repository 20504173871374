import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from '../Table/Tablev1';
import { Label, Button, ButtonGroup, Input } from 'reactstrap';
import ActionContainer from '../../../containers/workflow/ActionContainer';
import DataListStore from '../../workflow/operations/filters/DataListStore';
import DataListWrapper from '../../workflow/operations/filters/DataListWrapper';
import FilterWrapperHOC from '../../workflow/operations/filters/FilterWrapperHOC';
import NoDataAlertBox from '../../commons/NoDataAlertBox';

const findActionByCode = (actions, code) => {
    let matchedAction = null;
    for (let i = 0; i < actions.length; i += 1) {
        if (actions[i].actionProperties.generatedActionCode && actions[i].actionProperties.generatedActionCode === code) {
            matchedAction = actions[i]
        }
    }
    return matchedAction;
}

class TypeModelTable extends Component {
    constructor(props) {
        super(props);
        this._dataList = new DataListStore(props.rows);
        this.state = {
            filteredDataList: this._dataList,
            sorting: {
                classProperty: null,
                order: 'ASCENDING'
            },
            searching: false,
            pagination: {
                pageSize: 10,
                currentPage: 1
            }
        }
        this._onFilterChange = this._onFilterChange.bind(this);
    }
    componentDidMount() {
        const { pagination: { pageSize, currentPage } } = this.state;
        const filteredDataList = new DataListStore(this.props.rows);
        this._dataList = new DataListStore(filteredDataList.getPage(pageSize, (currentPage - 1) * pageSize + 1));
        this.setState({ filteredDataList: this._dataList });
    }
    componentWillReceiveProps(nextProps) {
        const { rows } = nextProps;
        const { pagination: { pageSize, currentPage } } = this.state;
        if (rows && Array.isArray(rows) && this.state.filteredDataList.length !== rows.length && !this.state.searching) {
            const filteredDataList = new DataListStore(nextProps.rows);
            this._dataList = new DataListStore(filteredDataList.getPage(pageSize, (currentPage - 1) * pageSize + 1));

            this.setState({ filteredDataList: this._dataList });
        }
    }
    _onFilterChange(field, filterBy) {
        if (!filterBy) {
            this.setState({
                filteredDataList: this._dataList,
            });
        }

        var size = this._dataList.getSize();
        var filteredIndexes = [];
        for (var index = 0; index < size; index++) {
            const _val = this._dataList.getObjectAt(index)[field.classProperty];
            if (_val && _val.toLowerCase().indexOf(filterBy.toLowerCase()) !== -1) {
                filteredIndexes.push(index);
            }
        }

        this.setState({
            filteredDataList: new DataListWrapper(filteredIndexes, this._dataList),
        });
    }
    sortByClassProperty = (classProperty) => {
        const { filteredDataList, sorting } = this.state;
        let order = sorting.order;
        if (sorting.classProperty === classProperty) {
            if (order === 'ASCENDING') {
                this._dataList = new DataListStore(filteredDataList.sortByClassProperty(classProperty, order));
                order = 'DESCENDING';
            } else {
                this._dataList = new DataListStore(filteredDataList.sortByClassProperty(classProperty, order));
                order = 'ASCENDING';
            }
        } else {
            this._dataList = new DataListStore(filteredDataList.sortByClassProperty(classProperty, 'ASCENDING'));
            order = 'DESCENDING';
        }
        this.setState({
            filteredDataList: this._dataList,
            sorting: {
                classProperty,
                order
            }
        });
    }

    searchByKeyword = (event) => {
        const keyword = event.target.value;
        if (keyword.length > 1) {
            const filteredDataList = new DataListStore(this.props.rows);
            this._dataList = new DataListStore(filteredDataList.search(keyword, this.props.columns));
            this.setState({ filteredDataList: this._dataList, searching: true });
        } else {
            this._dataList = new DataListStore(this.props.rows);
            this.setState({ filteredDataList: this._dataList, searching: false });
        }
    }

    getCompatibleInlineActions(actions, itemProperties) {
        const { allowDeletion, generatedActionCodeForAllowEditing, generatedActionCodeForAllowDeletion } = itemProperties;
        let actionList = [];
        if (allowDeletion) {
            const deletionAction = findActionByCode(actions, generatedActionCodeForAllowDeletion);
            if (deletionAction) actionList.push(deletionAction);
        }
        const editingAction = findActionByCode(actions, generatedActionCodeForAllowEditing);
        if (editingAction) actionList.push(editingAction);
        return actionList;
    }
    onChangePageSize = (selectedPageSize) => {
        const { pagination: { currentPage, pageSize } } = this.state;
        const pagination = {
            currentPage: 1,
            pageSize: selectedPageSize
        }
        const filteredDataList = new DataListStore(this.props.rows);
        this._dataList = new DataListStore(filteredDataList.getPage(selectedPageSize, (currentPage - 1) * selectedPageSize + 1));
        this.setState({ pagination, filteredDataList: this._dataList });
    }
    onPageChange = (pageNumber) => {
        const { pagination: { currentPage, pageSize } } = this.state;
        const pagination = {
            currentPage: pageNumber,
            pageSize
        }
        const filteredDataList = new DataListStore(this.props.rows);
        this._dataList = new DataListStore(filteredDataList.getPage(pageSize, (pageNumber - 1) * pageSize + 1));
        this.setState({ pagination, filteredDataList: this._dataList });
    }
    componentWillUnmount() {
        this._dataList = new DataListStore([]);
        this.setState({ filteredDataList: this._dataList });
    }

    render() {
        const { workflow, rows, columns, label, viewMode, typeModelViewMode, onChangeTypeModelViewMode, workflow: { currentItem: { typeModel: { name } } }, layoutItem: { itemProperties }, noDataText, noDataTextHeading } = this.props;
        const { filteredDataList, sorting, pagination } = this.state;
        const { currentPage, pageSize } = pagination;
        const start = (currentPage - 1) * pageSize + 1;
        let end = pageSize + start - 1;
        if (end > rows.length) {
            end = rows.length;
        }
        const numberOfPages = Math.ceil(rows.length / pageSize);
        console.log("rows", rows);
        return (
            <div className="type-model-table">
                <div className='d-flex justify-content-between mb-2 border-botoom'>
                    <div>
                        <Label className='text-muted text-capitalize mb-0 labelTopTable' style={{ marginLeft: 10 }}>{label.text}</Label>
                    </div>
                    <div>
                        {
                            itemProperties && itemProperties.allowAdditionalTypeModelViewMode && <ButtonGroup className="mr-4">
                                <Button onClick={onChangeTypeModelViewMode.bind(this, typeModelViewMode.value)} color='primary' outline={viewMode !== typeModelViewMode.value} size="sm">
                                    <i className="fa fa-th-list" aria-hidden="true"></i>
                                </Button>
                                <Button onClick={onChangeTypeModelViewMode.bind(this, itemProperties.additionalTypeModelViewMode.value)} color='primary' outline={viewMode !== itemProperties.additionalTypeModelViewMode.value} size="sm">
                                    <i className="fa fa-th-large" aria-hidden="true"></i>
                                </Button>
                            </ButtonGroup>
                        }
                        <TypeModelCreateAction workflow={workflow} itemProperties={itemProperties} actions={workflow.currentItem.inlineAction} />
                    </div>
                </div>
                {
                    rows.length ?
                        <div >
                            <div className="d-flex justify-content-between pb-2 pt-2 tableEntriesAndSearchBar ">
                                <div className="d-flex align-items-center tableShowEntieriesOF ">
                                    <span>Show</span>
                                    <Input value={pageSize} onChange={(event) => {
                                        this.onChangePageSize(parseInt(event.target.value));
                                    }} type="select" className="select ml-2 mr-1" name="select"
                                        style={{
                                            minWidth: '80px'
                                        }}
                                    >
                                        <option value={10}>10</option>
                                        <option value={25}>25</option>
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                        <option value={500}>500</option>
                                        <option value={1000}>1000</option>
                                        <option value={5000}>5000</option>
                                    </Input>
                                    <span>entries</span>
                                </div>
                                <Input
                                    style={{
                                        width: '160px',
                                        lineHeight: '1',
                                        marginBottom: '6px'
                                    }}
                                    type="text"
                                    placeholder="Search"
                                    onChange={this.searchByKeyword}
                                />
                            </div>
                            <Table
                                workflow={workflow}
                                rows={filteredDataList}
                                columns={columns}
                                sortByClassProperty={this.sortByClassProperty}
                                inlineActions={this.getCompatibleInlineActions(workflow.currentItem.inlineAction, itemProperties)}
                                typeModelName={itemProperties.field.customModelName}
                                sorting={sorting}
                                isReadOneTable={true}
                            />
                            <div className="d-flex justify-content-between mb-2 tableShowEntieriesOFShowing">
                                <div>
                                    <span>{`Showing ${start} to ${end} of ${rows.length} entries`}</span>
                                </div>
                                <div className="type-model-table-pagination">
                                    <ButtonGroup>
                                        <Button onClick={(event) => {
                                            event.preventDefault()
                                            this.onPageChange(currentPage - 1);
                                        }} outline color="primary" className="pagination-button" disabled={currentPage === 1}>
                                            Previous
                                        </Button>
                                        <Button onClick={(event) => {
                                            event.preventDefault()
                                        }} color="primary" className="pagination-button">
                                            {currentPage}
                                        </Button>
                                        <Button onClick={(event) => {
                                            event.preventDefault()
                                            this.onPageChange(currentPage + 1);
                                        }} outline color="primary" className="pagination-button" disabled={end === rows.length}>
                                            Next
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </div>
                        </div> : <NoDataAlertBox typeModelName={name} noDataText={noDataText} noDataTextHeading={noDataTextHeading} {...this.props} />
                }
            </div>
        );
    }
}

const TypeModelCreateAction = (props) => {
    const { actions, itemProperties, workflow } = props;
    const { allowAddition, generatedActionCodeForAllowAddition } = itemProperties;
    if (!allowAddition) return null;
    const action = findActionByCode(actions, generatedActionCodeForAllowAddition);
    if (!action) return null;
    const { actionProperties } = action;
    if (actionProperties && actionProperties.actionDisabled) {
        return null;
    }
    return (
        <ActionContainer workflow={workflow} action={action} />
    )
}

TypeModelTable.defaultProps = {
    rows: [],
    columns: []
}

TypeModelTable.propTypes = {
    workflow: PropTypes.object,
    rows: PropTypes.array,
    columns: PropTypes.array,
    label: PropTypes.object
};

export default TypeModelTable;
