import React, { Component } from 'react';
import { Cell } from 'fixed-data-table-2';
import NoDataCell from '../NoDataCell';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import GoogleMapElement from '../../../GoogleMapElement';

class LocationCell extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false
        }
        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal() {
        this.setState({ modalOpen: !this.state.modalOpen })
    }
    render() {
        const { rows, column, columnKey, rowIndex, config, width } = this.props;
        const valueToRender = rows.getObjectAt(rowIndex)[columnKey];
        let cellAlignment;
        let cellStyle;
        const showInModal = config.showInModal;
        if (config && config.styles && config.styles) {
            const styles = config.styles
            cellAlignment = styles.alignment && styles.alignment.value;
            cellStyle = {
                color: styles.color,
                textDecoration: styles.lineThrough ? 'line-through' : 'inherit'
            }
        }
        return (
            <Cell width={width} align={cellAlignment ? cellAlignment : 'left'}>
                {
                    valueToRender !== 'undefined' && valueToRender !== null && valueToRender !== '' ?
                        showInModal ? <a style={cellStyle} href="#" onClick={this.toggleModal}>
                            <i className="fa fa-external-link" area-hidden="true"></i>
                            {"  Map"}
                        </a> : <a style={cellStyle} href={`https://www.google.com/maps/search/${valueToRender}/@${valueToRender},18z`} target="_blank" >
                            <i className="fa fa-external-link" area-hidden="true"></i>
                            {"  Map"}
                        </a> : <NoDataCell {...this.props} />
                }
                <LocationModal toggleModal={this.toggleModal} showModal={this.state.modalOpen} valueToRender={valueToRender} column={column} />

            </Cell>
        );
    }
}

const LocationModal = (props) => {
    const valueToRender = props.valueToRender;
    const iframeStyle = { border: 'none' };
    const size = props.column && props.column.config && props.column.config.modalSize ? props.column.config.modalSize.value : 'lg';
    const hideHeader = props.column && props.column.config && props.column.config.hideModalHeader ? props.column.config.hideModalHeader : true;
    return <Modal className='modal-class-common' size={`${size}`} isOpen={props.showModal} toggle={() => props.toggleModal()} >
        {!hideHeader && <ModalHeader toggle={props.toggleModal}>
            {props.column.config.header}
        </ModalHeader>
        }
        <ModalBody className='p-0'>
            <iframe
                width="100%" height="100%" style={iframeStyle}
                referrerPolicy="no-referrer-when-downgrade"
                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBfhuMCKv2c1jH_lJcxJ1iNjg0mmHXY_zU&q=${valueToRender}&center=${valueToRender}`}
                allowFullscreen>
            </iframe>
        </ModalBody>
    </Modal>
}

export default LocationCell;