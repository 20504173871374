import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';


const Sidebar = (props) => {

  const useMediaQuery = (query) => {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
      const media = window.matchMedia(query);
      if (media.matches !== matches) {
        setMatches(media.matches);
      }
      const listener = () => setMatches(media.matches);
      window.addEventListener("resize", listener);
      return () => window.removeEventListener("resize", listener);
    }, [matches, query]);

    return matches;
  };
  let menuItems = props.items;
  const [hovered, setHovered] = useState(null);
  const [active, setActive] = useState(1);
  const [activeChild, setActiveChild] = useState(null);
  const [animate, setAnimate] = useState(false);
  const changeSmall = useMediaQuery("(max-height: 550px)");
  let delay = 1;
  useEffect(() => {
    setAnimate(true);
    let timer = setTimeout(() => setAnimate(false), delay * 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [active, delay]);

  return (
    <div className={`sidebar ${props.isExpanded && "expanded"}`} >
      {menuItems && menuItems.map((item, index) => {
        if (item.alwaysInTopMenu) return null;
        let middle = true;
        const hasChildren = item.childMenus && item.childMenus.length > 0;
        return hasChildren ? (
          <div
            className={`boxicon-container ${props.isExpanded && "expanded-boxicon-container"
              }`}
            key={index}
            // onMouseEnter={() => {
            //   if (middle) {
            //     setHovered(index);
            //   }
            //   if(hasChildren){
            //       setActiveChild(!activeChild);
            //       setActive(index);
            //   }
            // }}
            // onMouseLeave={() => {
            //   if (middle) {
            //     setHovered(null);
            //   }
            //   if(hasChildren){
            //       setActiveChild(!activeChild);
            //       setActive(index);
            //   }
            // }}
            onClick={() => {
              if (!hasChildren) {
                setActive(index);
              } else {
                setActiveChild(!activeChild);
                setActive(index);
              }
            }}
          >
            <div
              className={`${middle && "boxicon"} 
                        ${active === index && "active"}
                        `}
              name={item.menuIcon}
              color={
                hovered === index || active === index ? "white" : item.color
              }
              animation={active === index && animate ? "tada" : ""}
              rotate={item.rotate}
            >

              {item.menuIcon ? <i className={` fa ${item.menuIcon} menuIcon ${active === index && 'activeIcon iconBounce'}`}></i> :
                <i className={` fa fa-inbox menuIcon ${active === index && 'activeIcon iconBounce'}`}></i>}


              <div
                className={`description descrption-child
              ${props.isExpanded && "show-description"}
              ${active === index && "active-description"} `

                }
                style={props.isExpanded ? {} : { display: 'none' }}
              >
                <p className={hasChildren ? 'pChild' : ''}> {item.title}</p>
                <span className="right-icon-container-Child">
                  <i className='fa fa-chevron-right'></i>
                </span>
              </div>
            </div>
            <div>
              {activeChild && active === index && <DesktopLevelTwoMenu {...props}>
                <ul className="sidebarWithTopbar-items">
                  {
                    item.childMenus.map((child, childIndex) => {
                      if (child.hideInMenu) return null;
                      const path = `${props.baseWorkflowRoute}/${child.friendlyUrl.toLowerCase()}`;
                      const childPath = `${path}`;
                      return <NavLink className="sidebarWithTopbar-item" key={childIndex} to={childPath}>
                        <li className="sidebarWithTopbar-item-link" >
                          <span className="left-icon-link-child">
                            <i className={`fa ${child.menuIcon}`}></i>
                          </span>
                          {child.title}

                        </li>
                      </NavLink>
                    })
                  }
                </ul>
              </DesktopLevelTwoMenu>}
            </div>
          </div>
        ) : (
          <li
            className={`boxicon-container ${props.isExpanded && "expanded-boxicon-container"
              }`}
            key={index}
            // onMouseEnter={() => {
            //   if (middle) {
            //     setHovered(index);

            //   }
            // }}
            // onMouseLeave={() => {
            //   if (middle) {
            //      setHovered(null);
            //   }
            // }}
            onClick={() => {
              if (middle) {
                setActive(index);
              }
            }}
            id={item.friendlyUrl}
          >
            <NavLink to={`/app/${item.friendlyUrl.toLowerCase()}`}
              className={`${middle && "boxicon"} 
                      ${active === index && "active"}
                      `}
              name={item.menuIcon}

              color={
                hovered === index || active === index ? "white" : item.color
              }
              animation={active === index && animate ? "tada" : ""}
              rotate={item.rotate}
            >
              {item.menuIcon ? <i className={` fa ${item.menuIcon} menuIcon ${active === index && 'activeIcon iconBounce'}`}></i> :
                <i className={` fa fa-inbox menuIcon ${active === index && 'activeIcon iconBounce'}`}></i>}

              {!props.isExpanded && <UncontrolledTooltip delay={100} placement='right' target={item.friendlyUrl}>
                {item.title}
              </UncontrolledTooltip>}
              <p
                className={`description 
            ${props.isExpanded && "show-description"}
            ${active === index && "active-description"}`}

                style={props.isExpanded ? {} : { display: 'none' }}
              >
                {item.title}
              </p>

            </NavLink>
          </li>
        );
      })}
      <li className={`boxicon-container`} id={'sidebar-signout'}>
        <a className="boxicon" href="#" onClick={() => props.logout()} >
          <p
            className={`description 
            ${props.isExpanded && "show-description"}`}

          >
            Sign Out
          </p>
          <i className='fa fa-sign-out menuIcon'></i>

        </a>
        {!props.isExpanded && <UncontrolledTooltip delay={100} placement='right' target={'sidebar-signout'}>
          Sign out
        </UncontrolledTooltip>}
      </li>

    </div>
  );
};

const DesktopLevelTwoMenu = (props) => {
  return <div className={`sidebarWithTopbar ${props.isExpanded && "expandedChildMenu"}`} >
    {props.children}
  </div>
}

export default Sidebar;
