import React, { Component, useState } from "react";
import { Cell } from "fixed-data-table-2";
import NoDataCell from "../NoDataCell";
import { Popover, PopoverBody } from "reactstrap";
import { ModelPropertiesParser,ModelPropertiesParserCal } from "../../../../commons/modelPropertiesParser";
import TextStyleResolver from "../../../TextStyleResolver/index";

class TypeModelCell extends Component {
  render() {
    const {
      rows,
      rowIndex,
      columnKey,
      maskValue,
      showLastdigits,
      column: {
        field: { collection },
      },
    } = this.props;
    if (collection) {
      return <TypeModelCollection {...this.props} />;
    }
    return (
      <TypeModelSimple
        typeModeldata={
          columnKey.startsWith("$$SELF$$")  ? rows.getObjectAt(rowIndex) : rows.getObjectAt(rowIndex)[columnKey]
        }
        maskValue={maskValue}
        showLastdigits={showLastdigits}
        isDynamicColumn={columnKey.startsWith("$$SELF$$")}
        {...this.props}
      />
    );
  }
}

const TypeModelSimple = (props) => {
  const { typeModeldata, column, isDynamicColumn, rowIndex, width,maskValue,showLastdigits,column:{config} } = props;
  const caluclateField = config && config.styles && config.styles.caluclateField ? config.styles.caluclateField : null
  if (!typeModeldata) return <NoDataCell {...props} />;
  let text = ModelPropertiesParserCal(
    column.config.modelSelector,
    typeModeldata,
    isDynamicColumn && caluclateField ? 'dynamic' : ''
  );
  if (maskValue && text){
    text = text.toString().substring(0, 3) + '*****' + text.toString().substring(text.toString().length-4, text.toString().length)
    }
    if (showLastdigits && text){
      let num = text.toString().substring(0, text.toString().length-4);
      let cross = '*'
      for (let index = 0; index < num.length-1; index++) {
         cross = cross + '*'
      }
      text = cross + text.toString().substring(text.toString().length-4, text.toString().length)
      }
  let cellAlignment;
  let cellStyle;
  if (props.config && props.config.styles && props.config.styles) {
    const styles = props.config.styles;
    cellAlignment = styles.alignment && styles.alignment.value;
    cellStyle = {
      color: styles.color,
      textDecoration: styles.lineThrough ? "line-through" : "inherit",
    };
  } else {
    cellStyle = {
      width: `${width - 16}px`,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }
  }
if (text && text != "null" && typeof text === 'string'){
  text.includes("null")
  text = text.replace("null","");
}
 if(text === null ) return <NoDataCell {...props} />;

  return (
    <Cell width={width} align={cellAlignment} style={cellStyle}>
      <TextStyleResolver
        width={width}
        text={text}
        cellStyle={cellStyle}
        config={column.config}
        {...props}
      />
    </Cell>
  );
};

class TypeModelCollection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popoverOpen: false,
    };
  }

  toggle = () => {
    this.setState({ popoverOpen: !this.state.popoverOpen });
  };
  render() {
    const { columnKey, rows, rowIndex, width } = this.props;
    const models = rows.getObjectAt(rowIndex)[columnKey];
    if (!models || !models.length) return <NoDataCell  {...this.props}/>;

    const TypeModelCmps = models.map((model, index) => {
      return (
        <TypeModelSimple key={index} typeModeldata={model} {...this.props} />
      );
    });
    return (
      <Cell width={width}>
        <div className="type-model-collection-count">
          <a
            className="type-model-collection-count-text"
            id={"Popover-" + rowIndex}
            onClick={this.toggle}
            href="#"
          >{`(VIEW ${models.length})`}</a>
          <Popover
            placement="right"
            isOpen={this.state.popoverOpen}
            target={"Popover-" + rowIndex}
            toggle={this.toggle}
          >
            <PopoverBody>{TypeModelCmps}</PopoverBody>
          </Popover>
        </div>
      </Cell>
    );
  }
}

export default TypeModelCell;
