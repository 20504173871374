import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Topbar from './components/menu/Topbar';
import TopbarMenuItems from './components/menu/TopbarMenuItems';
import { Button } from 'reactstrap';
import SidebarMenu from './components/menu/SidebarMenu';
import SidebarMenuCollapsed from './components/menu/SidebarMenuCollapsed';
import { ImmutableLoadingBar as LoadingBar } from 'react-redux-loading-bar'
import { ToastContainer } from 'react-toastify';
import Transition from 'react-transition-group/Transition';
import { Redirect } from 'react-router-dom';
import SidebarWithTopbar from './components/menu/SidebarWithTopbar';
import SidebarWithTopbarDef from './components/menu/SidebarWithTopBarDef';
import config from './config';

const fadeInAndOut = ({ children, position, ...props }) => (
    <Transition
        {...props}
        timeout={800}
        onEnter={node => node.classList.add('fadeIn', 'animated')}
        onExit={node => {
            node.classList.remove('fadeIn', 'animated');
            node.classList.add('fadeOut', 'animated');
        }}>
        {children}
    </Transition>
);

class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isSideMenuActive: false,
          isExpanded:true
        }
      }
      toggleSideMenu = () => {
        this.setState({ isSideMenuActive: !this.state.isSideMenuActive });
      }
    setActiveMenu() {
        //TODO: Set Menu Highlights
    }
    toggleIsExpanded = (key) => {
        if(key){
            this.setState({ isExpanded: false });
        }
        this.setState({ isExpanded: !this.state.isExpanded });
      }

      hideSidebar = () => {
            this.setState({ isExpanded: false });
      }
    componentDidMount() {
        const { getCompanyDetails, fetchNavWorkflows,fetchNavWorkflowsPublic,getCustomIcons,user, getOfflineWorkflows } = this.props;
        getCompanyDetails && getCompanyDetails();
        getCustomIcons && getCustomIcons();
        fetchNavWorkflows && fetchNavWorkflows(user);
        fetchNavWorkflowsPublic && fetchNavWorkflowsPublic(user);
    }

    componentWillReceiveProps(nextProps) {
        var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        document.title = nextProps.companyDetails && nextProps.companyDetails.data ? nextProps.companyDetails.data.defaultWindowTitle : 'Powered by Apto.ai';
        if(nextProps.companyDetails.data && nextProps.companyDetails.data.favIcoUrl) {
            link.href = config.assetsBasePath + nextProps.companyDetails.data.favIcoUrl;
        }
    }
    
    render() {
        const { user, isDasboard, companyDetails, getCompanyDetails,getCustomIcons } = this.props;
        if(this.context.router.route.location.search && this.context.router.route.location.search.indexOf('hideMenu=true') > -1) {
            return <div className='h-100'>
            <div id='player-container' className='container-fluid p-0'>
                {this.props.children}
            </div>
            <LoadingBar className="loading-bar" />
            <ToastContainer
                position="bottom-right"
                type="default"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                pauseOnHover
                transition={fadeInAndOut}
            />
            
        </div>
        }
        if (user.isAnonymous && this.context.router.route.location.pathname === "/app/login") {
            return <Redirect to={this.context.router.route.location.pathname} />
        }
      
        const menuType = companyDetails && companyDetails.data && window.innerWidth > 480  &&
                    (user && user.user && user.user.email?companyDetails.data.menuType:companyDetails.data.publicMenuType);
                  
        const fixedSideBar = companyDetails && companyDetails.data && companyDetails.data.companySettings &&
                    (user && user.user && user.user.email?companyDetails.data.companySettings.fixedSideBar:false);
          const topBarEnabled = companyDetails && companyDetails.data && companyDetails.data.companySettings &&
                    (user && user.user && user.user.email?companyDetails.data.companySettings.topBarEnabled:false);
                    const disableRightClick = this.props.companyDetails && this.props.companyDetails.data && this.props.companyDetails.data.companySettings && this.props.companyDetails.data.companySettings.disableRightClick ? this.props.companyDetails.data.companySettings.disableRightClick  : null ;
                  
        if(menuType === 'SIDEBARWITHTOPBAR' && !fixedSideBar){
            return    <div className='h-100'>
            <SidebarWithTopbar
                currentLanguage={this.props.currentLanguage}
                user={user}
                translate={this.props.translate}
                isPublic={!(user && user.user && user.user.email)}
                logout={() => {
                    const { router: { history } } = this.context;
                    this.props.logout(history,companyDetails)
                }}
                getCompanyDetails={getCompanyDetails}
                getCustomIcons={getCustomIcons}
                baseWorkflowRoute="/app"
                companyDetails={companyDetails}
                toggleIsExpanded={this.toggleIsExpanded}
                isExpanded={this.state.isExpanded}
            />
            <div id='player-container-sidebarWithTopbar' onClick={this.hideSidebar} className='container-fluid p-0'>
                {this.props.children}
            </div>
            <LoadingBar className="loading-bar" />
            <ToastContainer
                position="bottom-right"
                type="default"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                pauseOnHover
                transition={fadeInAndOut}
            />
        </div>
        }
        if(menuType === 'SIDEBARWITHTOPBAR' && fixedSideBar){
            return     <div className='h-100'>
            <SidebarWithTopbar
                currentLanguage={this.props.currentLanguage}
                user={user}
                translate={this.props.translate}
                isPublic={!(user && user.user && user.user.email)}
                logout={() => {
                    const { router: { history } } = this.context;
                    this.props.logout(history,companyDetails)
                }}
                getCompanyDetails={getCompanyDetails}
                getCustomIcons={getCustomIcons}
                baseWorkflowRoute="/app"
                companyDetails={companyDetails}
                toggleIsExpanded={this.toggleIsExpanded}
                isExpanded={this.state.isExpanded}
            />
            <div id='player-container-sidebarWithTopbar' onClick={this.hideSidebar} className='container-fluid p-0'>
                {this.props.children}
            </div>
            <LoadingBar className="loading-bar" />
            <ToastContainer
                position="bottom-right"
                type="default"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                pauseOnHover
                transition={fadeInAndOut}
            />
        </div>
        }

        if(menuType === 'COLLAPSED') {
            return <div className="h-100 sidebar-workflow-nav"
            onContextMenu={(e) =>{ 
                disableRightClick &&  e.preventDefault() ;}}
                 
            >
                <SidebarMenuCollapsed 
                currentLanguage={this.props.currentLanguage}
                    translate={this.props.translate}
                    isPublic={!(user && user.user && user.user.email)}
                    logout={() => {
                        const { router: { history } } = this.context;
                        this.props.logout(history,companyDetails)
                    }}
                    baseWorkflowRoute="/app"
                    user={user}
                    getCompanyDetails={getCompanyDetails}
                    companyDetails={companyDetails} 
                    isDasboard={isDasboard} 
                    router={this.context.router} 
                    setActiveMenu={this.setActiveMenu.bind(this)
                        
                }>
                    <div id='player-container' className='container-fluid p-0'>
                        {this.props.children}
                    </div>
                    <LoadingBar className="loading-bar" />
                    <ToastContainer
                        position="bottom-right"
                        type="default"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        pauseOnHover
                        transition={fadeInAndOut}
                    />
                </SidebarMenuCollapsed>
            </div>
        }
        else if (menuType == "SIDEBAR" && topBarEnabled){
            return  <div className="h-100 sidebar-workflow-nav"
            onContextMenu={(e) =>{ 
                disableRightClick &&  e.preventDefault() ;}}
                 
            >
            <SidebarWithTopbarDef 
            currentLanguage={this.props.currentLanguage}
                translate={this.props.translate}
                isPublic={!(user && user.user && user.user.email)}
                logout={() => {
                    const { router: { history } } = this.context;
                    this.props.logout(history,companyDetails)
                }}
                baseWorkflowRoute="/app"
                user={user}
                getCompanyDetails={getCompanyDetails}
                companyDetails={companyDetails} 
                isDasboard={isDasboard} 
                router={this.context.router}
                topBarEnabled={topBarEnabled}  
                setActiveMenu={this.setActiveMenu.bind(this)      
            }>
                <div id='player-container-sidebarWithTopbarDef' className='container-fluid p-0' >
                    {this.props.children}
                </div>
                <LoadingBar className="loading-bar" />
                <ToastContainer
                    position="bottom-right"
                    type="default"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    pauseOnHover
                    transition={fadeInAndOut}
                />
            </SidebarWithTopbarDef>
        </div>
       }
        else if (menuType == "SIDEBAR" && !topBarEnabled){
             return  <div className="h-100 sidebar-workflow-nav"
             onContextMenu={(e) =>{ 
                disableRightClick &&  e.preventDefault() ;}}
                 
             >
             <SidebarMenu 
             currentLanguage={this.props.currentLanguage}
                 translate={this.props.translate}
                 isPublic={!(user && user.user && user.user.email)}
                 logout={() => {
                     const { router: { history } } = this.context;
                     this.props.logout(history,companyDetails)
                 }}
                 baseWorkflowRoute="/app"
                 user={user}
                 getCompanyDetails={getCompanyDetails}
                 companyDetails={companyDetails} 
                 isDasboard={isDasboard} 
                 router={this.context.router}
                 topBarEnabled={topBarEnabled}  
                 setActiveMenu={this.setActiveMenu.bind(this)      
             }>
                 <div id='player-container' className='container-fluid p-0'>
                     {this.props.children}
                 </div>
                 <LoadingBar className="loading-bar" />
                 <ToastContainer
                     position="bottom-right"
                     type="default"
                     autoClose={3000}
                     hideProgressBar={false}
                     newestOnTop={false}
                     closeOnClick
                     pauseOnHover
                     transition={fadeInAndOut}
                 />
             </SidebarMenu>
         </div>
        }
        return (
            <div className='h-100'>
                <TopbarMenuItems
                    currentLanguage={this.props.currentLanguage}
                    user={user}
                    translate={this.props.translate}
                    isPublic={!(user && user.user && user.user.email)}
                    logout={() => {
                        const { router: { history } } = this.context;
                        this.props.logout(history,companyDetails)
                    }}
                    getCompanyDetails={getCompanyDetails}
                    getCustomIcons={getCustomIcons}
                    baseWorkflowRoute="/app"
                    companyDetails={companyDetails}
                />
                <div id='player-container' className='container-fluid p-0'>
                    {this.props.children}
                </div>
                <LoadingBar className="loading-bar" />
                <ToastContainer
                    position="bottom-right"
                    type="default"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    pauseOnHover
                    transition={fadeInAndOut}
                />
                {/* <SidebarMenu isDasboard={isDasboard} router={this.context.router} translate={ this.props.translate } setActiveMenu={this.setActiveMenu.bind(this)}>
                <div id='player-container' className='container-fluid p-0'>
                    {this.props.children}
                </div>
                <LoadingBar className="loading-bar" />
                <ToastContainer
                    position="bottom-right"
                    type="default"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    pauseOnHover
                    transition={fadeInAndOut}
                />
                </SidebarMenu> */}
            </div>
        );
    }
}

Layout.propTypes = {
    logout: PropTypes.func,
    user: PropTypes.object,
    isDasboard: PropTypes.bool
};

Layout.defaultProps = {
    isDasboard: false
}

Layout.contextTypes = {
    router: PropTypes.object
}

export default Layout;