import React, { Component } from 'react';
import { Cell } from 'fixed-data-table-2';
import NoDataCell from '../NoDataCell';
import TextStyleResolver from '../../../TextStyleResolver/index';
import conf from '../../../../../config';
import { Popover, PopoverBody, Modal, ModalHeader, ModalBody } from "reactstrap";
import { ModelPropertiesParser } from "../../../../commons/modelPropertiesParser";

class SimpleModelCell extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  popoverOpen: false,
		  modalOpen: false
		};
		this.toggleModal = this.toggleModal.bind(this);
	}

	toggleModal() {
		this.setState({ modalOpen: !this.state.modalOpen })
    }

	toggle = () => {
		this.setState({ popoverOpen: !this.state.popoverOpen });
	};
    render() {
        const { rows, column, columnKey, rowIndex, config, width,maskValue,showLastdigits } = this.props;
        const { field: { type }, config: {prependAssetsUrl}} = column;
		let valueToRender = rows.getObjectAt(rowIndex)[columnKey];
		const showInModal = config.showInModal;
		if (maskValue && valueToRender){
		
			valueToRender = valueToRender.toString().substring(0, 3) + '*****' + valueToRender.toString().substring(valueToRender.toString().length-4, valueToRender.toString().length)
		  }
		  if (showLastdigits && valueToRender){
			let num = valueToRender.toString().substring(0, valueToRender.toString().length-4);
			let cross = '*'
			for (let index = 0; index < num.length-1; index++) {
			   cross = cross + '*'
			}
			valueToRender =  cross + valueToRender.toString().substring(valueToRender.toString().length-4, valueToRender.toString().length)
		  }
		const wfData = rows.getObjectAt(rowIndex);
		if(!valueToRender){
            return <NoDataCell {...this.props} /> 
        }
		let flyoutextText = config.flyout && ModelPropertiesParser(
			column.config.flyoutField,
			rows.getObjectAt(rowIndex)
		);
		flyoutextText = flyoutextText && flyoutextText.split(',');
		flyoutextText = flyoutextText && flyoutextText.length && flyoutextText.join("\n");
		let cellAlignment;
        let cellStyle;
        if(config && config.styles && config.styles) {
            const styles = config.styles
            cellAlignment = styles.alignment && styles.alignment.value;
            cellStyle = {
                color: styles.color,
                textDecoration: styles.lineThrough ? 'line-through' : 'inherit'
            }
        }
		if(type === 'IMAGE' || prependAssetsUrl) {
			
			if(Array.isArray(valueToRender) ) {
				if(valueToRender.length > 0)
				{
					let newVal = [];
					valueToRender.forEach((item) => {
						item = item.indexOf(`${conf.assetsBasePath}` == -1 ) ? encodeURI(`${conf.assetsBasePath}${item}`) : item;
						newVal.push(item)
					});
					valueToRender = newVal.join(', ');
				}
			} else {
				valueToRender = !valueToRender ? '' : valueToRender.indexOf(`${conf.assetsBasePath}`) == -1 ? encodeURI(`${conf.assetsBasePath}${valueToRender}`) : valueToRender;
			}
			
		}
		if(type === 'DECIMAL' || type==='CALCULATED_NUMBER'){
			const enableCommaSep = config && config.enableCommaSep
			const commaNumber = require('comma-number')
			const ValueAfterCommaSeprate = commaNumber(valueToRender)
			 valueToRender = enableCommaSep ? ValueAfterCommaSeprate : valueToRender
		}
        return (
            <Cell width={width} style={cellStyle} align={cellAlignment ? cellAlignment : 'left'}>
				{ config.flyout ? 
                    <div ><a
						className="fa fa-info-circle mr-1"
						id={"Popover-" + rowIndex}
						onClick={this.toggle}
						href="#"
					></a>
				    <Popover
						placement="right"
						isOpen={this.state.popoverOpen}
						target={"Popover-" + rowIndex}
						toggle={this.toggle}
				    >
						<PopoverBody><div style={{whiteSpace: 'break-spaces'}}>{flyoutextText}</div ></PopoverBody>
					</Popover></div> : ''
                } 
                { valueToRender !== 'undefined' && valueToRender !== undefined && valueToRender !== null && valueToRender !== '' ? 
                    <TextStyleResolver showInModal={showInModal} wfData={wfData} cellStyle={cellStyle} text={`${valueToRender}`} 
						toggleModal={this.toggleModal} {...this.props} /> : 
                    <NoDataCell {...this.props} /> 
                }     
				<ImageModal toggleModal={this.toggleModal} showModal={this.state.modalOpen} valueToRender={valueToRender} column={column}/>   
            </Cell>
        );
    }null
}

const ImageModal = (props) => {
    const valueToRender = props.valueToRender;
    const iframeStyle = { border: 'none' };
	const size = props.column && props.column.config && props.column.config.modalSize ? props.column.config.modalSize.value : 'lg';
    const hideHeader = props.column && props.column.config && props.column.config.hideModalHeader ? props.column.config.hideModalHeader : true;
    return <Modal className='modal-class-common' size={`${size}`} isOpen={props.showModal} toggle={() => props.toggleModal()} >
         {!hideHeader && <ModalHeader toggle={props.toggleModal}>
            {props.column.config.header}
        </ModalHeader>}
        <ModalBody className='d-flex justify-content-center p-0'>
            <img width="100%" height="100%" src={`${valueToRender}`}/>
        </ModalBody>
    </Modal>
}

export default SimpleModelCell;