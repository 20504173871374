import React, { Component } from 'react';
import PropTypes from 'prop-types';
import WorkflowItemContainer from '../../containers/workflow/WorkflowItemContainer';
import RenderCompanyLogo from '../commons/RenderCompanyLogo';
import config from '../../config';
import { Alert } from 'reactstrap';

class Workflow extends Component {
    componentDidMount(){
        this.props.getUserProfile()
    }
    render() {
        const { workflow,user , companyDetails } = this.props;
        const userProfilePicture = user.profile.data && user.profile.data.profilePicture
        const userProfilePicturePath = `${config.assetsBasePath}${userProfilePicture}`;
        const disableRightClick = this.props.companyDetails && this.props.companyDetails.data && this.props.companyDetails.data.companySettings && this.props.companyDetails.data.companySettings.disableRightClick ? this.props.companyDetails.data.companySettings.disableRightClick  : null ;
        if(!this.props.workflow || !this.props.workflow.currentItem || !this.props.workflow.currentItem.layoutProperties)
            return null;
        const { workflow: { currentItem:{layoutProperties: { pageHeaderConfig}}}} = this.props
        const showHeaderWeb = pageHeaderConfig && pageHeaderConfig.showHeaderWeb;
        const LogoLink = user && user.user && user.user.email ? '/app/' : '/';
        if (!workflow) return null;
        if (workflow.currentStep === -1) {
            return <Alert color='info' className='p-3 m-4'><i className="fa fa-exclamation-circle" aria-hidden="true"></i> You have reached the end of this workflow.</Alert>
        }
        return (
            <div className='h-100'  onContextMenu={(e) =>{ 
                disableRightClick &&  e.preventDefault() ;}}
            >
                   {showHeaderWeb &&    <div className="web-header-div" style={{ height: 80, elevation: 3, flexDirection: "row", display: "flex", borderWidth: 2, borderColor: "black", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)" }}>
                            <div className='web-header-logo' style={{marginLeft:24}}>
                                <a href={LogoLink} className="web-company-logo" style={{ padding: 14 }} >
                                    <RenderCompanyLogo />
                                </a>
                            </div>
                            <div className='web-header-img' style={{ display: "flex", flexDirection: "row",marginLeft:20 }}>
                                <img src={userProfilePicturePath} width="55" height="55" style={{ borderTopLeftRadius: 10, borderBottomRightRadius: 10, margin: 11 ,boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)"}} />
                                <div className='web-header-name-div' style={{ display: "flex", flexDirection: "column",marginTop:10 }}>
                                    <span className='web-header-name'style={{fontSize:15,textTransform:"uppercase"}}>{user.user.firstName} {user.user.lastName}</span>
                                    <span style={{fontSize:13,color:"#524A5F",opacity:0.5}}>Profile</span>
                                </div>
                            </div>
                        </div>}
                <WorkflowItemContainer {...this.props} />
            </div>
        );
    }
}

Workflow.propTypes = {
    workflow: PropTypes.object
};

export default Workflow;