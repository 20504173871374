import React, { useState } from 'react';
import { DotChartOutlined } from '@ant-design/icons';
import { Divider, Form, Radio, Skeleton, Space, Switch } from 'antd';
const LoadingNumberReport = () => {
  const [active, setActive] = useState(true);
  const [block, setBlock] = useState(false);
  const [size, setSize] = useState('default');
  const [buttonShape, setButtonShape] = useState('default');
  const [avatarShape, setAvatarShape] = useState('circle');
  const handleActiveChange = (checked) => {
    setActive(checked);
  };
  const handleBlockChange = (checked) => {
    setBlock(checked);
  };
  const handleSizeChange = (e) => {
    setSize(e.target.value);
  };
  const handleShapeButton = (e) => {
    setButtonShape(e.target.value);
  };
  const handleAvatarShape = (e) => {
    setAvatarShape(e.target.value);
  };
  return (
    <>
      <br />
      <Space>
        <Skeleton.Image active={active} />
        <Space style={{display:'flex',flexDirection:'column',alignItems:'baseline'}}>
        <Skeleton.Input active={active} size={size} block={block} />
        <Skeleton.Button active={active} size={size} shape={buttonShape} block={block} />
        </Space>
        </Space>
      <Divider />
      <Form
        layout="inline"
        style={{
          margin: '16px 0',
        }}
      >
      </Form>
    </>
  );
};
export default LoadingNumberReport;