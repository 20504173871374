import React from 'react';
import PrintText from './PrintText';
import { Field } from 'redux-form/immutable';
import { renderField } from './_FORM_FIELDS';
import { FormText, FormGroup, Input, Label,FormFeedback } from 'reactstrap';
import PropTypes from 'prop-types';

class Password extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            confirmPassword: '',
            validPassword:false
        }
    }


    passwordFunc = (e) => {
        this.setState({ password: e.target.value },()=>{
            if(this.state.password.localeCompare(this.state.confirmPassword) === 0){
                this.setState({validPassword:true})
                this.props.toggleSaveandNextState(false)
            }else{
                this.setState({validPassword:false})
                this.props.toggleSaveandNextState(true)
            }
        })
    }


    passwordFuncWithoutConfirmPassword = (e) => {
        this.setState({ password: e.target.value  })
    }
    confirmPasswordFunc = (e) => {
        this.setState({ confirmPassword: e.target.value },()=>{
            if(this.state.password.localeCompare(this.state.confirmPassword) === 0){
                this.setState({validPassword:true})
                this.props.toggleSaveandNextState(false)
            }else{
                this.setState({validPassword:false})
                this.props.toggleSaveandNextState(true)
            }

        })
    }
    componentDidMount (){
        const {toggleSaveandNextState}= this.props;
        const { layoutItem: { itemProperties: { passwordConfig, field: { properties } } } } = this.props;

        if(passwordConfig && passwordConfig.confirmPassword){
    
            toggleSaveandNextState(true)
        }
    }

    render() {
        const { classProperty, type, validations, value, isReadOne, initialValues, ...rest } = this.props;
        const { layoutItem: { itemProperties: { passwordConfig, field: { properties } } } } = this.props;
        const { password, confirmPassword } = this.state;
        
        return (
            <div>
                {
                    isReadOne ?
                        <PrintText {...this.props} /> :
                        <div>
                            <div style={{ marginBottom: 30 }}>
                                <Field
                                    name={classProperty}
                                    component={renderField}
                                    type="password"
                                    validate={validations}
                                    value={value}
                                    fieldType={type}
                                    onChange={passwordConfig && passwordConfig.confirmPassword ? this.passwordFunc : this.passwordFuncWithoutConfirmPassword}
                                    {...rest}
                                />
                            </div>
                          
                            {
                                passwordConfig && passwordConfig.confirmPassword && <FormGroup>
                                    {passwordConfig && passwordConfig.confirmPasswordLabelText && <Label for={'confirmPassword'}>{passwordConfig.confirmPasswordLabelText}</Label>}
                                    <Input
                                        type="password"
                                        placeholder={passwordConfig && passwordConfig.confirmPasswordPlaceholderText ? passwordConfig.confirmPasswordPlaceholderText : ""}
                                        onChange={this.confirmPasswordFunc}
                                    />
                                    {!(this.state.validPassword) ? (
                                        <FormFeedback className='animated fadeIn d-block'>
                                            <i className='fa fa-exclamation-triangle' aria-hidden='true'></i>{' '}
                                            Passwords do not match
                                        </FormFeedback>
                                    ) : (
                                        ''
                                    )}
                                </FormGroup>
                            }
                        </div>
                }
            </div>
        );
    }
}

Password.contextTypes = {
    router: PropTypes.object
}

export default Password;