import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Badge } from 'reactstrap';
import { setInterval, clearInterval } from 'timers';
import { fromJS } from 'immutable';

class Logout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logoutConfig: { minutes: 0, seconds: 0 },
      interval: null
    }
  }
  componentDidMount() {


  }

  componentDidMount() {
    const { getCompanyDetails, companyDetails } = this.props;
    const { router: { history } } = this.context;
    getCompanyDetails && getCompanyDetails();
    let { timerIndex } = this.props;
    const { itemProperties } = this.props.layoutItem;
    const { logoutConfig } = itemProperties;
    this.setState({ logoutConfig: logoutConfig });
    const state = this.state;
    const props = this.props;
    let self = this;
    const interval = setInterval(function () {
      const { logoutConfig } = self.state;
      let { minutes, seconds, showAsTimer } = logoutConfig;
      if (seconds) {
        seconds--;
      } else if (minutes) {
        minutes--;
        seconds = 59;
      } else {

        clearInterval(interval);
        showAsTimer && this.props.logout(history, companyDetails);

      }
      logoutConfig.minutes = minutes;
      logoutConfig.seconds = seconds;
      self.setState({ logoutConfig: logoutConfig });
    }.bind(this), 1000);
    self.setState({ interval });
  }

  componentWillUnmount() {
    this.state.interval && clearInterval(this.state.interval);
  }

  Logout = (history, companyDetails) => {
    this.props.logout(history, companyDetails);
  }
  render() {
    const {
      layoutItem: {
        itemProperties: {
          label: { text },
        },
      }, companyDetails,

    } = this.props;

    const { router: { history } } = this.context;

    const { logoutConfig } = this.state;
    const badgeStyle = {
      minWidth: '40px',
      margin: '0 3px'
    }
    const { minutes, seconds, hideTimer } = logoutConfig;
    if (hideTimer) return '';

    return (

      <div>
        {logoutConfig && logoutConfig.showAsTimer ?
          <div>
            <h1>hey</h1>
            <div>
              {minutes ?
                <Badge style={badgeStyle} color='primary'>Min</Badge> : null
              }
              <Badge style={badgeStyle} color='primary'>Sec</Badge>
            </div>
            <div>
              {
                minutes ?
                  <Badge style={badgeStyle} color='primary'>{minutes}</Badge> : null
              }
              <Badge style={badgeStyle} color='primary'>{seconds ? seconds : '00'}</Badge>
            </div>
          </div> :
          <Button
            className='logout-btn'
            onClick={() => this.Logout(history, companyDetails)}
          >
            {text}
          </Button>
        }
      </div>
    )
  }
}


Logout.contextTypes = {
  router: PropTypes.object,
};


export default Logout;