import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RenderMessage from './RenderMessage';

class WorkflowMessages extends Component {
    render() {
        const { workflow } = this.props;
        if (!workflow) return null;
        const { messages } = workflow;
        if (!messages || (messages && !messages.length)) return null;

        return (
            <div className='workflow-messages-main-container'>
                {
                    messages.map((message, index) => {
                       if( (message && message.messageType &&  message.messageType === 'DEBUG') ||(message && message.messageType &&  message.messageType === 'TRACE') ) return null;
                        return <RenderMessage key={index} workflowMessage={message} {...this.props} />
                    })
                }
               <div className='showDebugMessages'>
                {
                    messages.map((message, index) => {
                        if( !(message && message.messageType &&  message.messageType === 'DEBUG') ||(message && message.messageType &&  message.messageType === 'TRACE') ) return null;
                        return <RenderMessage key={index} workflowMessage={message} {...this.props} />
                    })
                }
                </div>
            </div>
        );
    }
}

WorkflowMessages.defaultProps = {
    workflow: null
}

WorkflowMessages.propTypes = {
    workflow: PropTypes.object
};

export default WorkflowMessages;