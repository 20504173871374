import { connect } from 'react-redux';
import ActionElement from '../../components/workflow/action/ActionElement';
import { getTranslate } from 'react-localize-redux';
import { toJS } from '../to-js';
import { triggerAction, clearActionTriggeredState, invokeActionRules } from '../../actions/workflow-actions';
import { getCustomIcons } from '../../actions/company';

const mapStateToProps = (state, ownProps) => {
    const { action } = ownProps;
    if (typeof state.get === 'undefined') return {};
    return {
        translate: getTranslate(state.get('locale')),
        action,
        actionState: state.getIn(['workflowActions', action.id]),
        customIcons: state.getIn(['company', 'icons'])
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    const { action, tid, workflow, typeModelId, containerId, columnIndex} = ownProps;
    return {
        triggerAction: () => {
            
            dispatch(triggerAction(action, tid, workflow, ownProps.wfData, typeModelId, containerId, columnIndex));
        },
        clearActionTriggeredState: (payload) => {
            dispatch(clearActionTriggeredState(payload));
        },
        invokeActionRules: (payload) => {
            return dispatch(invokeActionRules(payload));
        },
        getCustomIcons: payload => {
            dispatch(getCustomIcons(payload));
          },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(ActionElement));