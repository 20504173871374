import React from 'react';
import PrintText from './PrintText';
import { Field } from 'redux-form/immutable';
import { renderField, renderSelect } from './_FORM_FIELDS';
import { FormText } from 'reactstrap';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { ModelPropertiesParser } from '../commons/modelPropertiesParser';
import { onUIAction } from '../../utils/uiActions';
import CustomTypeModelCreateUpdate from './CustomTypeModelCreateUpdate';
import { rtrim } from 'validator';

class SmallText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uiAction: null,
      tendigitGenNumber: null
    };
  }
  componentDidMount() {
    const {
      workflow,
      isBase,
      change,
      layoutItem: {
        itemProperties: {
          smallTextConfig,
          field: { classProperty },
        },
      },
      user: {
        user
      }
    } = this.props;
    if (smallTextConfig && smallTextConfig.displayAsOneToOne && !smallTextConfig.showAsTextFilter) {
      const oneToOneModel = smallTextConfig.oneToOneModel;
      const name = oneToOneModel.name;

      this.props.getModelByNamePromise({ name: name }).then(res => {
        const model = res.data;
        const typeId = model.id;
        const customerName = model.customerName;
        this.props.readAll({
          typeId,
          customerName,
          workflow: {
            id: workflow.id,
          },
          isBase,
          count: 10000,
          start: 0,
        });
      });
    }
    const uia = workflow && workflow.currentItem.layoutProperties.uiActions;
    if (Array.isArray(uia)) {
      uia.map((action, aIndex) => {
        if (classProperty === action.triggerActionOn) {
          this.setState({ uiAction: uia });
        }
      });
    }

    if(smallTextConfig && smallTextConfig.generateDefaultValue) {
      const date = new Date();
      let addItems = date.getFullYear() + date.getMonth() + date.getDate() + date.getHours() + date.getMinutes() + date.getSeconds() + date.getMilliseconds();
      if(user && user.token) {
        let rToken = user.token.replaceAll("-", "");
        var randomnumber = Math.floor(Math.random() * (25 - 0 + 1)) + 0;
        addItems = `${rToken.slice(randomnumber, randomnumber+6)}${addItems}`;
      }
      this.setState({tendigitGenNumber: addItems.toUpperCase()}, () => {
        change(
          classProperty,
          this.state.tendigitGenNumber
        );
      });

    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      layoutItem: {
        itemProperties: { smallTextConfig },
      },
      value,
    } = nextProps;
    if (smallTextConfig && value.length <= 0 && smallTextConfig.loadFromUrl) {
      const {
        router: {
          history: {
            location: { search },
          },
        },
      } = this.context;
      qs.parse(search)[smallTextConfig.key] &&
        nextProps.change(
          nextProps.classProperty,
          qs.parse(search)[smallTextConfig.key]
        );
    }
  }
  normalize(value) {
    if (!value) return value;
    return value.value;
  }
  render() {
    const {
      typeModelCollection,
      classProperty,
      type,
      validations,
      value,
      isReadOne,
      initialValues,
      ...rest
    } = this.props;
    const {
      layoutItem: {
        itemProperties: {
          smallTextConfig,
          field: { properties },
        },
        displayAsOneToOne,
      },
    } = this.props;
    const fieldToDisplay = smallTextConfig && smallTextConfig.fieldToDisplay;
    const fieldToSave = smallTextConfig && smallTextConfig.fieldToSave;
    const oneToOneModel = smallTextConfig && smallTextConfig.oneToOneModel;
    const modelData =
      typeModelCollection &&
      oneToOneModel &&
      typeModelCollection[`${oneToOneModel.name}`];
    const selectOptions =
      modelData &&
      Array.isArray(modelData.data) &&
      modelData.data.map(item => {
        return {
          label: ModelPropertiesParser(fieldToDisplay, item),
          value: ModelPropertiesParser(`{{${fieldToSave}}}`, item),
          ...item,
        };
      });
    return (
      <div>
        {isReadOne ? (
          <PrintText {...this.props} />
        ) : (
          <div>
            {smallTextConfig && smallTextConfig.displayAsOneToOne && !smallTextConfig.showAsTextFilter ? (
              <Field
                onBlur={() => {
                  onUIAction(
                    this.props.formValues,
                    this.props.dispatch,
                    this.state.uiAction,
                    this.props.change
                  );
                }}
                name={classProperty}
                component={renderSelect}
                type='select'
                validate={validations}
                fieldType='TYPE'
                valueKey='value'
                labelKey='label'
                options={selectOptions}
                properties={properties}
                normalize={this.normalize.bind(this)}
                {...rest}
              />
            ) : smallTextConfig && smallTextConfig.displayAsOneToOne && smallTextConfig.showAsTextFilter ? (
              <CustomTypeModelCreateUpdate modifier="SMALLTEXT" {...this.props}/>
            ) : (
              <Field
                name={classProperty}
                component={renderField}
                _onBlur={(formValues, target) => {
              //    e.preventDefault()
                  onUIAction(
                    formValues,
                    this.props.dispatch,
                    this.state.uiAction,
                    this.props.change,
                    target
                  );
                }}
                disabled={
                  this.props.disableLayoutItem ||
                  (smallTextConfig && smallTextConfig.disableEditing)
                }
                type='text'
                validate={this.props.disableLayoutItem ? [] : validations}
                value={value}
                fieldType={type}
                {...rest}
              />
            )}

            <FormText>{properties.validationMessage}</FormText>
          </div>
        )}
      </div>
    );
  }
}

SmallText.contextTypes = {
  router: PropTypes.object,
};

export default SmallText;
