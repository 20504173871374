import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Form, FormGroup, Label, Input, InputGroup, InputGroupAddon, Alert } from 'reactstrap';
import { Field, reduxForm, formValueSelector } from 'redux-form/immutable';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import { toJS } from '../../../containers/to-js';
import { setPassword, resetSetpasswordState } from '../../../actions/user';
import { getCompanyDetails } from '../../../actions/company';
import isEmail from 'validator/lib/isEmail';
import SetPasswordWorkflow from './SetPasswordWorkflow';
import config from '../../../config';

class SetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            passwordType: 'password',
            recaptcha: false
        }
    }
    componentDidMount() {
        localStorage.removeItem('user');
		const { companyDetails } = this.props;
        const reflink = this.context.router.route.match.params.reflink;
        this.props.change('refLink', reflink);
		if(companyDetails && companyDetails.data == null) {
			this.props.getCompanyDetails();
		}
		
    }
    componentWillReceiveProps(nextProps) {
        var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        document.title = nextProps.companyDetails && nextProps.companyDetails.data ? nextProps.companyDetails.data.defaultWindowTitle : 'Powered by Apto.ai';
        if(nextProps.companyDetails.data && nextProps.companyDetails.data.favIcoUrl) {
            link.href = config.assetsBasePath + nextProps.companyDetails.data.favIcoUrl;
        }
    }
    setPasswordType(passwordType) {
        this.setState({ passwordType });
    }

    renderField({ input, label, type, placeholder, meta: { touched, error } }) {
        return <FormGroup>
            <Label for={input.name}>{label}</Label>
            <Input placeholder={placeholder} type={type} { ...input } />
            {touched && error && <small className='text-danger form-text mt-2 animated fadeIn'><i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {error}</small>}
        </FormGroup>
    }
    
    showPassword(event) {
        this.setPasswordType(event.target.checked ? 'text' : 'password');
    }
    componentWillUnmount() {
         this.props.resetSetpasswordState();
    }
    onRecaptchaSuccess = () => {
        this.setState({ recaptcha: true });
    }
    render() {
        const { handleSubmit, setPasswordState, companyDetails } = this.props;
        const logoutUrl = companyDetails && companyDetails.data && companyDetails.data.logoutURL ? companyDetails.data.logoutURL : '/app/login'
		const companyName = companyDetails && companyDetails.data != null ? companyDetails.data.description : '';
        const { passwordType } = this.state;
        const isSetPasswordIsHavingError = setPasswordState && setPasswordState.data && setPasswordState.data.message;
        const setPasswordErrorMsg = setPasswordState && setPasswordState.data && setPasswordState.data.data;
		const reflink = this.context.router.route.match.params.reflink;
        if(companyDetails.isLoading) {
            return ''
        }
        if (setPasswordState && !setPasswordState.isLoading && setPasswordState.data !== null && !companyDetails.data.companySettings.setPasswordWorkflow) {
            return <PasswordChangedSuccessfully {...this.props}/>
        }

        if (companyDetails && companyDetails.data && companyDetails.data.companySettings && companyDetails.data.companySettings.setPasswordWorkflow) {
            return <SetPasswordWorkflow reflink={reflink}  setPasswordErrorMsg={setPasswordErrorMsg} isSetPasswordIsHavingError={isSetPasswordIsHavingError}  workflowCode={companyDetails.data.companySettings.setPasswordWorkflow} setPassword={handleSubmit} setPasswordState={setPasswordState} {...this.props}/>
        }
    
        return (
            <Row className='h-100 d-flex align-items-center justify-content-center public-user-page'>
                <Col xs="12" sm="10" md="4">
                    {
						companyName ? <h4 className='brand-name player'>{companyName}</h4> : <h4 className='brand-name player'>APTO<span className='ats'>.AI</span></h4>
					}
                    {
                        !reflink ? <h6 className='text-center bg-white p-3'>Please make sure that you are coming to this page via clicking the link you recieved at the time of registeration.</h6> :
                            <Form onSubmit={handleSubmit} className='player-public-user-form p-4'>
                                <h5 className='text-center mb-3'>Set your password</h5>
                                <Field
                                    name="password"
                                    component={this.renderField}
                                    type={passwordType}
                                    label='Password'
                                />
                                <FormGroup check className='text-right text-muted'>
                                    <Label check>
                                        <Input onChange={this.showPassword.bind(this)} type="checkbox" />{' '}
                                        Show Password
                                    </Label>
                                </FormGroup>
                                <Field
                                    name="confirmPassword"
                                    component={this.renderField}
                                    type="password"
                                    label='Confirm Password'
                                />
                                {/*<ReCAPTCHA
                                    ref="recaptcha"
                                    sitekey="6LdA1rYUAAAAADPkB4lFQbyas7GQ_1LQalVgiu4-"
                                    onChange={this.onRecaptchaSuccess}
                                />*/}
                                <Button disabled={setPasswordState.isLoading } className='mt-4' color='primary' block>
                                    {setPasswordState.isLoading ? 'Please wait...' : 'Set Password'}
                                </Button>
                                <Link className='mt-4 d-block text-center' to={logoutUrl}>
                                    <i className="fa fa-arrow-left mr-1" aria-hidden="true"></i>
                                    Back to login
                                </Link>
                            </Form>
                    }

                </Col>
            </Row>
        );
    }
}

const PasswordChangedSuccessfully = (props) => {
	const { companyDetails } = props;
	const companyName = companyDetails && companyDetails.data != null ? companyDetails.data.description : '';
    const logoutUrl = companyDetails && companyDetails.data && companyDetails.data.logoutURL ? companyDetails.data.logoutURL : '/app/login'
    return (
        <Row className='h-100 d-flex align-items-center justify-content-center public-user-page'>
            <Col xs="12" sm="10" md="4">
                {
					companyName ? <h4 className='brand-name player'>{companyName}</h4> : <h4 className='brand-name player'>HUW<span className='ats'>ATS</span></h4>
				}
                <Alert color='success'>
                    <i className='fa fa-check'></i> Great! You have successfully set your password
                </Alert>
                <Link className='mt-4 d-block text-center bg-white p-2 rounded' to={logoutUrl}>
                    Continue to login
                </Link>
            </Col>
        </Row>
    )
}

SetPassword.contextTypes = {
    router: PropTypes.object
};


const validate = (values) => {
    const errors = {};
    const password = values.get('password');
    const confirmPassword = values.get('confirmPassword');
    if (!password) {
        errors.password = 'Please provide a password';
    }
    if (!confirmPassword) {
        errors.confirmPassword = 'Please retype the password';
    }
    if (password !== confirmPassword) {
        errors.confirmPassword = 'Passwords do not match';
    }
    return errors;
}

export default   reduxForm({
    form: 'player-setpassword-form',
    validate
})(SetPassword)

