import React, { useState } from 'react';
import { Col, Jumbotron } from 'reactstrap';
import CreateUpdateContainer from '../../../containers/workflow/operations/CreateUpdateContainer';
import ReadOneContainer from '../../../containers/workflow/operations/ReadOneContainer';
import ReadAllContainer from '../../../containers/workflow//operations/ReadAllContainer';

const GenericColumn = (props) => {
    const { index, columnIndex, threeColumn, twoColumn, isFirst, isLast, container: {properties : {colLayoutConfig}} } = props;
    let { OperationLayout, ...rest } = props;
     const [collapse, setCollapse] = useState(false);
     const collapsable = () => {
        setCollapse(!collapse);
      }
    const colOperation = colLayoutConfig && colLayoutConfig[`colOperation-${columnIndex}`];
    OperationLayout = colOperation ? components[colOperation] : OperationLayout;
	const columnWidth = props.container.properties.columnWidth ? props.container.properties.columnWidth.split(',') : [];
    const title = props.container.properties && props.container.properties.title
    let md = '12';
    let colWidth = columnWidth[columnIndex];
    let additionalClassName = isFirst?'no-padding-left': '';
    if(colWidth && colWidth.endsWith('!')) {
        colWidth = colWidth.substring(0, colWidth.length - 1);
        additionalClassName += ' position-fixed dropdown-menu-right h-80 scroll-div';
    }
    colWidth = colLayoutConfig && colLayoutConfig[`colWidth-${columnIndex}`];
    const  colCollapsable = colLayoutConfig && colLayoutConfig[`colCollapsable-${index}`];
    const  colTitle = colLayoutConfig && colLayoutConfig[`colTitle-${index}`];
    if(threeColumn) {
        switch(columnIndex) {
            case 0: md=colWidth ? `${parseInt(colWidth)}` : '2';
                break;
            case 1: md=colWidth ? `${parseInt(colWidth)}` : '7';
                break;
            case 2: md=colWidth ? `${parseInt(colWidth)}` : '3';
                break;
        }
    }
    if(isLast) additionalClassName += ' no-padding-right';
    if(colCollapsable === true && false) 
        return <Col md={twoColumn ? colWidth ? `${parseInt(colWidth)}` : '6' : md} className={`generic-column ${additionalClassName}`}>
            <Jumbotron className="container-column-jumbotron" >
            <div className='collapseTitleColumnDiv' onClick={collapsable}>   <h6 style={{fontWeight:'bold'}}>{colTitle}</h6>  <i className={!collapse ? "fa fa-close mr-1":"fa fa-chevron-down mr-1"} aria-hidden="true"></i> </div>
      {   !collapse &&   <> 
        <div className='operation-layout-wrapper' style={{background: 'transparent'}}>
            <OperationLayout {...rest} containerIndex={index} columnIndex={columnIndex} />
        </div> </>}
        </Jumbotron>
    </Col>
    else
    return <Col md={twoColumn ? colWidth ? `${parseInt(colWidth)}` : '6' : md} className={`generic-column ${additionalClassName}`}>
       {title &&  <span className='container-title-class'>{title}</span>}
        <div className='operation-layout-wrapper' style={{background: 'transparent'}}>
            <OperationLayout {...rest} containerIndex={index} columnIndex={columnIndex} />
        </div>
    </Col>
}

const components = {
    'READALL': ReadAllContainer,
    'READONE': ReadOneContainer,
    'CREATEUPDATE': CreateUpdateContainer,
    'REPORT': ReadOneContainer
  };

export default GenericColumn;