import React, { useState, useEffect } from 'react';
import { Label } from 'reactstrap';
import { Table, Column, Cell } from 'fixed-data-table-2';
import Measure from 'react-measure';
import { ModelPropertiesParser } from '../../commons/modelPropertiesParser';
import TextStyleResolver from '../TextStyleResolver';

const TableReport = props => {
  const [filters, onChangeFilters] = useState([]);
  const [data, onChangeData] = useState([]);
  const {
    reportConfig,
    label,
    graphHeight,
    getReportDataByAction,
    workflow,
  } = props;
  useEffect(() => {
    if (
      reportConfig &&
      Array.isArray(reportConfig.filters) &&
      reportConfig.filters[0]
    ) {
      getReportDataByAction({
        workflowSessionId: workflow.sessionId,
        actionUniqueId: reportConfig.filters[0].actionGuid.value,
        filters,
        actionName: reportConfig.filters[0].actionGuid.label
      })
        .then(res => {
          try {
            const responseDataArray = res.data.data;
            const updatedData = Object.assign([], data);
            for (let index = 0; index < responseDataArray.length; index++) {
              const responseData = res.data.data[index];
              let obj = {};
              reportConfig.columns &&
              reportConfig.columns.map((col, colIndex) => {
                const value = ModelPropertiesParser(col.value, responseData);
                console.log(col.value);
                obj[`${col.label}`] = value;
              });
              updatedData.push(obj)
            }
            
            onChangeData(updatedData);
          } catch (e) {
            console.log(e, 'JSON PARSE ERROR');
          }
        })
        .catch(ex => {
          onChangeData([]);
        });
    }
  }, [filters]);

  return <div
  style={{ width: '100%', padding: 10 }}
  className='bg-white mt-3 rounded-lg'
>
  {label.show && <Label>{label.text}</Label>}
  <Measure bounds>
                {({ measureRef }) =>
                    <div ref={measureRef}>
                        <Table
                            rowHeight={50}
                            rowsCount={data.length}
                            headerHeight={50}
                            width='100%'
                            className='neene-table-report'
                            maxHeight='500'>
                            {
                                reportConfig.columns &&
                                reportConfig.columns.map((column, index) => {
                                    return <Column
                                        key={index}
                                        header={<Cell className='neene-table-header-report'>{column.label}</Cell>}
                                        cell={<RenderCellData
                                            graphData={data}
                                            className="neene-table-cell-data"
                                            columnIndex={index}
                                            _columnKey={column.label}
                                            originalReportType='TABLE'
                                        />}
                                        width={140}
                                        flexGrow={1}
                                    />
                                })
                            }
                        </Table>
                    </div>
                }
            </Measure>
  </div>;
};

const RenderCellData = (props) => {
  return <TableTypeCell  {...props} />
}

const TableTypeCell = (props) => {
  const { graphData, rowIndex, _columnKey ,roundOffField} = props;
  const rowData = graphData[rowIndex];
  if(!rowData[_columnKey]){
      return <Cell className='table-type-cell' >Not Avalaible</Cell>;
  }
  if(roundOffField){
      let roundOffData = null ;
      for (let index = 0; index < roundOffField.length; index++) {
          roundOffData = rowData.tableData[_columnKey] ;
          if(roundOffField[index].value ===_columnKey ){
              if(typeof roundOffData === 'number' ){
                  roundOffData = parseInt(roundOffData)
                  return <Cell className='table-type-cell' >{roundOffData}</Cell>;
              }
            }
          
      }
    }
     
    return <Cell className='table-type-cell' >      <TextStyleResolver text={`${rowData[_columnKey]}`} /></Cell>
  
}
export default TableReport;
