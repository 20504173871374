import React, { Component } from 'react'
import config from '../../config';

export default class LinksElement extends Component {
    
    render() {
      const { layoutItem: { itemProperties: { linksElementConfig } } } = this.props;
      const { logo , subheading , link1 , icon1 , link2 ,icon2 , link3 , icon3, link4,link5,icon5,link6,icon6, icon4  } = linksElementConfig;
      const logoUrl = logo && logo.path;
      const logoUrlComplete = `${config.assetsBasePath}${logoUrl}`
      
    return (
      <div className='linksElement'>
        <div >
        <img src={logoUrlComplete} className='image-linksElement'  alt="logo" />
        </div>
        <div className='subHeading-linksElement'>
            {subheading}
        </div>
        <div className='allLinks-linksElement'>
           {link1 && <div>
                <a href={link1} target="_blank" className='linka link1-linksElement'>      <i className={`fa ${icon1 && icon1.cssClass}`} aria-hidden="true"></i> </a>
            </div>}
            {link2 && <div>
                <a href={link2} target="_blank" className='linka link2-linksElement'>       <i className={`fa ${icon2 && icon2.cssClass}`} aria-hidden="true"></i> </a>
            </div>}
            {link3 && <div>
                <a href={link3} target="_blank" className='linka link3-linksElement'>       <i className={`fa ${icon3 && icon3.cssClass}`} aria-hidden="true"></i> </a>
            </div>}
            {link4 && <div>
                <a href={link4} target="_blank" className='linka link4-linksElement'>       <i className={`fa ${icon4 && icon4.cssClass}`} aria-hidden="true"></i> </a>
            </div>}
            {link5 && <div>
                <a href={link5} target="_blank" className='linka link5-linksElement'>       <i className={`fa ${icon5 && icon5.cssClass}`} aria-hidden="true"></i> </a>
            </div>}
            {link6 && <div>
                <a href={link6} target="_blank" className='linka link6-linksElement'>       <i className={`fa ${icon6 && icon6.cssClass}`} aria-hidden="true"></i> </a>
            </div>}
        </div>
      </div>
    )
  }
}
