import React, { Component } from 'react';
import * as CardTemplates from '../templates/card/index';
import { Row, Col, Alert, Collapse, Card, CardBody, CardHeader } from 'reactstrap';
import { ModelPropertiesParser } from '../commons/modelPropertiesParser';
import NoDataAlertBox from '../commons/NoDataAlertBox';
import InlineWorkflow from '../workflow/InlineWorkflow';


class AccordianLayout extends Component {

    constructor(props) {
      super(props);
      this.state = {
        toggler: 'accordian-0'
      }
    }

    componentDidMount() {

    }
    
    handleToggler = (id) => {
        if ( this.state.toggler === id ) {
           this.setState({ toggler: null });
        } else {
            this.setState({ toggler: id });
        }
    }
    render() {
        const { accordianConfig, inlineActions, workflowId, accordians } = this.props;
        if (!accordianConfig) return <Alert color='warning'>Looks like the Accordian Layout has not been configured. Please try switching to the Table layout.</Alert>
        const { path, readOneWorkflowUniqueCode,accoHeading,isHeading} = accordianConfig;
        console.log(accordianConfig,"accordianConfigaccordianConfig")
        return (
            <div className="accordian-layout">
             {isHeading &&  <CardHeader className={'accordinLayoutHeading'}>{accoHeading}</CardHeader>}
              {
                accordians && accordians.map((accordian, index) => {
                  return (
                    <Card key={index} className="accordian">
                      <CardHeader
                        onClick={() => this.handleToggler(`accordian-${index}`)}>{ModelPropertiesParser(path, accordian)}
                        <i className={`icon fa ${ this.state.toggler === `accordian-${index}` ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                      </CardHeader>
                      <Collapse className="collapse-body" isOpen={this.state.toggler === `accordian-${index}`}>
                        <CardBody>
                          {
                            this.state.toggler === `accordian-${index}` ? <InlineWorkflow 
                            code={readOneWorkflowUniqueCode}
                            isAccordian={true}
                            tid={accordian.id}
                            {...this.props}
                          /> : ""
                          }
                        </CardBody>
                      </Collapse>
                    </Card>
                  )
                })
              }
            </div>
        )
    }
}

export default AccordianLayout;