import React, { useState, useEffect } from 'react';
import PrintText from './PrintText';
import { FormGroup, Label, FormFeedback } from 'reactstrap';
import { Field } from 'redux-form/immutable';
import ReactPhoneInput from 'react-phone-input-2';
 import 'react-phone-input-2/lib/style.css'
import { convertToNumber } from './_FORM_FIELDS';

const Phone = (props) => {
    const { classProperty, type, validations, value, isReadOne,data, ...rest } = props;
    return (
        <div>
            {
                isReadOne ?
                    <PrintText {...props} /> :
                    <Field
                        name={classProperty}
                        component={renderPhone}
                        type="number"
                        validate={validations}
                        value={value}
                        normalize={(value) => {
                          let valueNew = value.replace("-", "").replace(" ", "").replace("(", "").replace(")", "")
                          if(!valueNew.startsWith('+')){
                           valueNew = '+' + valueNew
                          }
                          return valueNew
                        }}
                        fieldType={type}
                        onBlur={() => {
                          const cEventKeys = window.cEvents && Object.keys(window.cEvents);
                          Array.isArray(cEventKeys) &&   cEventKeys.map(k => {
                              k.startsWith(classProperty) &&
                              window.cEvents[k](props.formValues);
                          });
                      }}
                        {...rest}
                    />
            }
        </div>
    );
}

const renderPhone = ({ input,translate, label,workflow:{currentItem:{layoutProperties}}, type, placeholder, useLabelAsPlaceholder,layoutItem: { itemProperties: { phoneConfig } }, value, min, max, step, layoutItem, meta: { touched, error }, phoneNumberCountryCode }) => {
  const hasError = touched && error;
  const { properties } = layoutItem.itemProperties.field;
  const disbleEditing = phoneConfig && phoneConfig.disableEditing 
  const minLength = layoutItem.itemProperties && layoutItem.itemProperties.minLength ? layoutItem.itemProperties.minLength : null
  const labelStyle = layoutProperties && layoutProperties.labelStyle;
  let translatedLabel = label.text;
  if (label.languageKey) {
    translatedLabel = translate(
      `label.${label.languageKey.toLowerCase().replace(/ /g, '_')}`
    );
    translatedLabel = translatedLabel.startsWith('Missing translation')
      ? label.text
      : translatedLabel;
  }
  return (
    <FormGroup>
      {
          label.show?<Label style={labelStyle?{textTransform:labelStyle}:{}} for={input.name}>
            {translatedLabel}<sup>{(properties.required || minLength) ? '*' : ''}</sup>
      </Label>
        :''
      }
      <ReactPhoneInput 
          country={phoneNumberCountryCode ? phoneNumberCountryCode : 'in'}
          countryCodeEditable={false}
          disabled={disbleEditing}
          onChange={input.onChange}
          isValid={(value, country) => {
            window[`selectedCountry`] = country;
            return true;
          }}
          inputStyle={{
            width: '100%'
          }}
          value={input.value}
          placeholder={useLabelAsPlaceholder ? label.text : placeholder.show?placeholder.text:"Phone Number"}
      />
       {
            hasError ? <FormFeedback className='animated fadeIn d-block'>
                <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {error}
            </FormFeedback> : ''
        }
    </FormGroup>
  )
}

export default Phone;