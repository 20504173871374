import { ModelPropertiesParser } from '../components/commons/modelPropertiesParser';
import { fromJS } from 'immutable';
import config from '../config';

export const onUIAction = (values, dispatch, uiActions, change, targetField) => {
  let targetName =
    window.event && window.event.target && window.event.target.name;
  if(!targetName) {
    targetName = targetField;
  }
  const tmp = {};
  const iValues = fromJS(values);
  if (Array.isArray(uiActions)) {
    const valJson = JSON.stringify(values);
    const valObj = JSON.parse(valJson);
    uiActions.map(async (ele, index) => {
      if (ele.triggerActionOn == targetName) {
        if (ele && ele.uiActionType == 'CLEARDATA') {
          const triggerValue = ele.triggerValue;
          let value = '';
          if (ele.triggerActionOn.indexOf('.') == -1) {
            value = iValues && iValues.get(`${ele.triggerActionOn}`);
          } else {
            value =
              valObj &&
              ModelPropertiesParser(`{{${ele.triggerActionOn}}}`, valObj);
          }
          if (value === true) {
            value = JSON.stringify(value);
          }
          if (
            (value != '' && value == triggerValue ||
            (triggerValue && triggerValue.toLowerCase() == '{{notnull}}'))
          ) {
            const fields = ele.fields;
            fields.map((field, j) => {
              let source = '';
              let target = '';
              try {
                tmp[`${targetName}_${j}`] = {
                  key: `${field.clearField}`,
                  value: null,
                  removeValue: true,
                };
              

              } catch (e) {
                console.log(e, 'erroe ');
              }
            });
          }
        } else  if (ele && ele.uiActionType == 'COPYDATA') {
          const triggerValue = ele.triggerValue;
          let value = '';
          if (ele.triggerActionOn.indexOf('.') == -1) {
            value = iValues && iValues.get(`${ele.triggerActionOn}`);
          } else {
            value =
              valObj &&
              ModelPropertiesParser(`{{${ele.triggerActionOn}}}`, valObj);
          }
          if (value === true) {
            value = JSON.stringify(value);
          }
          if (
            (value != '' && value == triggerValue) ||
            (triggerValue && triggerValue.toLowerCase() == '{{notnull}}')
          ) {
            const fields = ele.fields;
            fields.map((field, j) => {
              let source = '';
              let target = '';
              try {
                if (field.copyFrom.indexOf('.') == -1) {
                  source = iValues && iValues.get(`${field.copyFrom}`);
                } else {
                  source =
                    valObj &&
                    ModelPropertiesParser(`{{${field.copyFrom}}}`, valObj);
                }
                if (field.copyTo.indexOf('.') == -1) {
                  target = iValues && iValues.get(`${field.copyTo}`);
                } else {
                  target =
                    valObj &&
                    ModelPropertiesParser(`{{${field.copyTo}}}`, valObj);
                }
                if(field.copyAnObject){
                  source = JSON.parse(source)
                }
                if (!target || target == 'null' || target != source) {
                  tmp[`${targetName}_${j}`] = {
                    key: `${field.copyTo}`,
                    value: source,
                  };
                }
              } catch (e) {
                console.log(e, 'erroe ');
              }
            });
          } else {
            const fields = ele.fields;
            fields.map((field, j) => {
              let source = '';
              let target = '';
              try {
                tmp[`${targetName}_${j}`] = {
                  key: `${field.copyTo}`,
                  value: '',
                  removeValue: true,
                };
              } catch (e) {
                console.log(e, 'erroe ');
              }
            });
          }
        } else if (ele && ele.uiActionType == 'TRANSFORM') {
          const triggerValue = ele.triggerValue;
          let value = '';
          if (ele.triggerActionOn.indexOf('.') == -1) {
            value = iValues && iValues.get(`${ele.triggerActionOn}`);
          } else {
            value =
              valObj &&
              ModelPropertiesParser(`{{${ele.triggerActionOn}}}`, valObj);
          }
          if (value === true) {
            value = JSON.stringify(value);
          }
          if (
            (value != '' && value == triggerValue) ||
            (triggerValue && triggerValue.toLowerCase() == '{{notnull}}')
          ) {
            const fields = ele.fields;
            let newVal = '';
            fields.map(field => {
              let target = '';
              const transformation = field.transformation;
              if (field.target.indexOf('.') == -1) {
                target = iValues && iValues.get(`${field.target}`);
              } else {
                target =
                  valObj &&
                  ModelPropertiesParser(`{{${field.target}}}`, valObj);
              }
              if (target) {
                let alreadyExist = null;
                const keys = Object.keys(tmp);
                keys.map(key => {
                  if (key === targetName) {
                    alreadyExist = tmp[key].value;
                  }
                });
                switch (transformation) {
                  case 'LOWERCASE':
                    newVal =
                      alreadyExist != null
                        ? alreadyExist.toLowerCase()
                        : target.toLowerCase();
                    tmp[targetName] = { key: `${field.target}`, value: newVal };
                    break;
                  case 'UPPERCASE':
                    newVal =
                      alreadyExist != null
                        ? alreadyExist.toUpperCase()
                        : target.toUpperCase();
                    tmp[targetName] = { key: `${field.target}`, value: newVal };
                    break;
                  case 'TRIM':
                    if (target.startsWith('\t')) {
                      target = target.toString().replace('\t', '');
                    }
                    console.log(target.length)
                    newVal =
                      alreadyExist != null
                        ? alreadyExist.trim()
                        : target.trim();
                    tmp[targetName] = { key: `${field.target}`, value: newVal == "" ? null: newVal, removeValue: newVal == "" ? true: false };
                    break;
                  case 'REPLACE':
                    newVal =
                      alreadyExist != null
                        ? alreadyExist.replace(
                            `${field.replace}`,
                            `${field.replaceWith}`
                          )
                        : target.replace(
                            `${field.replace}`,
                            `${field.replaceWith}`
                          );
                    tmp[targetName] = { key: `${field.target}`, value: newVal };
                    break;
                  case 'REPLACEALL':
                    var regex = new RegExp(field.replace, 'g');
                    newVal =
                      alreadyExist != null
                        ? alreadyExist.replace(regex, `${field.replaceWith}`)
                        : target.replace(regex, `${field.replaceWith}`);
                    tmp[targetName] = { key: `${field.target}`, value: newVal };
                    break;
                }
              }
            });
          }
        } else if (ele && ele.uiActionType == 'TRANSLITERATE') {
          const triggerValue = ele.triggerValue;
          let value = '';
          if (ele.triggerActionOn.indexOf('.') == -1) {
            value = iValues && iValues.get(`${ele.triggerActionOn}`);
          } else {
            value =
              valObj &&
              ModelPropertiesParser(`{{${ele.triggerActionOn}}}`, valObj);
          }
          if (value === true) {
            value = JSON.stringify(value);
          }
          if (
            (value != '' && value == triggerValue) ||
            (triggerValue && triggerValue.toLowerCase() == '{{notnull}}')
          ) {
            let target = '';
            let lang = '';
            if (ele.language.indexOf('.') == -1) {
              lang = iValues && iValues.get(`${ele.language}`);
            } else {
              lang =
                valObj && ModelPropertiesParser(`{{${ele.language}}}`, valObj);
            }
            target = getText(value, lang);
            target.then(value => {
              dispatch(change(ele.target, value));
              tmp[target] = { key: `${ele.target}`, value: value };
            });
          }
        } else if (ele && ele.uiActionType == 'LOCATIONDETAILS') {
          const triggerValue = ele.triggerValue;
          let value = '';
          if (ele.triggerActionOn.indexOf('.') == -1) {
            value = iValues && iValues.get(`${ele.triggerActionOn}`);
          } else {
            value =
              valObj &&
              ModelPropertiesParser(`{{${ele.triggerActionOn}}}`, valObj);
          }
          if (value === true) {
            value = JSON.stringify(value);
          }
          if (value != '') {
            const out = await getLocationDetails(value);
            if (out) {
              const countries = await getCountries();
              if (Array.isArray(countries)) {
                countries.some(c => {
                  if (c.sortname === out.country) {
                    ele.countryField && change(ele.countryField, c.name);
                    return true;
                  }
                  return false;
                });
              }
              ele.stateField && change(ele.stateField, out.state);
              ele.cityField && change(ele.cityField, out.district);
            }
          }
        }
      }
    });
    const keys = Object.keys(tmp);
    keys.map(key => {
      if (tmp[key].value || tmp[key].removeValue)
        dispatch(change(tmp[key].key, tmp[key].value));
    });
  }
};

const getCountries = async () => {
  try {
    const url = `${config.publicAPI}/staticdata/countries`;
    const res = await fetch(url, {
      method: 'POST',
    });
    const data = await res.json();
    if (Array.isArray(data)) {
      return data;
    }
    return null;
  } catch (e) {
    console.log(e);
  }
};

const getLocationDetails = async (pincode, callback) => {
  try {
    const url = `https://api.worldpostallocations.com/pincode?postalcode=${pincode}`;
    const res = await fetch(url);
    const data = await res.json();
    if (data && data.result && data.result[0]) {
      return data.result[0];
    }
    return null;
  } catch (e) {
    console.log(e);
  }
};

async function getText(text, lang) {
  const url = `https://inputtools.google.com/request?text=${text}&itc=${lang}-t-i0-und&num=1&cp=0&cs=1&ie=utf-8&oe=utf-8`;

  try {
    const res = await fetch(url);
    const data = await res.json();
    if (data && data[0] === 'SUCCESS') {
      const found = data[1][0][1];
      return found[0];
    }
    return null;
  } catch (e) {
    // catch error
    console.error('There was an error with transliteration', e);
    return null;
  }
}

export const updateValues = (actions, values) => {
  let updatedValues = values;
  actions.map((action, index) => {
    if (action.type === 'AFTER') {
      const inputSplitter = action.inputField.split('.');
      const outputSplitter = action.outputField.split('.');
      if(inputSplitter && outputSplitter) {
        if (action.actionType === 'inwordsIN') {
            const { ToWords } = require('to-words');
            const toWords = new ToWords({
              localeCode: 'en-IN',
              converterOptions: {
                currency: false,
                ignoreDecimal: false,
                ignoreZeroCurrency: false,
                doNotAddOnly: true,
              },
            });
            let valueComing = values.getIn(inputSplitter);
            if (valueComing) {
              let numberInWords = toWords.convert(valueComing);
              const numbeInWordsarr = numberInWords.split(' ');
              for (var i = 0; i < numbeInWordsarr.length; i++) {
                numbeInWordsarr[i] =
                  numbeInWordsarr[i].charAt(0).toUpperCase() +
                  numbeInWordsarr[i].slice(1);
              }
              let numberInWordsCapitalize = numbeInWordsarr.join(' ');
    
              updatedValues = updatedValues.setIn(
                outputSplitter,
                numberInWordsCapitalize
              );
            }
          } else if (action.actionType === 'commaSep') {
            const commaNumber = require('comma-number');
            let valueComing = values.getIn(inputSplitter);
            if (valueComing) {
              valueComing = parseInt(valueComing);
              const valueAfterCommaSeprate = commaNumber(valueComing);
              updatedValues = updatedValues.setIn(
                outputSplitter,
                valueAfterCommaSeprate
              );
            }
          } else if (action.actionType === 'toINT') {
            let valueComing = values.getIn(inputSplitter);
            if (valueComing) {
              let changeNumber = valueComing.toFixed(0);
              updatedValues = updatedValues.setIn(outputSplitter, changeNumber);
            }
          }
      }
    }
  });
  return updatedValues;
};

