import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../containers/to-js';
import { login } from '../../actions/user';
import * as LoginComponents from './AuthHelpers';
import {
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Row,
  Col,
  Button,
} from 'reactstrap';
import FacebookLoginComp from './AuthHelpers/FacebookLogin';
import GoogleLoginComp from './AuthHelpers/GoogleLoginComp';
import LinkedInLoginComp from './AuthHelpers/LinkedInLoginComp';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Transition from 'react-transition-group/Transition';
import ReCAPTCHA from 'react-google-recaptcha';

const fadeInAndOut = ({ children, position, ...props }) => (
  <Transition
    {...props}
    timeout={800}
    onEnter={node => node.classList.add('fadeIn', 'animated')}
    onExit={node => {
      node.classList.remove('fadeIn', 'animated');
      node.classList.add('fadeOut', 'animated');
    }}
  >
    {children}
  </Transition>
);
class Authentication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      refcode: '',
      hasError: false,
      displayReCaptcha: false
    };
  }
  updateTwoFactor = (twoFA) => {
    this.setState({twoFactorStatus: twoFA})
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.user &&
      nextProps.user.user &&
      nextProps.user.user.hasError &&
      !this.state.hasError
    ) {
      toast.error(
        nextProps.user.user.error && nextProps.user.user.error.message && nextProps.user.user.error.message.length > 0 
          ? nextProps.user.user.error.message
          : 'Authentication Failed. Username or Password not valid.'
      );
      this.setState({hasError: true})
    }
  }

  setError = (value) => {
    this.setState({hasError: value})
  }

  render() {
    const {
      user,
      form,
      layoutItem: { itemProperties },
      authenticationCallback,
      label,
      workflow,
      companyDetails,
    } = this.props;
    const loginType =
      itemProperties && itemProperties.loginType
        ? itemProperties.loginType
        : { label: 'Email', value: 'Email' };
    const LoginHandlerComponent = LoginComponents[loginType.value];
    const showForgotPasswordAboveLoginButton = itemProperties && itemProperties.showForgotPasswordAboveLoginButton
    const hasRefCode =
      itemProperties &&
      itemProperties.additionalOptions &&
      itemProperties.additionalOptions.some(x => x.value === 'refcode');
    const hasFb =
      itemProperties &&
      itemProperties.additionalOptions &&
      itemProperties.additionalOptions.some(x => x.value === 'fb');
    const hasGoogle =
      itemProperties &&
      itemProperties.additionalOptions &&
      itemProperties.additionalOptions.some(x => x.value === 'google');
    const hasLinkedIn =
      itemProperties &&
      itemProperties.additionalOptions &&
      itemProperties.additionalOptions.some(x => x.value === 'linkedin');
    const hasForgotPassword =
      itemProperties &&
      itemProperties.additionalOptions &&
      itemProperties.additionalOptions.some(x => x.value === 'forgotpassword');
      const fbLabel =
      itemProperties &&
      itemProperties.authConfig &&
      itemProperties.authConfig.fblabel;
      const googleLabel =
      itemProperties &&
      itemProperties.authConfig &&
      itemProperties.authConfig.googlelabel

      const template =
      itemProperties &&
      itemProperties.authTemplate && itemProperties.authTemplate.value;

      const twoFactorCompanyDetails = companyDetails && companyDetails.data  && companyDetails.data.companySettings ? companyDetails.data.companySettings.enableTwoFactor : false
      const twoFA = twoFactorCompanyDetails ? twoFactorCompanyDetails : itemProperties && itemProperties.twoFactorAuth  ? itemProperties && itemProperties.twoFactorAuth  : false;
      const reCaptcha =
      itemProperties &&
      itemProperties.reCaptcha;

      const recatchpa_siteKey = itemProperties && itemProperties.recatchpa_siteKey
    return (
      <div>
        <LoginHandlerComponent
          displayReCaptcha={this.state.displayReCaptcha}
          sessionId={workflow.sessionId}
          itemProperties={itemProperties}
          user={user}
          label={label}
          login={this.props._login}
          authenticationCallback={authenticationCallback}
          requiredRole={itemProperties.requiredRole}
          hasRefCode={hasRefCode}
          setError={this.setError.bind(this)}
          hasForgotPassword={hasForgotPassword}
          showForgotPasswordAboveLoginButton={showForgotPasswordAboveLoginButton}
          getCompanyDetails= {this.props.getCompanyDetails}
          companyDetails= {this.props.companyDetails}
          twoFA={twoFA}
          reCaptchaComponent={
            reCaptcha && (
              <ReCaptcha
                recatchpa_siteKey={recatchpa_siteKey}
                displayReCaptcha={this.state.displayReCaptcha}
                updateDisplayReCaptcha={(value) => {
                  this.setState({displayReCaptcha: value})
                }}
                user={user}
              />
            )
          }
        />
        {hasForgotPassword && !showForgotPasswordAboveLoginButton && template==='Template1' && !twoFA ? (
          <div className='alt-container-login'>
            <Link to='/app/forgotpassword'>Forgot password?</Link>
          </div>
        ) : null}
        {hasFb ? (
          <div className='mt-1 form-group'>
            <FacebookLoginComp
              appId={
                itemProperties &&
                itemProperties.authConfig &&
                itemProperties.authConfig.fbAppId
              }
              requiredRole={itemProperties.requiredRole}
              user={user}
              login={this.props._login}
              authenticationCallback={authenticationCallback}
              label={fbLabel?fbLabel:null}
            />
          </div>
        ) : null}
        {hasGoogle ? (
          <div className='mt-1 form-group'>
            <GoogleLoginComp
              clientId={
                itemProperties &&
                itemProperties.authConfig &&
                itemProperties.authConfig.googleClientId
              }
              requiredRole={itemProperties.requiredRole}
              hasRefCode={hasRefCode}
              user={user}
              login={this.props._login}
              authenticationCallback={authenticationCallback}
              label={googleLabel?googleLabel:null}
            />
          </div>
        ) : null}
        {hasLinkedIn ? (
          <div className='mt-1 form-group'>
            <LinkedInLoginComp
              requiredRole={itemProperties.requiredRole}
              hasRefCode={hasRefCode}
              user={user}
              login={this.props._login}
              authenticationCallback={authenticationCallback}
              label={itemProperties.label}
            />
          </div>
        ) : null}
        <ToastContainer
          position='bottom-right'
          type='default'
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          pauseOnHover
          transition={fadeInAndOut}
        />
      </div>
    );
  }
}


const ReCaptcha = (props) => {

  const captchaRef = useRef();

  const [captchaDetails, setCaptchaDetails] = useState(null);
  useEffect(() => {
    let reCaptchaDetails = localStorage.getItem("_captcha_details");
    reCaptchaDetails = JSON.parse(reCaptchaDetails);
    if(reCaptchaDetails && reCaptchaDetails.count !== (captchaDetails && captchaDetails.count)) {
      if(reCaptchaDetails.count > 2) {
        props.updateDisplayReCaptcha(true);
        captchaRef.current && captchaRef.current.reset();
      }
      setCaptchaDetails(reCaptchaDetails);
    }
  }, [props.user])

  if(!captchaDetails) {
    return null
  }
  if(captchaDetails && captchaDetails.count < 3) {
    return null
  }
  return (
    <div>
        <ReCAPTCHA
          ref={captchaRef}
          sitekey={
            props.recatchpa_siteKey ? props.recatchpa_siteKey : '6LeWWy8UAAAAANwTQi-Y3DNwRztm_6Mqiw2AjYuF'
          }
          onChange={token => {
              if(token) {

                props.updateDisplayReCaptcha(false)
              }
          }}
        />
      </div>
  )
}

const mapStateToProps = state => {
  return {
    user: state.get('user'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    _login: (user, successCallback) => {
      dispatch(login(user, successCallback));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(Authentication));
