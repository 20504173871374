import React, { Component } from 'react';
import { Cell } from 'fixed-data-table-2';

const NoDataCell = (props) => {
    let cellAlignment;
    let cellStyle;
    const { field: { type }, config: {prependAssetsUrl}} = props.column;
   
    if (props.config && props.config.styles && props.config.styles) {
        const styles = props.config.styles
        cellAlignment = styles.alignment && styles.alignment.value;
        cellStyle = {
            color: styles.color,
            lineThrough: styles.lineThrough
        }
    }
    return (
        <Cell>
            <span align={cellAlignment ? cellAlignment : 'left'} className='text-muted' width={props.width}>
                {props.config.nullData == undefined ? ( type === 'DECIMAL' || type === 'POSITIVE_NUMBER' || type === 'CALCULATED_NUMBER' || type==='NUMBER') ? 0 : "Not Available" : `${props.config.nullData}`}
            </span>
        </Cell>
    )
}

export default NoDataCell;