import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../containers/to-js';
import { Button, Row, Col, Alert, Collapse, Card, CardBody, CardHeader, Jumbotron, Dropdown, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import WorkflowContainer from '../../containers/workflow/WorkflowContainer';
import { getWorkflowFromCode, endWorkflow, getWorkflowById } from '../../actions/workflows';
import ActionContainer from '../../containers/workflow/ActionContainer';
import NoDataAlertBox from '../commons/NoDataAlertBox';
import { save, next, updateWorkflowCardData } from '../../actions/workflow-item';
import { getFormValues } from 'redux-form/immutable';

class WorkflowCardLayout extends Component {

    constructor(props) {
      super(props);
      this.state = {
        currentItem: null,
        cardSessionId:null,
        cardWorkflowModel:null,
        cardInnerWorkflowModel:null,
        actionsDDOpen: { opendd: false, ddindex: -1},
        activeWorkflowCardIndex: 0,
        readOneWorkflowUniqueCode: null
      }
      this.next = this.next.bind(this);
      this.previous = this.previous.bind(this);
    }
	next() {
		const { __form__values, saveInline, workflowCardConfig, workflow, updateWorkflowCardData,onHandleSubmit } = this.props;
		const { currentItem: { typeModel, operation }} = this.state;
    const callNextWf = !workflowCardConfig.callNextWf ? true : workflowCardConfig.callNextWf;// temporary
    const nextIndex = this.state.activeWorkflowCardIndex === this.props.workflowCards.length - 1 ? -1 : this.state.activeWorkflowCardIndex + 1;
		if (operation == "CREATEUPDATE") { 
			const info = {
				typeModelName: typeModel.safeName,
				customerName: typeModel.customerName,
        ignoreDispatch: true
			};
			saveInline(__form__values, info); 
      updateWorkflowCardData(__form__values, this.state.activeWorkflowCardIndex);
		}
    if(nextIndex == -1 && callNextWf) {
      this.props.next(workflow)
    }
		this.setState({ activeWorkflowCardIndex: nextIndex });
	}

	previous() {
		const nextIndex = this.state.activeWorkflowCardIndex === 0 ? this.props.workflowCards.length - 1 : this.state.activeWorkflowCardIndex - 1;
		this.setState({ activeWorkflowCardIndex: nextIndex });
	}
	toggle(key, index) {
		const { opendd, ddindex } = this.state.actionsDDOpen;
        this.setState({ actionsDDOpen: { opendd: !opendd, ddindex: index }});
    }

    loadWorkflowFromCode(readOneWorkflowUniqueCode, getWorkflowFromCode, getWorkflowById) {
      if(!window.workflowCache) window.workflowCache = [];
      const workflowSessionId= this.props.workflow.sessionId;
      const workflowModel= this.props.workflow.workflowModel;
      if(window.workflowCache[readOneWorkflowUniqueCode]) {
        console.log('Loading from cache...');
        this.setState({ currentItem: window.workflowCache[readOneWorkflowUniqueCode].items[0], cardSessionId:workflowSessionId,cardWorkflowModel:workflowModel ,cardInnerWorkflowModel:window.workflowCache[readOneWorkflowUniqueCode].id});
      } else {
        if(this.state.readOneWorkflowUniqueCode != readOneWorkflowUniqueCode)
        {
          this.setState({readOneWorkflowUniqueCode})
        getWorkflowFromCode(readOneWorkflowUniqueCode).then((res) => {
          res.data && getWorkflowById(res.data.data).then((workflowResponse) => {
            if(workflowResponse) {
              window.workflowCache[readOneWorkflowUniqueCode] = workflowResponse.data;
              this.setState({ currentItem: workflowResponse.data.items[0], workflowId: res.data.data,
                cardSessionId:workflowSessionId,cardWorkflowModel:workflowModel ,
                cardInnerWorkflowModel:workflowResponse.data.id });
            }
          }).catch(ex => {
          });
        }).catch(error => {
        });
        }
      }      
    }

    componentDidMount() {
      const { workflowCardConfig, getWorkflowFromCode, getWorkflowById } = this.props;
      const { readOneWorkflowUniqueCode } = workflowCardConfig;
      if(workflowCardConfig) {
        this.loadWorkflowFromCode(readOneWorkflowUniqueCode, getWorkflowFromCode, getWorkflowById);
      }
    }

    componentWillReceiveProps(nextProps) {
      const { workflowCardConfig, getWorkflowFromCode, getWorkflowById } = nextProps;
      const { readOneWorkflowUniqueCode } = workflowCardConfig;
      if(workflowCardConfig && !this.state.currentItem) {
        this.loadWorkflowFromCode(readOneWorkflowUniqueCode, getWorkflowFromCode, getWorkflowById);
        // getWorkflowFromCode(readOneWorkflowUniqueCode).then((res) => {
        //   res.data && getWorkflowById(res.data.data).then((workflowResponse) => {
        //     workflowResponse && this.setState({ currentItem: workflowResponse.data.items[0] });
        //   }).catch(ex => {
        //   });
        // }).catch(error => {
        // });
      }
    }
    
    render() {
        const { workflowCardConfig, workflowCards, inlineActions, workflow, noDataText,noDataTextHeading, menuItemIconPosition, menuItemIcon,
          menuTypeInlineAction, workflow: { currentItem: { typeModel: { name } } } } = this.props;
        const { currentItem, actionsDDOpen,cardSessionId,cardWorkflowModel,cardInnerWorkflowModel} = this.state;
		    const self = this;
        
        if (!workflowCardConfig) return <Alert color='warning'>Looks like the Accordian Layout has not been configured. Please try switching to the Table layout.</Alert>
        const { path, readOneWorkflowUniqueCode, backgroundImage} = workflowCardConfig;
  
		if (!workflowCards || workflowCards.length === 0) {
          return <NoDataAlertBox typeModelName={name} noDataText={noDataText} noDataTextHeading={noDataTextHeading} {...this.props} />
        }
        if(workflowCardConfig && workflowCardConfig.showInColumn) {
          return (
            <Row className="workflow-card-layout d-flex justify-content-center">
              {
                currentItem && workflowCards && workflowCards.map((workflowCard, index) => {
                  if(backgroundImage) {
                    try { 
                      currentItem.layoutProperties.bgImage =  {"path":workflowCard[backgroundImage]};
                    } catch(error) {
                      console.log(error);
                    }
                  }
                  const readOneworkflow = {
                    currentItem: JSON.parse(JSON.stringify(currentItem)),
                    workflowData: workflowCard,
                    sessionId:cardSessionId,
                    workflowModel:cardWorkflowModel,
                    innerWorkflowModel:cardInnerWorkflowModel
                  }
                  const tid = workflowCard.id;
				  const actiondd = actionsDDOpen.opendd == true && actionsDDOpen.ddindex == index ? true : false ;
				  
                  return (
                    <Col xs={12} lg={3} className="workflow-card-item" key={index}>
					  <div className="workflow-card-layout-actions d-flex justify-flex-end">
						{
							menuTypeInlineAction && menuTypeInlineAction.length > 0 && menuItemIconPosition && menuItemIconPosition.value == 'Top' ? 
              <div className='ml-1 d-inline-block'>
								<Dropdown size="sm" isOpen={actiondd} toggle={self.toggle.bind(self, 'opendd', index)}>
									<DropdownToggle color='link'>
										<i className={`fa fa-lg ${menuItemIcon ? menuItemIcon : 'fa-ellipsis-h'}`} aria-hidden="true"></i>
									</DropdownToggle>
									<DropdownMenu>
										{
											menuTypeInlineAction.map((action, actionIndex) => {
												if (action.actionProperties.hasOwnProperty('systemGenerated') &&  action.actionProperties.systemGenerated === true) return null;
												const { actionProperties } = action;
												if(actionProperties && actionProperties.actionDisabled) {
													return null;
												}
												return <DropdownItem key={actionIndex} >
													<ActionContainer key={actionIndex} workflow={workflow} action={action} tid={tid} workflowData={workflowCard} wfData={workflowCard} additionalData={this.props.workflowData?this.props.workflowData.__ADDITIONAL_MODEL_DATA:null} />
												</DropdownItem>
											})
										}
									</DropdownMenu>
								</Dropdown>
							</div>:null
						}
                      </div>
                      <WorkflowContainer
                        onHandleSubmit={workflowCardConfig ? ()=>{} : null}
                        change={workflowCardConfig ? ()=>{} : null}
                        displayHeader={false}
                        noPadding={true}
                        workflow={readOneworkflow}
                        isBase={false}
                        isInline={true}
                        inlineFormKey={'workflow-card-' + index}
                        callInlineNext={()=>{}}
                        workflowData={workflowCard}
                      />
                      <div className="workflow-card-layout-actions d-flex justify-flex-end">
                        {
                          menuTypeInlineAction && menuTypeInlineAction.length > 0 && menuItemIconPosition && menuItemIconPosition.value == 'Bottom' ? 
                          <div className='ml-1 d-inline-block'>
                            <Dropdown size="sm" isOpen={actiondd} toggle={self.toggle.bind(self, 'opendd', index)}>
                              <DropdownToggle color='link'>
                                <i className={`fa fa-lg ${menuItemIcon ? menuItemIcon : 'fa-ellipsis-h'}`} aria-hidden="true"></i>
                              </DropdownToggle>
                              <DropdownMenu>
                                {
                                  menuTypeInlineAction.map((action, actionIndex) => {
                                    if (action.actionProperties.hasOwnProperty('systemGenerated') &&  action.actionProperties.systemGenerated === true) return null;
                                    const { actionProperties } = action;
                                    if(actionProperties && actionProperties.actionDisabled) {
                                      return null;
                                    }
                                    return <DropdownItem key={actionIndex} >
                                      <ActionContainer key={actionIndex} workflow={workflow} action={action} tid={tid} workflowData={workflowCard} wfData={workflowCard} additionalData={this.props.workflowData?this.props.workflowData.__ADDITIONAL_MODEL_DATA:null} />
                                    </DropdownItem>
                                  })
                                }
                              </DropdownMenu>
                            </Dropdown>
                          </div>:null
                        }
                        {
                          currentItem && inlineActions && inlineActions.map((action, index) => {
                            if (action.actionProperties.hasOwnProperty('systemGenerated') &&  action.actionProperties.systemGenerated === true) return null;
                            const { actionProperties } = action;
                            if(actionProperties && actionProperties.actionDisabled  || actionProperties.elementType == 'MENULINK') {
                              return null;
                            }
                            return <div key={index} className='m-2'>
                              <ActionContainer key={index} workflow={workflow} action={action} tid={tid} wfData={workflowCard} workflowData={workflowCard}/>
                            </div>
                          })
                        }
                      </div>
                    </Col>
                  )
                })
              }
            </Row>
          )
        }
		if(workflowCardConfig && workflowCardConfig.oneAtATime) {
		  const workflowCard = workflowCards[self.state.activeWorkflowCardIndex];
		  const readOneworkflow = {
			currentItem,
			workflowData: workflowCard,
      sessionId:cardSessionId,
      workflowModel:cardWorkflowModel,
      innerWorkflowModel:cardInnerWorkflowModel
      

          }
          const tid = workflowCard && workflowCard.id;
		  const actiondd = actionsDDOpen.opendd == true && actionsDDOpen.ddindex == self.state.activeWorkflowCardIndex ? true : false ;
		  workflow.workflowCardData = workflowCard;
          return (
            currentItem && workflowCards && workflowCards.length> 0 && <div className="workflow-card-item " key={self.state.activeWorkflowCardIndex}>
				<div className="workflow-card-layout-actions d-flex justify-flex-end">
					{
							menuTypeInlineAction.length > 0 && menuItemIconPosition && menuItemIconPosition.value == 'Top' && <div className='ml-1 d-inline-block'>
								<Dropdown size="sm" isOpen={actiondd} toggle={self.toggle.bind(self, 'opendd', self.state.activeWorkflowCardIndex)}>
									<DropdownToggle color='link'>
										<i className={`fa fa-lg ${menuItemIcon ? menuItemIcon : 'fa-ellipsis-h'}`} aria-hidden="true"></i>
									</DropdownToggle>
									<DropdownMenu >
										{
											menuTypeInlineAction.map((action, actionIndex) => {
												if (action.actionProperties.hasOwnProperty('systemGenerated') &&  action.actionProperties.systemGenerated === true) return null;
												const { actionProperties } = action;
												if(actionProperties && actionProperties.actionDisabled) {
													return null;
												}
												return <DropdownItem key={actionIndex} >
													<ActionContainer key={actionIndex} workflow={workflow} action={action} tid={tid} wfData={workflowCard} workflowData={workflowCard}/>
												</DropdownItem>
											})
										}
									</DropdownMenu>
								</Dropdown>
							</div>
						}
					</div>
                      <WorkflowContainer
                        onHandleSubmit={workflowCardConfig ? ()=>{} : null}
                        change={workflowCardConfig ? ()=>{} : null}
                        displayHeader={false}
                        noPadding={true}
                        workflow={readOneworkflow}
                        isBase={false}
                        isInline={true}
                        inlineFormKey={'workflow-card-' + self.state.activeWorkflowCardIndex}
                        callInlineNext={()=>{}}
                        workflowData={workflowCard}
                      />
                      <div className="workflow-card-layout-actions d-flex justify-flex-end">
                      {
                          menuTypeInlineAction.length > 0 && menuItemIconPosition && menuItemIconPosition.value == 'Bottom' && <div className='ml-1 d-inline-block'>
                            <Dropdown size="sm" isOpen={actiondd} toggle={self.toggle.bind(self, 'opendd', self.state.activeWorkflowCardIndex)}>
                              <DropdownToggle color='link'>
                                <i className={`fa fa-lg ${menuItemIcon ? menuItemIcon : 'fa-ellipsis-h'}`} aria-hidden="true"></i>
                              </DropdownToggle>
                              <DropdownMenu >
                                {
                                  menuTypeInlineAction.map((action, actionIndex) => {
                                    if (action.actionProperties.hasOwnProperty('systemGenerated') &&  action.actionProperties.systemGenerated === true) return null;
                                    const { actionProperties } = action;
                                    if(actionProperties && actionProperties.actionDisabled) {
                                      return null;
                                    }
                                    return <DropdownItem key={actionIndex} >
                                      <ActionContainer key={actionIndex} workflow={workflow} action={action} tid={tid} wfData={workflowCard} workflowData={workflowCard}/>
                                    </DropdownItem>
                                  })
                                }
                              </DropdownMenu>
                            </Dropdown>
                          </div>
                        }
                        {
                          currentItem && inlineActions && inlineActions.map((action, index) => {
                            if (action.actionProperties.hasOwnProperty('systemGenerated') &&  action.actionProperties.systemGenerated === true) return null;
                            const { actionProperties } = action;
                            if(actionProperties && actionProperties.actionDisabled || actionProperties.elementType == 'MENULINK') {
                              return null;
                            }
                            return <div key={index} className='m-2'>
                              <ActionContainer key={index} workflow={workflow} action={action} tid={tid} wfData={workflowCard} workflowData={workflowCard}/>
                            </div>
                          })
                        }
                      </div>
                      <Row style={{padding:25}}>
                        <Col>
					  <div className="workflow-card-layout-actions d-flex justify-flex-start">
                        {
                          workflowCardConfig && workflowCardConfig.nextAndPrevious && self.state.activeWorkflowCardIndex > 0 && <Button
							onClick={self.previous}
							color='blue'
						  >
							<i
							  className='fa fa-arrow-left mr-1'
							  aria-hidden='true'
							></i>
							Previous
						  </Button>
                        }
                      </div>
                        </Col>
                        <Col>
                        
					  <div className="workflow-card-layout-actions d-flex justify-flex-end">
                        {
                          workflowCardConfig && workflowCardConfig.onlyNext && self.state.activeWorkflowCardIndex < workflowCards.length - 1 &&  self.state.activeWorkflowCardIndex  > -1 && <Button
                        onClick={
                        this.props.onHandleSubmit(()=>{
                          self.next()
                        })}
                        color='blue'
                        >
							<i
							  className='fa fa-arrow-right mr-1'
							  aria-hidden='true'
							></i>
							Next
						  </Button>
                        }
                        {
                          workflowCardConfig && workflowCardConfig.onlyNext && self.state.activeWorkflowCardIndex == workflowCards.length - 1 && <Button
                            onClick={
                            this.props.onHandleSubmit(()=>{
                              self.next()
                            })}
                            className="Submit-Button-WC"
                            color='blue'
                            style={{backgroundColor:"#0a3f6b",color:"#fff"}}
                            size="sm"
                            >
                            Done
                          </Button>
                        }
                      </div>
                      </Col>
                      </Row>
                    </div>
          )
        }
        return (
            <div className="workflow-card-layout">
              {
                currentItem && workflowCards && workflowCards.map((workflowCard, index) => {
                  const readOneworkflow = {
                    currentItem,
                    workflowData: workflowCard,
                    sessionId:cardSessionId,
                    workflowModel:cardWorkflowModel,
                    innerWorkflowModel:cardInnerWorkflowModel
                  }
                  const tid = workflowCard.id;
				  const actiondd = actionsDDOpen.opendd == true && actionsDDOpen.ddindex == index ? true : false ;
                  return (
                    <div className="workflow-card-item" key={index}>
					  <div className="workflow-card-layout-actions d-flex justify-flex-end">
						{
							menuTypeInlineAction.length > 0 && menuItemIconPosition && menuItemIconPosition.value == 'Top' && <div className='ml-1 d-inline-block'>
								<Dropdown size="sm" isOpen={actiondd} toggle={self.toggle.bind(self, 'opendd', index)}>
									<DropdownToggle color='link'>
										<i className={`fa fa-lg ${menuItemIcon ? menuItemIcon : 'fa-ellipsis-h'}`} aria-hidden="true"></i>
									</DropdownToggle>
									<DropdownMenu >
										{
											menuTypeInlineAction.map((action, actionIndex) => {
												if (action.actionProperties.hasOwnProperty('systemGenerated') &&  action.actionProperties.systemGenerated === true) return null;
												const { actionProperties } = action;
												if(actionProperties && actionProperties.actionDisabled) {
													return null;
												}
												return <DropdownItem key={actionIndex} >
													<ActionContainer key={actionIndex} workflow={workflow} action={action} tid={tid} wfData={workflowCard} workflowData={workflowCard}/>
												</DropdownItem>
											})
										}
									</DropdownMenu>
								</Dropdown>
							</div>
						}
                      </div>
                      <WorkflowContainer
                        onHandleSubmit={workflowCardConfig ? ()=>{} : null}
                        change={workflowCardConfig ? ()=>{} : null}
                        displayHeader={false}
                        noPadding={true}
                        workflow={readOneworkflow}
                        isBase={false}
                        isInline={true}
                        inlineFormKey={'workflow-card-' + index}
                        callInlineNext={()=>{}}
                        workflowData={workflowCard}
                        additionalModelData={this.props.additionalModelData}
                      />
                      <div className="workflow-card-layout-actions d-flex justify-flex-end">
                      {
                        menuTypeInlineAction.length > 0 && menuItemIconPosition && menuItemIconPosition.value == 'Bottom' && <div className='ml-1 d-inline-block'>
                          <Dropdown size="sm" isOpen={actiondd} toggle={self.toggle.bind(self, 'opendd', self.state.activeWorkflowCardIndex)}>
                            <DropdownToggle color='link'>
                              <i className={`fa fa-lg ${menuItemIcon ? menuItemIcon : 'fa-ellipsis-h'}`} aria-hidden="true"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                              {
                                menuTypeInlineAction.map((action, actionIndex) => {
                                  if (action.actionProperties.hasOwnProperty('systemGenerated') &&  action.actionProperties.systemGenerated === true) return null;
                                  const { actionProperties } = action;
                                  if(actionProperties && actionProperties.actionDisabled) {
                                    return null;
                                  }
                                  return <DropdownItem key={actionIndex} >
                                    <ActionContainer key={actionIndex} workflow={workflow} action={action} tid={tid} wfData={workflowCard} workflowData={workflowCard}/>
                                  </DropdownItem>
                                })
                              }
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      }
                        {
                          currentItem && inlineActions && inlineActions.map((action, index) => {
                            if (action.actionProperties.hasOwnProperty('systemGenerated') &&  action.actionProperties.systemGenerated === true) return null;
                            const { actionProperties } = action;
                            if(actionProperties && actionProperties.actionDisabled || actionProperties.elementType == 'MENULINK') {
                              return null;
                            }
                            return <div key={index} className='m-2'>
                              <ActionContainer key={index} workflow={workflow} action={action} tid={tid} wfData={workflowCard} workflowData={workflowCard}/>
                            </div>
                          })
                        }
                      </div>
                    </div>
                  )
                })
              }
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { workflow, containerIndex } = ownProps;
  const workflowItem = workflow.currentItem;
  const typeModelId = workflowItem.typeModel.id;
  const containerId = `_c${containerIndex}`;
  return {
      getWorkflowFromCode: (code) => {
          return dispatch(getWorkflowFromCode(code));
      },
      endWorkflow: (payload) => {
          return dispatch(endWorkflow(payload));
      },
      getWorkflowById: (id) => {
          return dispatch(getWorkflowById(id));
      },
	    saveInline: (payload, info) => {
          dispatch(save(payload, info, false));
      },
      next: (payload) => {
        dispatch(next(payload));
      },
      updateWorkflowCardData: (payload, workflowCardIndex) => {
        dispatch(updateWorkflowCardData(payload, workflowCardIndex, typeModelId, containerId, workflow));
      }
  }
};

const mapStateToProps = (state, ownProps) => {
  const { workflow, containerIndex } = ownProps;
  return {
	 __form__values: getFormValues('create-update-form-base')(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(WorkflowCardLayout));