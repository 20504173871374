import React, { useState, useEffect } from 'react';
import { Label } from 'reactstrap';
import { ModelPropertiesParser } from '../../commons/modelPropertiesParser';

const CardReport = props => {
  const [filters, onChangeFilters] = useState([]);
  const [data, onChangeData] = useState({});
  const {
    reportConfig,
    label,
    graphHeight,
    getReportDataByAction,
    workflow,
  } = props;
  useEffect(() => {
    if (
      reportConfig &&
      Array.isArray(reportConfig.filters) &&
      reportConfig.filters[0]
    ) {
      getReportDataByAction({
        workflowSessionId: workflow.sessionId,
        actionUniqueId: reportConfig.filters[0].actionGuid.value,
        filters,
        actionName: reportConfig.filters[0].actionGuid.label
      })
        .then(res => {
          try {
            const responseData = res.data.data[0];
              const value = ModelPropertiesParser(reportConfig.columns[0].value, responseData);
              const entry = {
                label: reportConfig.columns[0].label,
                value: parseInt(value),
              };
              onChangeData(entry);
            
          } catch (e) {
            console.log(e, 'JSON PARSE ERROR');
          }
        })
        .catch(ex => {
          onChangeData([]);
        });
    }
  }, [filters]);
  return (
    <div
      style={{ width: '100%', padding: 10 }}
      className='bg-white mt-3 rounded-lg'
    >
      {label.show && <Label>{label.text}</Label>}

      {typeof data.value != 'undefined' ? (
        <div>{data.value}</div>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img
            src='https://cn-mumbai-assets.s3.ap-south-1.amazonaws.com/public/nojobsfound-1.png'
            height={40}
            width={80}
          ></img>
        </div>
      )}
    </div>
  )
}

export default CardReport;