import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Topbar from './components/menu/Topbar';
import { ImmutableLoadingBar as LoadingBar } from 'react-redux-loading-bar';
import { ToastContainer } from 'react-toastify';
import Transition from 'react-transition-group/Transition';
import TopbarMenuItems from './components/menu/TopbarMenuItems';
import SidebarMenu from './components/menu/SidebarMenu';
import SidebarMenuCollapsed from './components/menu/SidebarMenuCollapsed';
import config from './config';

const fadeInAndOut = ({ children, position, ...props }) => (
    <Transition
        {...props}
        timeout={800}
        onEnter={node => node.classList.add('fadeIn', 'animated')}
        onExit={node => {
            node.classList.remove('fadeIn', 'animated');
            node.classList.add('fadeOut', 'animated');
        }}>
        {children}
    </Transition>
);

class PublicLayout extends Component {
    componentDidMount() {
        const { getCompanyDetails, fetchNavWorkflows,getCustomIcons, getLanguageTranslations, 
            setLanguages, addTranslationForLanguage, setActiveLanguage } = this.props;
        getCompanyDetails && getCompanyDetails();
        getCustomIcons && getCustomIcons();
        fetchNavWorkflows && fetchNavWorkflows();
        getLanguageTranslations().then(resp => {
            const languages = resp.data && resp.data.data ? resp.data.data : [];
            const langKey = Object.keys(languages)
            setLanguages(langKey);
            for (const [key, value] of Object.entries(languages)) {
               addTranslationForLanguage(value, `${key}`);
                for (const [key1, value1] of Object.entries(value)) {
                    addTranslationForLanguage(value, `${key}`);
                    
                }
            }
            setActiveLanguage('en')
      });
    }

    setActiveMenu() {
        //TODO: Set Menu Highlights
    }

    componentWillReceiveProps(nextProps) {
        var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        document.title = nextProps.companyDetails && nextProps.companyDetails.data ? nextProps.companyDetails.data.defaultWindowTitle : 'Powered by Apto.ai';
        if(nextProps.companyDetails.data && nextProps.companyDetails.data.favIcoUrl) {
            link.href = config.assetsBasePath + nextProps.companyDetails.data.favIcoUrl;
        }
    }
    
    render() {
        const { clearWorkflowState, isDasboard, user, companyDetails, getCompanyDetails ,getCustomIcons, getLanguageTranslations} = this.props;
        const showMenu = companyDetails && companyDetails.data && !companyDetails.data.hideMenuOnLanding
        const disableRightClick = this.props.companyDetails && this.props.companyDetails.data && this.props.companyDetails.data.companySettings && this.props.companyDetails.data.companySettings.disableRightClick ? this.props.companyDetails.data.companySettings.disableRightClick  : null ;


        if(companyDetails && companyDetails.data && companyDetails.data.publicMenuType === 'SIDEBAR') {
            return <div className="h-100 sidebar-workflow-nav"
            onContextMenu={(e) =>{ 
                disableRightClick &&  e.preventDefault() ;}}
                 
            >
                <SidebarMenu currentLanguage={this.props.currentLanguage}
                    translate={this.props.translate}
                    isPublic={true}
                    clearWorkflowState={clearWorkflowState}
                    baseWorkflowRoute="/open"
                    user={user}
                    getCompanyDetails={getCompanyDetails}
                    companyDetails={companyDetails} 
                    isDasboard={isDasboard} 
                    router={this.context.router} 
                    setActiveMenu={this.setActiveMenu.bind(this)
                }>
                    <div id='player-container' className='container-fluid p-0 public_layout_div'>
                        {this.props.children}
                    </div>
                    <LoadingBar className="loading-bar" />
                    <ToastContainer
                        position="bottom-right"
                        type="default"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        pauseOnHover
                        transition={fadeInAndOut}
                    />
                </SidebarMenu>
            </div>
        }
        else if(companyDetails && companyDetails.data && companyDetails.data.publicMenuType === 'COLLAPSED') {
            return <div className="h-100 sidebar-workflow-nav">
                <SidebarMenuCollapsed currentLanguage={this.props.currentLanguage}
                    translate={this.props.translate}
                    isPublic={true}
                    clearWorkflowState={clearWorkflowState}
                    baseWorkflowRoute="/open"
                    user={user}
                    getCompanyDetails={getCompanyDetails}
                    companyDetails={companyDetails} 
                    isDasboard={isDasboard} 
                    router={this.context.router} 
                    setActiveMenu={this.setActiveMenu.bind(this)
                }>
                    <div id='player-container' className='container-fluid p-0'>
                        {this.props.children}
                    </div>
                    <LoadingBar className="loading-bar" />
                    <ToastContainer
                        position="bottom-right"
                        type="default"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        pauseOnHover
                        transition={fadeInAndOut}
                    />
                </SidebarMenuCollapsed>
            </div>
        }

        return (
            <div className='h-100'>
              {
                showMenu && (<TopbarMenuItems
                currentLanguage={this.props.currentLanguage}
                translate={this.props.translate}
                isPublic={true}
                clearWorkflowState={clearWorkflowState}
                baseWorkflowRoute="/open"
                user={user}
                getCompanyDetails={getCompanyDetails}
                getCustomIcons={getCustomIcons}
                companyDetails={companyDetails}
                {...this.props}
            />)
              }
                <div id='player-container' className='container-fluid player-is-public'>
                    {this.props.children}
                </div>
                <LoadingBar className="loading-bar" />
                <ToastContainer
                    position="bottom-right"
                    type="default"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    pauseOnHover
                    transition={fadeInAndOut}
                />
                
            </div>
        );
    }
}

PublicLayout.contextTypes = {
    router: PropTypes.object
}

export default PublicLayout;