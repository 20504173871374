import React from 'react';
import { CardFooter } from 'reactstrap';
import CardInlineActions from './CardInlineActions';

const CommonFooterActions = (props) => {
    const { workflow, data, inlineActions,isImageCard, cardConfig: { config: { isFullWidth } }, editActionId, menuTypeInlineAction } = props;
    const filteredActions = inlineActions.filter((action) => action.actionProperties.systemGenerated !== true && action.actionProperties.inlineActionPlacement === 'INLINE')
   if(isImageCard){
            return (
                filteredActions.length ? 
        <CardFooter className={'imageCard-footer'}>
                <div className='imageCard-inline-actions'>
                    <CardInlineActions isFullWidth={isFullWidth} editActionId={editActionId}
                    workflow={workflow} data={data} wfData={data} actions={filteredActions} menuTypeInlineAction={menuTypeInlineAction}/>
                </div> 
        </CardFooter>: ''
)
   }
    return (
                filteredActions.length ? 
        <CardFooter>
                 <div className='card-inline-actions'>
                    <CardInlineActions isFullWidth={isFullWidth} editActionId={editActionId}
					workflow={workflow} data={data} wfData={data} actions={filteredActions} menuTypeInlineAction={menuTypeInlineAction}/>
                </div> 
        </CardFooter>: ''
    )
}

export default CommonFooterActions;