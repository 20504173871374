import React, {useState, useEffect} from 'react';
import { Button } from 'reactstrap';
import RenderIcon from '../commons/RenderIcon';
import { ModelPropertiesParser } from '../commons/modelPropertiesParser';

const _Button = (props) => {
    const {
        action: {
            actionProperties: {
                elementText,
                iconConfig,
                buttonConfig,
                displayMode,
                updateGlobalModule,
                globalModuleFieldName
            }
        },
        triggerAction,
        actionState,
        workflowData,
        customIcons,
        showAsPlaneText
    } = props;
    const [hasGlobalData, setHasGlobalData] = useState(false);
    const [onChangeState, setOnChangeState] = useState(false);
    const [disable, setOnClick] = useState(false);
    useEffect(() => {
        if(updateGlobalModule) {
          const globalModel =  localStorage.getItem(globalModuleFieldName)
            if(globalModel) {
              const parsedData = JSON.parse(globalModel);
              if(Array.isArray(parsedData)) {
                if(parsedData.length > 0) {
                  const found = parsedData.findIndex((value) => value.id === workflowData.id);
                  if(found > -1) {
                    setHasGlobalData(true);
                  } else {
                    setHasGlobalData(false);
                  }
                } else {
                  setHasGlobalData(false);
                }
              }
            } else {
              setHasGlobalData(false);
            }
        }
    }, [onChangeState])
    const _color = hasGlobalData ? 'danger' : (buttonConfig && buttonConfig.buttonStyle ? buttonConfig.buttonStyle : 'primary');
    const outline = buttonConfig && buttonConfig.isOutline ? buttonConfig.isOutline : false;
    const onlyIcon = iconConfig && iconConfig.onlyIcon;
    let btnTitle = elementText && elementText.indexOf('{{') == -1 ? elementText : ModelPropertiesParser(elementText, workflowData);
    btnTitle = btnTitle?btnTitle.replace('null', ''):btnTitle;
    if(!btnTitle || btnTitle === 'null') btnTitle = '';
    if(hasGlobalData) {
        btnTitle = "Remove"
    }
    const classNamePlaneText = buttonConfig && buttonConfig.buttonStyle === 'danger' ? 'danger-button-selected' : buttonConfig && buttonConfig.buttonStyle === 'warning' ? 'warning-button-selected' : buttonConfig && buttonConfig.buttonStyle === 'info' ? 'info-button-selected' : buttonConfig && buttonConfig.buttonStyle === 'success' ? 'success-button-selected':'primary-button-selected'
    if(showAsPlaneText){
      return (
        <div title={btnTitle} 
		disabled={disable || (actionState ? actionState.isLoading : false)} onClick={() => {
                if(displayMode != 'MODAL') {
                    
                    window && window.scrollTo({ top: 0, behavior: 'smooth' })
                }
                if(displayMode == 'BACKGROUND'){
                  setOnClick(!disable)
                }
                if(updateGlobalModule) {
                    triggerAction();
                    setTimeout(() => {
                      setOnChangeState(!onChangeState);
                    }, 2000);
                  } else {
                    triggerAction();
                  }
            }
            }   className={classNamePlaneText} >
            {
                iconConfig ? <RenderIcon customIcons={customIcons} config={hasGlobalData ? {...iconConfig, cssClass: 'fa-remove'} : iconConfig} /> : null
            }
            {
                !onlyIcon && btnTitle
            }
        </div>
    );
    }
    return (
        <Button title={btnTitle} 
		disabled={disable || (actionState ? actionState.isLoading : false)} onClick={() => {
                if(displayMode != 'MODAL') {
                    
                    window && window.scrollTo({ top: 0, behavior: 'smooth' })
                }
                if(displayMode == 'BACKGROUND'){
                  setOnClick(!disable)
                }
                if(updateGlobalModule) {
                    triggerAction();
                    setTimeout(() => {
                      setOnChangeState(!onChangeState);
                    }, 2000);
                  } else {
                    triggerAction();
                  }
            }
            } outline={outline} color={_color} size="sm">
            {
                iconConfig ? <RenderIcon customIcons={customIcons} config={hasGlobalData ? {...iconConfig, cssClass: 'fa-remove'} : iconConfig} /> : null
            }
            {
                !onlyIcon && btnTitle
            }
        </Button>
    );
}

export default _Button;