import React from 'react';
import PrintText from './PrintText';
import { Field } from 'redux-form/immutable';
import { renderSingleRadio, renderErrorMessage } from './_FORM_FIELDS';
import { Label, Row, Col } from 'reactstrap';
import { onUIAction } from '../../utils/uiActions';

const MultiChoice = (props) => {
    const { classProperty, type, validations, value, isReadOne, label, ...rest } = props;
    const uia = props.workflow && props.workflow.currentItem.layoutProperties.uiActions;
    let uiActions = null;
    if (Array.isArray(uia)) {
      uia.map((action, aIndex) => {
        if (classProperty === action.triggerActionOn) {
            uiActions = uia;
        }
      });
    }
    const { layoutItem, layoutItem: { itemProperties: { field: { properties } } } } = props;
    let { possibleValues } = properties;
    const minLength = layoutItem && layoutItem.itemProperties && layoutItem.itemProperties.minLength ? layoutItem.itemProperties.minLength : null
    if (!possibleValues.length) possibleValues = ['Option 1', 'Option 2'];
    return (
        <div>
            {
                label.show && !isReadOne ? <Label>{label.text}<sup>{properties.required || minLength ? '*' : ''}</sup></Label> : ''
            }
            {
                isReadOne ?
                    <PrintText {...props} /> :
                    <div>
                        <div>
                            {
                                possibleValues.map((_value, index) => {
                                    return <div key={index} className="form-check form-check-inline">
                                        <Field
                                            name={classProperty}
                                            value={_value}
                                            component={renderSingleRadio}
                                            type="radio"
                                            fieldType={type}
                                          
                                            onBlur={() => {
                                                const cEventKeys = window.cEvents && Object.keys(window.cEvents);
                                                Array.isArray(cEventKeys) &&     cEventKeys.map(k => {
                                                    k.startsWith(classProperty) &&
                                                    window.cEvents[k](props.formValues);
                                                });
                                                onUIAction(
                                                    props.formValues,
                                                    props.dispatch,
                                                    uiActions,
                                                    props.change
                                                  );
                                              
                                            }}
                                            _label={_value}
                                            {...rest}
                                        />
                                    </div>
                                })
                            }
                        </div>
                        <Row>
                            <Col>
                                <Field
                                    name={classProperty}
                                    component={renderErrorMessage}
                                    validate={validations}
                                />
                            </Col>
                        </Row>
                    </div>

            }
        </div>
    );
    // return (
    //     <div>
    //         {
    //             label.show ? <Label>{label.text}<sup>{properties.required ? '*' : ''}</sup></Label> : ''
    //         }
    //         {
    //             isReadOne ?
    //                 <PrintText {...props} /> :
    //                 <Field
    //                     name={classProperty}
    //                     component={renderMultiChoiceRadios}
    //                     type="radio"
    //                     validate={validations}
    //                     value={value}
    //                     fieldType={type}
    //                     possibleValues={possibleValues}
    //                     label={label}
    //                     classProperty={classProperty}
    //                     {...rest}
    //                 />

    //         }
    //     </div>
    // );
}

export default MultiChoice;