import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Col } from 'reactstrap';
import RenderIcon from '../../../commons/RenderIcon';

class SubmenuItem3 extends Component {
    render() {
        const { submenuItem: {label, icon}, onSubmenuClicked, active,customIcons } = this.props;
        return (
            <Col  className={`nav-item-3 ${active ? 'active' : ''}`} >
                <Button color='link' className={`nav-link ${active ? 'active' : ''}`} onClick={onSubmenuClicked}>
                    <span className='text'>
                        {
                            icon && icon.cssClass ? <RenderIcon config={icon} customIcons={customIcons} /> : ''
                        }
                        {label}
                    </span>
                </Button>
            </Col>
        );
    }
}

SubmenuItem3.defaultProps = {
    active: false
}

SubmenuItem3.propTypes = {
    submenuItem: PropTypes.object,
    onSubmenuClicked: PropTypes.func,
    active: PropTypes.bool
};

export default SubmenuItem3;