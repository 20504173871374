import React from 'react';
import { Jumbotron, Button } from 'reactstrap';

const NotFound = () =>
    <div className='page-not-found d-flex h-100 justify-content-center align-items-center'>
        <div>
            <Jumbotron>
                <h3 className="display-5"><i className="fa fa-warning text-info" aria-hidden="true"></i> 404 page not found</h3>
                <p className="lead">We are sorry but the page you are looking for does not exist.</p>
            </Jumbotron>
        </div>
    </div>

export default NotFound;