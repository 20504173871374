import React from 'react';
import { Alert } from 'reactstrap';
import config from '../../config';

const NoDataAlertBox = (props) => {
    const { typeModelName, noDataText,noDataTextHeading,templateType,filterNoDatamage} = props;
    const modelName = typeModelName ? typeModelName.toLowerCase() : '';
    let imageUrlComplete = null;
    if(filterNoDatamage){
        
        imageUrlComplete = filterNoDatamage?`${config.assetsBasePath}${filterNoDatamage.path}`:null;
    }
    if(templateType && templateType.value === 'Template2'){
        return <Alert color='light' className='no-data-alert-2'>

             <img src={imageUrlComplete}  alt="companyLogo" />
                <h6 className='noDateHeadingText' style={{color:'#000',marginTop:10,fontWeight:'bold'}}> {noDataTextHeading? noDataTextHeading:`No ${typeModelName}`}!</h6>
                <p className='noDateSubHeadingText' style={{marginTop:10}} >{noDataText ? noDataText : `We weren't able to find any entries for ${modelName}`}</p>

    </Alert>
    }
    return <Alert color='light' className='no-data-alert'>
        <div className='d-flex justify-content-center'>
            <div>
                <h5><i className="fa fa-exclamation-triangle mr-1" aria-hidden="true"></i> {noDataTextHeading? noDataTextHeading:`No ${typeModelName}`}!</h5>
                <p>{noDataText ? noDataText : `We weren't able to find any entries for ${modelName}`}</p>
            </div>
        </div>
    </Alert>
}

export default NoDataAlertBox;