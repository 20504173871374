import React from 'react';
import { FormGroup, Input, Label, FormFeedback, Button } from 'reactstrap';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import client from '../../../client';
import config from '../../../config';

class OTPAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: "",
      otp: "",
      showOTPInput: false,
      invalidOtp: false 
    }
  }

  onClickGenerateOTP = () => {
    const { phoneNumber } = this.state;
    const payload = {
      phoneNumber
    }
    this.setState({ showOTPInput: true }, () => {
      client().post(`${config.publicAPI}/generateotp`, payload).then((res) => {
      }).catch((error) => {
        this.setState({ showOTPInput: false })
      });
    });
  }

  verifyOTP = () => {
    const { otp, phoneNumber } = this.state;
    const {authenticationCallback, requiredRole} = this.props;
	const payload = {
      isSessionRequired: true,
      otp,
      phoneNumber,
	  requiredRole
    }
    //TODO: Pass the roleToAssign in the payload above
    client().post(`${config.publicAPI}/verifyotp`, payload).then((res) => {
      const {authenticationCallback} = this.props;
      console.log(res.data.latestToken)
	  let user = localStorage.getItem('user');
	  user = typeof user === 'string' && JSON.parse(user);
	  const newUser = {
        token: res.data.latestToken,
        ...res.data.data
      }

      if(res.data.hasError) {
        this.setState({ invalidOtp: true});  
      } else {
        if(user.token != res.data.latestToken) {
          // localStorage.setItem('user', JSON.stringify(newUser));
          window.user = newUser;
        }
	      
	  	  authenticationCallback();
	      //} else {
		      //window.location.href = '/app';
        //}
      }
    }).catch((error) => {
      this.setState({ invalidOtp: true });
    });
  }

  render () {
    const { showOTPInput, phoneNumber, otp } = this.state;
    const { label, hasRefCode } = this.props;
    return (
      <div>
        <FormGroup>
          <Label for={'phone'}>Phone</Label>
          <ReactPhoneInput 
            country={'in'}
            countryCodeEditable={false}
            onChange={(value) => this.setState({ phoneNumber: value })}
            placeholder={'Enter your phone number'}
            inputStyle={{
              width: '100%'
            }}
            value={phoneNumber}
            disabled={showOTPInput}
          />
        </FormGroup>
        {
          showOTPInput && (
            <FormGroup>
              <Label for={'password'}>One-Time-Password</Label>
              <Input 
                value={otp}
                type='text'
                onChange={(event) => this.setState({ otp: event.target.value })}
                placeholder={'Enter password'}
              />
            </FormGroup>
          )
        }
        {
                this.state.invalidOtp && (
                  <div style={{color:"#f00"}} >
                    Invalid OTP
                  </div>
               )
              }
		{
			hasRefCode && !showOTPInput ?
			<FormGroup>
			  <Label for={'refcode'}>Referral Code (Optional)</Label>
			  <Input 
				value={this.state.refcode}
				onChange={(event) => this.setState({ refcode: event.target.value })}
				placeholder={'Enter Referral Code'}
				type={'text'}
			  />
			</FormGroup>
			:null
		}
        <div className="d-flex justify-content-end">
          <Button
            size="sm"
            className="pr-2 pl-2"
            color="primary"
            onClick={showOTPInput ? this.verifyOTP : this.onClickGenerateOTP}
            disabled={phoneNumber.length < 10}
          >
            {
              showOTPInput && <i className="fa fa-lock pr-2" aria-hidden="true"></i>
            }
            { 
              (label.show && !showOTPInput) ? label.text : 'Next'
            }
          </Button>
        </div>
      </div>
    )
  }
}


export default OTPAuth;