import React, { useState } from 'react';
import { PieChartOutlined } from '@ant-design/icons';
import { Divider, Form, Radio, Skeleton, Space, Switch } from 'antd';
const LoadingPieChart = () => {
  const [active, setActive] = useState(true);
  const [block, setBlock] = useState(false);
  const [size, setSize] = useState('large');
  const [buttonShape, setButtonShape] = useState('default');
  const [avatarShape, setAvatarShape] = useState('circle');
  const handleActiveChange = (checked) => {
    setActive(checked);
  };
  const handleBlockChange = (checked) => {
    setBlock(checked);
  };
  const handleSizeChange = (e) => {
    setSize(e.target.value);
  };
  const handleShapeButton = (e) => {
    setButtonShape(e.target.value);
  };
  const handleAvatarShape = (e) => {
    setAvatarShape(e.target.value);
  };
  return (
    <>
      <Space style={{display:'flex',justifyContent:'center',padding:25}}>
      <Skeleton.Node active={active} style={{ padding:25,width:200,height:200}}>
          <PieChartOutlined
            style={{
              fontSize: 160,
              color: '#bfbfbf',
            }}
          />
        </Skeleton.Node>
      </Space>
      <br/>
      <Divider />
      <Form
        layout="inline"
        style={{
          margin: '16px 0',
        }}
      >
      </Form>
    </>
  );
};
export default LoadingPieChart;