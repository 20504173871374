import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FieldFilter from './FieldFilter';
import { Button, Row, Input, InputGroup, InputGroupAddon } from 'reactstrap';

class Filters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            hideFilter:props.fetchAfterFilter ? false : true
        }
    }
    onChangeSearchBox = (e) => {
        const { onFilterChange, filter } = this.props;
        const value = e.target.value;
        this.setState({ value }, () => {
            const filterConfig = {
                filter: {
                    targetClassProperty: "*",
                    isSearch: true
                }
            }
            onFilterChange(filterConfig, [value]);
        })
    }
    onEnter = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const { onFiltersApplied } = this.props;
            onFiltersApplied();
        }
    }

    toggleFilterUIBOx = ()=> {
        this.setState({hideFilter:!this.state.hideFilter})
      }
    render() {
        const { onFiltersApplied, onClearAppliedFilters, filters, allFilters } = this.props;
        const { hideFilter} = this.state;
        //if (!workflow) return null;
        //const { currentItem: { layoutProperties: { readAllFilterConfig }, typeModel: { fields } } } = workflow;
        //const filters = readAllFilterConfig ? readAllFilterConfig.filterConfig.filters : [];
        if (!filters || !filters.length) return null;
        if(allFilters) {
            return (
                <div className='mb-3 mt-3'>
                    <InputGroup>
                        <Input
                            type="search"
                            name="search"
                            value={this.state.value}
                            onChange={this.onChangeSearchBox}
                            onKeyPress={this.onEnter}
                            id="allFiltersSearch"
                            placeholder="Search"
                        />
                        <InputGroupAddon addonType="prepend"><Button onClick={onFiltersApplied} size="sm">SEARCH</Button></InputGroupAddon>
                    </InputGroup>
                    
                </div>
            )
        }
        return (
            <div className='read-all-filter' style={{marginTop:-20, marginRight: 40}}>
                <div className='d-flex justify-content-end'>
        <Button className="show-hide-button" target='_blank' onClick={this.toggleFilterUIBOx.bind(this)} color="link" size="sm">
            {hideFilter ? "Show Filter": "Hide Filter"}
        </Button>
        </div>
          { !hideFilter &&     <div className='top_most_filters'>
                <div className='mb-1'> </div>
                <Row className='filters-wrapper mb-1'>
                    {
                        filters.map((filter, index) => {
                            if (typeof filter.isActive === 'boolean' && !filter.isActive) return null;
                            return <FieldFilter filterIndex={index} filter={filter} key={index} {...this.props} />
                        })
                    }
                </Row>
                <div className='d-flex justify-content-end filterButtons'>
					<Button onClick={onFiltersApplied} size='sm' color='primary'>Apply Filters</Button>
                    <Button onClick={onClearAppliedFilters} size='sm' color='link'><i className="fa fa-undo mr-1" aria-hidden="true"></i>Clear</Button>
                </div>
                </div>
           }
            </div>
        );
    }
}

Filters.propTypes = {
    workflow: PropTypes.object
};

export default Filters;