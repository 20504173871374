import React from 'react';
import { FormGroup, Button, Label } from 'reactstrap';
import ActionContainer from '../../containers/workflow/ActionContainer'

const ActionButton = (props) => {
    const { label,
        placeholder,
        classProperty,
        isReadOne,
        value,
        onLayoutItemChanged,
        layoutItem: {itemProperties: {generatedActionCodeForAllowAddition}},
        workflowData
    } = props;
    const actions = props.workflow.currentItem.inlineAction;
    let action = null;
    actions.forEach(element => {
        if(element.actionProperties.generatedActionCode === generatedActionCodeForAllowAddition)
            action = element;
    });
    if(!action) return null;
    const tid = workflowData && workflowData.id;
    return (
        <ActionContainer action={action} tid={tid} {...props} >
        </ActionContainer>
    );
}

export default ActionButton;