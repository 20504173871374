import React, { Component } from 'react';
import { Row, Col, Button, Form, FormGroup, Label, Input, InputGroup, InputGroupAddon, Alert } from 'reactstrap';
import { Field, reduxForm, formValueSelector } from 'redux-form/immutable';
import { Redirect, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import isEmail from 'validator/lib/isEmail';
import LoginWorkflow from './LoginWorkflow';
import config from '../../../config';

class Login extends Component {
    componentDidMount() {
        const { getCompanyDetails,getCustomIcons } = this.props;
        getCompanyDetails && getCompanyDetails();
        getCustomIcons && getCustomIcons();
    }
    componentWillReceiveProps(nextProps) {
        var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        document.title = nextProps.companyDetails && nextProps.companyDetails.data ? nextProps.companyDetails.data.defaultWindowTitle : 'Powered by Apto.ai';
        if(nextProps.companyDetails.data && nextProps.companyDetails.data.favIcoUrl) {
            link.href = config.assetsBasePath + nextProps.companyDetails.data.favIcoUrl;
        }
    }
    renderField({ input, label, type, placeholder, meta: { touched, error }, autoComplete }) {
        return <FormGroup>
            <Label for={input.name}>{label}</Label>
            <Input placeholder={placeholder} type={type} autoComplete={autoComplete} { ...input } />
            {touched && error && <small className='text-danger form-text mt-2 animated fadeIn'><i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {error}</small>}
        </FormGroup>
    }
    render() {
        const { handleSubmit, user, companyDetails } = this.props;
        const { router: { history } } = this.context;
        if (!user.isAnonymous && user.user !== null && !user.user.hasError && user.user.token !== null && user.user.email) {
            history.push('/app');
        }
        if(companyDetails.isLoading) {
            return ''
        }
        if(companyDetails && companyDetails.data && companyDetails.data.companySettings && companyDetails.data.companySettings.disableSignInPage) {
          history.push('/');
        } else if (companyDetails && companyDetails.data && companyDetails.data.loginWorkflowCode) {
            return <LoginWorkflow  workflowCode={companyDetails.data.loginWorkflowCode} login={handleSubmit} {...this.props}/>
        }
        return (
            <Row className='h-100 d-flex align-items-center justify-content-center public-user-page'>
                <Col xs="12" sm="10" md="4">
                    <h4 className='brand-name player'>{document.location.href.indexOf('screenermonkey')==-1?"Apto":"Screener"}<span className='ats'>{document.location.href.indexOf('screenermonkey')==-1?".ai":"Monkey"}</span></h4>
                    <Form onSubmit={handleSubmit} className='player-public-user-form '>
                        <Field
                            name="email"
                            component={this.renderField}
                            type="text"
                            label='Company Email'
                            placeholder='John.Doe@huwats.com'
                            autoComplete="email"
                        />
                        <Field
                            name="password"
                            component={this.renderField}
                            type="password"
                            label='Password'
                            placeholder='Enter your password'
                            autoComplete="password"
                        />
                        {
                          (user.user && user.user.hasError) ? <Alert color="danger">
                            Not able to login. Please check your credentials!
                          </Alert> : null
                        }
                        <Row className='justify-content-center mt-4'>
                            <Col xs="12" sm="10" md="12">
                                <div className='d-flex flex-column'>
                                    <Button type='submit' color="primary" block className='login-btn'>
                                        <i style={{ marginRight: 5 }} className="fa fa-lock" aria-hidden="true"></i>
                                        Login
                                    </Button>
                                    <div className='alt-container-login'>
                                        <Link to='/app/forgotpassword'>
                                            Forgot password?
                                        </Link>
                                        {/*<p className='m-0'>Don't have an account? <Link to='/app/signup'>Sign up now</Link></p>*/}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                    <p className='text-right mt-2 slogan'>#ZeroCode</p>
                </Col>
            </Row>

        );
    }
}

Login.contextTypes = {
    router: PropTypes.object
}

const validate = (values) => {
    const errors = {};
    const email = values.get('email');
    const password = values.get('password');
    if (!email || !isEmail(email)) {
        errors.email = 'Please provide a valid email address';
    }
    if (!password) {
        errors.password = 'Please enter your password'
    }
    return errors;
}

export default reduxForm({
    form: 'player-login-form',
    validate
})(Login)
