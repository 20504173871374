import React from 'react';
import NavWorkflowsContainer from '../../containers/NavWorkflowsContainer';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import DesktopWorkflowTopBarWithSideBar from './DesktopWorkflowTopBarWithSideBar';

class DesktopWorkflowTopBarMenuItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: -1
    }
  }

  componentDidMount() {
    this.props.fetchWorkflows(this.props.user);
  }

  resolveIsActive = (match, location) => {
    if (!match || !location) {
        return false
    }
    const { path } = match;
    const { pathname } = location;
    if (pathname === path) {
        return true;
    }
    return false;
  }

  setActiveTab = (tabIndex) => {
    this.setState({ activeTab: tabIndex });
  }
  render() {
    const { router } = this.context;
    const { isActive, isPublic, baseWorkflowRoute, isScreenerMonkeyApp, items,companySettings,sideBarWithTopBar } = this.props;
    const sideMenuConfig =  companySettings ? companySettings : null
    const onlySignOut = sideMenuConfig && sideMenuConfig.sideMenuDefaultLogout ? sideMenuConfig.sideMenuDefaultLogout : null 
    if(sideBarWithTopBar){
      return <DesktopWorkflowTopBarWithSideBar {...this.props}/>
    }
    return (
	<div className={`desktop-menu-items ${isPublic ? 'hide-menu' : ''}`}>
        <ul className="desktop-level-one-menu">
		  <li className="desktop-level-one-menu-item home" key={-1}>
			  <NavLink className={`desktop-level-one-menu-item-link ${isScreenerMonkeyApp ? 'screener-monkey' : ''}`} to={isPublic ? '/' : '/app'}>
          <span>
            <i className='fa fa-home'></i>
          </span>
			  </NavLink>
		  </li>	  
          {
            items && (
              Array.isArray(items) && items.map((parentItem, parentIndex) => {
                let parentItemProperties = parentItem && parentItem.properties ? parentItem.properties : null
                if (parentItemProperties !== null) {
                  var obj = JSON.parse(parentItemProperties);
                  var parentItemIconObject = obj
                }
                const mobileOnly = parentItemIconObject && parentItemIconObject.displayMode ? parentItemIconObject.displayMode : null
                if(parentItem.hideInMenu) return null;
                if (mobileOnly && mobileOnly.value === 2) return null;
                const hasChildren = parentItem.childMenus && parentItem.childMenus.length > 0;
                const routerBase = router.route.match.path;
                const parentPath = `${baseWorkflowRoute}/${parentItem.friendlyUrl.toLowerCase()}`;
                const { title } = parentItem;
                return  hasChildren ? (
                          <li key={parentIndex} className={`desktop-level-one-menu-item wf-topbar-only ${this.state.activeTab === parentIndex ? 'active' : ''}`} onMouseOver={() =>this.setActiveTab(parentIndex)}>
                            <a className={`desktop-level-one-menu-item-link ${isScreenerMonkeyApp ? 'screener-monkey' : ''}`}>
                              {parentItem.title}
                              <span className="right-icon-container">
                                <i className='fa fa-chevron-down'></i>
                              </span>
                            </a>
                            <DesktopLevelTwoMenu>
                              <ul className="desktop-level-two-menu-items">
                                {
                                  parentItem.childMenus.map((child, childIndex) => {
                                    if (child.hideInMenu) return null;
                                    const path = `${baseWorkflowRoute}/${child.friendlyUrl.toLowerCase()}`;
                                    const childPath = `${path}`;
                                    return <li className="desktop-level-two-menu-item" key={childIndex}>
                                      <NavLink className="desktop-level-two-menu-item-link" to={childPath} >
                                        {child.title}
                                        <span className="right-icon-link">
                                          <i className='fa fa-arrow-circle-o-right'></i>
                                        </span>
                                      </NavLink>
                                    </li>
                                  })
                                }
                              </ul>
                            </DesktopLevelTwoMenu>
                          </li>
                      ) : <li className={`desktop-level-one-menu-item ${this.state.activeTab === parentIndex ? 'active' : ''}`} key={parentIndex} onClick={() =>this.setActiveTab(parentIndex)} >
                          <NavLink className={`desktop-level-one-menu-item-link ${isScreenerMonkeyApp ? 'screener-monkey' : ''}`} to={parentPath}>
							<i className={`custom-icon-class fa ${parentItem.menuIcon?parentItem.menuIcon:''}`}></i>
                            <span>{parentItem.title}</span>
                          </NavLink>
                    </li>
                    }
                )
            )
          }
          {
            isPublic ? <li className="desktop-level-one-menu-item signin">
              <NavLink className={`desktop-level-one-menu-item-link ${isScreenerMonkeyApp ? 'screener-monkey' : ''}`} to="/app/login">
                <span className="left-icon-container">
                  <i className='fa fa-sign-out'></i>
                </span>
                Sign-In
              </NavLink>
            </li> : 
            ( onlySignOut === "onlySignOut"  ?
            <div style={{display: 'inline-block'}}>
            <li className={`desktop-level-one-menu-item wf-topbar-only ${this.state.activeTab === items ? items.length + 1 : 2 ? 'active' : ''}`} onMouseOver={() =>this.setActiveTab(items ? items.length + 1 : 2)}>
            <a className="desktop-level-one-menu-item-link" href="#" onClick={() => this.props.logout()} >
                        SignOut
                        <span className="right-icon-container">
                          <i className='fa fa-sign-out'></i>
                        </span>
                      </a>
                </li>
                </div>
            :
            <div style={{display: 'inline-block'}}>
              <li className={`desktop-level-one-menu-item wf-topbar-only ${this.state.activeTab === items ? items.length + 1 : 2 ? 'active' : ''}`} onMouseOver={() =>this.setActiveTab(items ? items.length + 1 : 2)}>
                <a className={`desktop-level-one-menu-item-link ${isScreenerMonkeyApp ? 'screener-monkey' : ''}`}>
                  <span className="left-icon-container">
                    <i className='fa fa-user-md'></i>
                  </span>
                  Account
                  <span className="right-icon-container">
                    <i className='fa fa-chevron-down'></i>
                  </span>
                </a>
                <DesktopLevelTwoMenu>
                  <ul className="desktop-level-two-menu-items">
                    <li className="desktop-level-two-menu-item">
                      <NavLink className="desktop-level-two-menu-item-link" to="/profile" >
                        Profile
                        <span className="right-icon-container">
                          <i className='fa fa-user'></i>
                        </span>
                      </NavLink>
                    </li>
                    <li className="desktop-level-two-menu-item">
                      <a className="desktop-level-two-menu-item-link" href="#" onClick={() => this.props.logout()} >
                        SignOut
                        <span className="left-icon-container">
                          <i className='fa fa-sign-out'></i>
                        </span>
                      </a>
                    </li>
                  </ul>
                </DesktopLevelTwoMenu>
              </li>
              </div>
            )
          }
        </ul>
      </div>
    )
  }
}

const DesktopLevelTwoMenu = (props) => {
  return <div className="desktop-level-two-menu wf-topbar-only">
    {props.children}
  </div>
}

DesktopWorkflowTopBarMenuItems.contextTypes = {
  router: PropTypes.object
}

export default DesktopWorkflowTopBarMenuItems;