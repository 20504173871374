import { combineReducers } from 'redux-immutable';
import { reducer as form } from 'redux-form/immutable';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';
import { localeReducer as locale } from 'react-localize-redux';
import navigation from './navigation';
import navigationPublic from './navigationPublic';
import workflow from './workflow';
import save from './save';
import user from './user';
import assignValueModal from './assign-value-modal';
import uploader from './uploader';
import reports from './reports';
import bulkActions from './bulk-actions';
import breadcrumbs from './breadcrumbs';
import workflowActions from './workflow-actions';
import next from './next';
import company from './company';
import staticData from './static-data';
import calendarEvents from './calendar-event';
import uiFilters from './uiFilters';

export default combineReducers({
    form,
    loadingBar,
    locale,
    navigation,
    navigationPublic,
    workflow,
    save,
    next,
    user,
    assignValueModal,
    uploader,
    reports,
    bulkActions,
    breadcrumbs,
    workflowActions,
    company,
    staticData,
    calendarEvents,
    uiFilters
});