import React, { Component } from 'react';
import PropTypes, { checkPropTypes } from 'prop-types';
import { Cell } from 'fixed-data-table-2';
import { FormGroup, Label, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { toJS } from '../../../../../containers/to-js';
import { pushTid, popTid } from '../../../../../actions/workflow-actions'; 

class RowSelectorCell extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: null,
            tid: null
        }
    }
    componentWillReceiveProps(nextProps) {
        const { rows, columnKey, rowIndex, selectAll, readOnlyTableWithSelection, changeVal, classProperty, 
            formValues, hasModifiedClassProperty, getNewContext } = nextProps;
        const row = rows.getObjectAt(rowIndex);
        if(readOnlyTableWithSelection)
        {
            let val = hasModifiedClassProperty ? getNewContext(formValues, classProperty) : formValues ? formValues[classProperty] : [];
            val = val ? val : []
            if(selectAll === true){
                const f = val.find(v=> v.id == row.id)
                if(!f){
                    val.push(row)
                }
                
                changeVal(classProperty, val);
                
            } else if(selectAll === false) {
                val.map((v,i) => {
                    if(v.id == row.id)
                        val.splice(i, 1);
                });
                
                changeVal(classProperty, val)
            }
            this.setState({tid: null})
        }
    }
    onChange(tid,row, ev) {
        const checked = ev.target.checked;
        const { pushTid, popTid, readOnlyTableWithSelection, changeVal, classProperty, 
            formValues, hasModifiedClassProperty, getNewContext, selectAllNotClicked } = this.props;
        if(readOnlyTableWithSelection)
        {
            let val = hasModifiedClassProperty ? getNewContext(formValues, classProperty) : formValues ? formValues[classProperty] : [];
            val = val ? val : []
            if(checked){
                val.push(row)
                changeVal(classProperty, val);
                
            } else {
                let index = -1;
                val.map((v,i) => {
                    if(v.id == row.id)
                        val.splice(i, 1);
                });
                
                changeVal(classProperty, val)
            }
            this.setState({checked: checked, tid})
        } else {
            checked ? pushTid(tid) : popTid(tid);
        }
    }
    render() {
        const { rows, columnKey, rowIndex } = this.props;
        if (!rows || !rows.getSize()) return null;
        const tid = rows.getObjectAt(rowIndex)[columnKey];
        const row = rows.getObjectAt(rowIndex);
        const checked = this.props.selectAll && this.state.checked == null ? this.props.selectAll : 
            (this.props.selectAll || !this.props.selectAll) && this.state.checked != null && this.state.tid == tid
             ?  this.state.checked : this.props.selectAll;
        return (
            <Label check className='w-100 h-100 d-flex justify-content-center align-items-center'>
                <Input onChange={this.onChange.bind(this, tid, row)} 
                    style={{ position: 'relative', width: 35, height: 35, marginLeft: -7, marginTop: 0 }} 
                    type="checkbox" checked={checked}/>
            </Label>
        );
    }
}

RowSelectorCell.defaultProps = {
    rows: []
}

RowSelectorCell.propTypes = {
    rows: PropTypes.array,
    columnKey: PropTypes.string,
    rowIndex: PropTypes.number
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        pushTid: (tid) => {
            dispatch(pushTid(tid));
        },
        popTid: (tid) => {
            dispatch(popTid(tid));
        }
    }
};

export default connect(null, mapDispatchToProps)(toJS(RowSelectorCell));