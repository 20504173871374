import { showLoading, hideLoading } from 'react-redux-loading-bar'
import client from '../client';
import config from '../config';

export const UPLOAD_FILES = 'UPLOAD_FILES';
export const UPLOAD_FILES_SUCCESS = 'UPLOAD_FILES_SUCCESS';
export const UPLOAD_FILES_FAILURE = 'UPLOAD_FILES_FAILURE';

export const CLEAR_UPLOADER = 'CLEAR_UPLOADER';

export const uploadFiles = (payload) => {
    return (dispatch) => {
        const formData = new FormData();
        const files = payload.files;
        for (let i = 0; i < files.length; i += 1) {
            formData.append('files', files[i]);
        }
        dispatch({
            type: UPLOAD_FILES,
            payload
        });
        dispatch(showLoading());
        const options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        const uploadUrl = payload.isProtected ? `${config.uploaderAPI}?isProtected=true` : config.uploaderAPI;
        client(options).post(uploadUrl, formData).then((res) => {
            const files = res.data.data;
            payload.isProtected  && Array.isArray(files) && files.map((f, i) => {
                files[i] = `protected/${f}`
            });

            dispatch({
                type: UPLOAD_FILES_SUCCESS,
                payload: {
                    data: files,
                    namespace: payload.namespace
                }
            });
            dispatch(hideLoading());
        }).catch((error) => {
            dispatch({
                type: UPLOAD_FILES_FAILURE,
                payload: {
                    hasError: true,
                    namespace: payload.namespace,
                    error
                }
            });
            dispatch(hideLoading());
        });
    }
}

export const clearUploader = (namespace) => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_UPLOADER,
      payload: namespace
  });
  }
}

export const base64Upload = (payload) => {
    return (dispatch) => {
        const uploadUrl = config.assetsBasePath.endsWith('/') ? `${config.playerApi}/upload/base64` : `${config.assetsBasePath}/api/player/upload/base64`
        return client().post(uploadUrl, payload);
    }
}

export const buildPdf = (payload) => {
    return (dispatch) => {
        return client().post(`${config.authAPI}/pdf/build`, payload)
    }
}