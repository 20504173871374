import React, { Component } from 'react';
import { FormGroup, Label, Row, Col, Input } from 'reactstrap';
import { Field } from 'redux-form/immutable';
import { renderSelect, genericNormalizer, renderMultipleCheckBox } from './_FORM_FIELDS';
import { connect } from 'react-redux';
import { toJS } from '../../containers/to-js';
import { readAll } from '../../actions/workflow-item';
import PrintText from './PrintText';
import { ModelPropertiesParser, getNewContext, ModelPropertiesParserArray } from '../commons/modelPropertiesParser';
import { TypeModelDisplays } from '../../dto/type-model-displays';
import Table from './Table/Tablev1';
import { getModelByNamePromise } from '../../actions/workflows';
import TypeModelCreateUpdateTable from './TypeModelHelpers/TypeModelCreateUpdateTable';
import TypeModelBadges from './TypeModelHelpers/TypeModelBadges';
import RenderIcon from '../commons/RenderIcon';
import { Iterable } from 'immutable';
import resolvePath from '../../utils/resolvePath';
import TypeModelCreateUpdateWorkflowCard from './TypeModelHelpers/TypeModelCreateUpdateWorkflowCard';
import JSONPath from 'jsonpath';
import { filter } from 'lodash';
import {
  TABLE
} from './index';
import DataListStore from '../workflow/operations/filters/DataListStore';
import Pagination from '../commons/pagination';

class TypeModelCreateUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            model: null,
            isInitialValueSet: false,
            callCounter: 0,
            filter: []
        }
    }
    componentDidMount() {
        const { getModelByNamePromise,isLayoutItemEditable, customerName, workflow, containerIndex, isBase,
          layoutItem: { itemProperties: { readOnlyTableWithSelection,readOnlyTable,loadFilteredDataFromServer, parentModelSelector,field: { classProperty, customModelName } } } } = this.props;
          if(!loadFilteredDataFromServer )
          {
            this.setState({callCounter: 1}, () => {
              if((!parentModelSelector ||isLayoutItemEditable)) {
                getModelByNamePromise({ name: customModelName }).then((res) => {
                    const model = res.data;
                    const typeId = model.id;
                        this.props.readAll({
                          customModelName: customModelName,
                            typeId,
                            customerName,
                            workflow: {
                              id: workflow.id,
                              displayMode: workflow.displayMode
                            },
                            isBase,
                            "count":(readOnlyTableWithSelection||readOnlyTable) ? this.props.pageSize : 10000,
                            "start":(readOnlyTableWithSelection||readOnlyTable) ? this.props.paginationStart:0
                        }, containerIndex);
                    this.setState({ model, callCounter: 0 });
                })
              }
            })
          }
        
    }

    componentWillReceiveProps(nextProps) {
      const { getModelByNamePromise, customerName, workflow, containerIndex, isBase, workflowData, columnIndex,
        layoutItem: { itemProperties: {readOnlyTableWithSelection,readOnlyTable, loadFilteredDataFromServer,filterConfig, dontAllowAdditionDeletion, parentModelSelector, field: { classProperty, customModelName } } } } = nextProps;
      // if(nextProps.workflow && this.props.workflow && nextProps.workflow.currentStep != this.props.workflow.currentStep || !nextProps.typeModelCollection) {
       
      // }
      let data = this.props.formValues ? nextProps.formValues : workflowData;
      let filters = [];
      filterConfig && filterConfig.filters && filterConfig.filters.map(f => {
        const field = f.filterField && f.filterField.replace('{{','').replace('}}','');
        const value = ModelPropertiesParser(f.filterValueField,data);
        value && filters.push({filterField: field,filterValue: [value], valueType: f.leafType})
      })
      let filterChange = false;
      const stateFilter = this.state.filter;
      if(stateFilter.length == 0) {
        filterChange = true;
      } else if(filters.length != stateFilter.length){
        filterChange = true;
      } else {
        filters.map((item,i) =>{
          if(item.filterValue[0] != stateFilter[i].filterValue[0]){
            filterChange = true;
            return;
          }
        }
        );
      }
      if((filterChange && filters.length > 0 && this.state.callCounter === 0 && loadFilteredDataFromServer) || 
        nextProps.pageSize != this.props.pageSize || nextProps.paginationStart != this.props.paginationStart) {
        this.setState({callCounter: 1}, () => {
          getModelByNamePromise({ name: customModelName }).then((res) => {
            const model = res.data;
            const typeId = model.id;
            this.props.readAll({
              customModelName: customModelName,
                typeId,
                customerName,
                filters,
                workflow: {
                  id: workflow.id,
                  displayMode: workflow.displayMode
                },
                isBase,
                "count":(readOnlyTableWithSelection||readOnlyTable) ? nextProps.pageSize : 10000,
                "start":(readOnlyTableWithSelection||readOnlyTable) ? nextProps.paginationStart:0
            }, containerIndex, columnIndex);
            this.setState({ model, callCounter: 0, filter: filters});
            });
        })
          
      }
    }

    setInitialValue = (initialValues, classProperty, modelSelector, change,hasModifiedClassProperty, replaceClassProperty) => {
      if(!this.state.isInitialValueSet) {
        const actualValue = resolvePath(hasModifiedClassProperty ? replaceClassProperty : classProperty, initialValues);
        if(Array.isArray(actualValue) && actualValue) {
          const values = actualValue;
          const initValues = initialValues[hasModifiedClassProperty ? replaceClassProperty : classProperty];
          initValues  && initValues.map((item, itemIndex) => {
            if(!item.label) {
              values[itemIndex] = {
                label: ModelPropertiesParser(modelSelector, item),
                value: item.id,
                ...item
              }
            } else {
              values[itemIndex] = item;
            }
          })
          this.setState({
            isInitialValueSet: true
          }, () => {
            change(hasModifiedClassProperty ? replaceClassProperty : classProperty, values);
          })
        } else if(actualValue) {
          
          const value = {
            value: actualValue.id,
            label: ModelPropertiesParser(modelSelector, actualValue),
            ...actualValue
          }
          this.setState({
            isInitialValueSet: true
          }, () => {
            try {
              change(hasModifiedClassProperty ? replaceClassProperty : classProperty, value);
            }
            catch(ex) {
              console.log('ex..',ex);
            }
          })

        }
      }
      
    }

    render() {
        const { layoutItem, initialValues,isLayoutItemEditable, validations, readAll, isEditing, customerName, change, value, isReadOne, workflowData,customIcons, ...rest } = this.props;
        let { typeModelCollection, itemClassPropertyFromTable } = this.props;
        const { itemProperties } = layoutItem;
        const showAsRadioButton = itemProperties && itemProperties.showAsRadioButton
        const optionDisplayType = itemProperties && itemProperties.optionDisplayType ? itemProperties.optionDisplayType.value : null;
        let { modelSelector, typeModelViewMode, badgeType, placeholder, label, tableConfig, readOnlyTableWithSelection,readOnlyTable,
          field: { collection, classProperty, properties }, replaceClassProperty } = itemProperties
        let TypeCollectionData = null;
        if(itemProperties.parentModelSelector && !isLayoutItemEditable) {
            let data = this.props.formValues?this.props.formValues:workflowData;
            if(!data) {
                data = workflowData?workflowData:this.props.formValues;
                if(data == null)
                    return null;
            }
            const wData = ModelPropertiesParser(itemProperties.parentModelSelector,data);

            if(wData)
              TypeCollectionData = { data: JSON.parse(wData)};
        }  else {
            const { model } = this.state;
            
            if(!typeModelCollection && model && workflowData && workflowData[classProperty]) {
              console.log( typeModelCollection, model.name, workflowData[classProperty])
              const typeId = model.id;
              typeModelCollection = {};
              typeModelCollection[model.name] = workflowData[classProperty]
            }
            if (!layoutItem || !model || !typeModelCollection) return null;
            
            const typeId = model.id;
            TypeCollectionData = typeModelCollection;
        }
        if(!TypeCollectionData) return null;
        if (TypeCollectionData && TypeCollectionData.isLoading === true) return <p className='text-muted'>Loading...</p>;
        const placeholderText = placeholder.show ? placeholder.text : '';
        if (!typeModelViewMode || isEditing) {
            typeModelViewMode = TypeModelDisplays.DROPDOWN;
        }
        const hasModifiedClassProperty = replaceClassProperty && typeof replaceClassProperty === 'string' && replaceClassProperty !== '' ? true : false;
        typeModelCollection = TypeCollectionData.data;
        if(itemProperties.filterField && itemProperties.filterValueField) {
            let data = this.props.formValues ? this.props.formValues : workflowData;
            
            const tmpReplaceClassProperty = hasModifiedClassProperty?replaceClassProperty.split('.'):'';
            const replaceString = itemProperties.filterValueField.substring(0, itemProperties.filterValueField.indexOf('.'));
            const tmpFilterVal = hasModifiedClassProperty? itemProperties.filterValueField.replace(replaceString, `{{${tmpReplaceClassProperty[0]}`)
                                :itemProperties.filterValueField;
           
            const filterData = ModelPropertiesParserArray(tmpFilterVal,data);
            let filteredCollection = [];
            typeModelCollection && typeModelCollection.forEach(x => {
                const currentFilterData = ModelPropertiesParser(itemProperties.filterField,x);
                if(Array.isArray(filterData)){
                  const f = filterData.find(v => v == currentFilterData);
                  if(f)
                    filteredCollection.push(x);
                } else {
                  if(currentFilterData == filterData)
                    filteredCollection.push(x);
                }
            });
            typeModelCollection = filteredCollection;
        } 
        if (typeModelViewMode.value === TypeModelDisplays.DROPDOWN.value || typeModelViewMode.value === TypeModelDisplays.CHECKBOX.value) {
            const selectOptions = typeModelCollection && typeModelCollection.map((item) => {
                return {
                    label: ModelPropertiesParser(modelSelector, item),
                    value: item.id,
                    ...item
                }
            });
            this.setInitialValue(initialValues, classProperty, modelSelector, change, hasModifiedClassProperty, replaceClassProperty);
            return (
                    typeModelViewMode.value === TypeModelDisplays.DROPDOWN.value?
                <Field
                    name={hasModifiedClassProperty ? replaceClassProperty : classProperty}
                    component={renderSelect}
                    type="select"
                    validate={validations}
                    normalize={(val)=>{
                      let newVal = val;
                      if ( newVal && newVal.class && newVal.class.indexOf('LinkedTreeMap') != -1) {
                        newVal.class = newVal.serializerClass;
                    }
                       return newVal
                    }}
                    fieldType='TYPE'
                    valueKey='value'
                    labelKey='label'
                    options={selectOptions}
                    showAsRadioButton={showAsRadioButton}
                    optionDisplayType={optionDisplayType}
                    properties={properties}
                    initialValues={initialValues}
                    change={change}
                    {...rest}
                    multiple={collection}
                    customModelSelector={modelSelector}
                    layoutItem={layoutItem}
                />:<div className="type-model-collection-checkbox-container d-flex">
                {
                    layoutItem && layoutItem.itemProperties && layoutItem.itemProperties.beforeIcon?
                    <span className="mr-2"><RenderIcon config={layoutItem.itemProperties.beforeIcon}  customIcons={customIcons} /></span>:null
                }
                <Field
                name={classProperty}
                component={renderMultipleCheckBox}
                type="checkbox"
                validate={validations}
                fieldType='checkbox'
                valueKey='id'
                labelKey='label'
                options={selectOptions}
                customIcons={customIcons}
                properties={properties}
                initialValues={initialValues}
                layoutItem={layoutItem}
                {...rest}
                multiple={collection}
                customModelSelector={modelSelector}
            /></div>
            )
        }
        if (typeModelViewMode.value === TypeModelDisplays.TABLE.value) {
          const rows = hasModifiedClassProperty ? getNewContext(workflowData, replaceClassProperty) : workflowData ? workflowData[classProperty] : [];
          const list1 = typeModelCollection ? new DataListStore(typeModelCollection) : new DataListStore([]);
          const sorting = {
            order: 'ASCENDING'
          }
          if(readOnlyTableWithSelection || readOnlyTable) {
              const size = {};
              return <div><TABLE
              size={size}
              workflow={this.props.workflow}
              rows={list1}
              columns={tableConfig.config.columns}
              user={this.props.user}
              selectedTemplate='TEMPLATE_ONE'
              dateformat=""
              pageSize={this.props.pageSize}
              sorting={sorting}
              changeVal={change}
              readOnlyTableWithSelection={readOnlyTableWithSelection}
              name={hasModifiedClassProperty ? replaceClassProperty : classProperty}
              formValues={this.props.formValues}
              hasModifiedClassProperty={hasModifiedClassProperty}
              getNewContext={getNewContext}
              readOnlyTable={readOnlyTable}
            />
            <Pagination 
                        start={this.props.paginationStart} 
                        hasNextPage={typeModelCollection ?typeModelCollection.length === this.props.pageSize : false}
                        size={this.props.pageSize}
                        onChangePage={this.props.onChangePage}
                        onChangePageSize={this.props.onChangePageSize}
                    />
            </div>
             
            }
          return <TypeModelCreateUpdateTable 
                label={label}
                workflow={this.props.workflow}
                rows={rows? rows : []}
                columns={tableConfig.config.columns}
                layoutItem={layoutItem}
                validations={validations}
                {...this.props}
            />
        }

        if (typeModelViewMode.value === TypeModelDisplays.BADGES.value) {
            return <TypeModelBadges 
                label={label}
                workflow={this.props.workflow}
                workflowData={workflowData}
                badgeType={badgeType}
                layoutItem={layoutItem}
                modelSelector={modelSelector}
            />
        }
        if(typeModelViewMode.value === TypeModelDisplays.WORKFLOWCARD.value) {
          const rows = hasModifiedClassProperty ? getNewContext(workflowData, replaceClassProperty) : workflowData ? workflowData[classProperty] : [];
          return (
            <TypeModelCreateUpdateWorkflowCard 
              rows={rows}
              classProperty={this.props.classProperty}
              readOneWorkflowUniqueCode={itemProperties.readOneWorkflowUniqueCode}
            />
          )
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    const { workflow, containerIndex, columnIndex, layoutItem: { itemProperties: { field: { classProperty, customModelName, customTypeId } } } } = ownProps;
    const newState = state.toJS();
    const modelProp = customModelName? customModelName: customTypeId;
    let typeModelCollection = null;
    if(typeof workflow.collections !== 'undefined' && workflow.collections !== null) {
      typeModelCollection = workflow.collections[modelProp + `_c${containerIndex}_${columnIndex}`];
      if(!typeModelCollection){
        typeModelCollection = workflow.collections[modelProp + `_c${containerIndex}`];
      }
    }
    return {
        typeModelCollection,
        customerName: workflow.currentItem.typeModel.customerName
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        readAll: (payload, containerId, columnId) => {
            dispatch(readAll(payload, 'c' + containerId, columnId));
        },
        getModelByNamePromise: (payload) => {
            return dispatch(getModelByNamePromise(payload));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(TypeModelCreateUpdate));

