import { FETCH_PUBLIC_NAV_WORKFLOWS, FETCH_PUBLIC_NAV_WORKFLOWS_FALIURE, FETCH_PUBLIC_NAV_WORKFLOWS_SUCCESS } from '../actions/workflows';
import { Map } from 'immutable';

const intitalState = Map({
    isLoading: false,
    data: null,
    hidden:true
});

const navigationPublic = (state = intitalState, action = {}) => {
    switch (action.type) {
        case FETCH_PUBLIC_NAV_WORKFLOWS: return state.merge({
            isLoading: true
        });
        
        case FETCH_PUBLIC_NAV_WORKFLOWS_SUCCESS: return state.merge({
            isLoading: false,
            data: action.payload.data,
            hidden:true
        });

        case FETCH_PUBLIC_NAV_WORKFLOWS_FALIURE: return state.merge({
            isLoading: false,
            data: {
                hasError: true,
                error: action.payload
            }
        });

        default: return state;
    }
};

export default navigationPublic;