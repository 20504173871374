import { Button, Input } from 'antd';
import React, { Component } from 'react'
import { UndoOutlined } from '@ant-design/icons';



export default class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            baseData:props.dataNew,
        }
   
    }

    search = value => {
        const { baseData } = this.state;
    
        const filterTable2 = baseData.filter(o =>
          Object.keys(o).some(k =>
            String(o[k])
              .toLowerCase()
              .includes(value.toLowerCase())
          )
        );
    
         this.props.filterSearchData(filterTable2)
      };

      clearFilter = () =>{
        const { baseData } = this.state;
        this.props.filterSearchData(baseData)
      }


  render() {
    return (
        <div className='filter-new-template'>
          <div className='input'>
        <Input.Search
        placeholder="Search by..."
        enterButton
        onSearch={this.search}
      />
      </div>
      <Button className='button'  onClick={this.clearFilter}>
      <UndoOutlined /> 
      Clear
      </Button>
      </div>
    )
  }
}
