import React, { useEffect, useState } from 'react';
import { FormGroup, Input, Label, FormFeedback, Button, Row } from 'reactstrap';
import client from '../../../../client';
import config from '../../../../config';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import OtpInput from 'react-otp-input';

const OTPScreen = props => {
  const [otp, setOTP] = useState('');
  const [verified, changeVerify] = useState(false);
  const [attempts, changeAttempts] = useState(3);
  const [resendButton, changeResendButton] = useState(false);
  const [invalidOTP, setInvalidOTP] = useState(false);
  const [key, setKey] = useState(0);

  useEffect(() => {
    if (!verified) {
      setTimeout(() => {
        changeResendButton(true);
      }, 121000);
       generateOTP();
    }
  }, [])

  const generateOTP = () => {


    const payload = {
      phoneNumber: props.phoneNumber
    }
    client().post(`${config.publicAPI}/generateotp`, payload).then((res) => {
      changeVerify(true);
    }).catch((error) => {
      console.log("Error", error);
      changeVerify(false);
    });
    changeResendButton(false);
    setKey(prevKey => prevKey + 1)
    setTimeout(() => {
      changeResendButton(true);
    }, 121000);
  }

  const renderTime = ({ remainingTime }) => {
    const minutes = Math.floor(remainingTime / 60)
    const seconds = remainingTime % 60
    if (remainingTime === 0) {
      return <div className="timer"> </div>;
    }
    return (
      <div className="timer">
        <div className="value">{`${minutes}:${seconds}`}</div>
      </div>
    );
  };

  const verifyOTP = () => {
    const { phoneNumber, requiredRole, authenticationCallback } = props;
    const payload = {
      isSessionRequired: true,
      otp,
      phoneNumber,
      requiredRole,
    };
    client()
      .post(`${config.publicAPI}/verifyotp`, payload)
      .then(res => {

        console.log(res.data);
        if (res.data.code === 200) {
          let user = localStorage.getItem('user');
          user = typeof user === 'string' && JSON.parse(user);
          const newUser = {
            token: res.data.latestToken,
            ...res.data.data,
          };

          if (res.data.hasError) {
            setInvalidOTP(true)
          } else {
            if (user.token != res.data.latestToken) {
              // localStorage.setItem('user', JSON.stringify(newUser));
              window.user = newUser;
            }
            authenticationCallback();
          }
        } else {
          let user = localStorage.getItem('user');
          user = typeof user === 'string' ? JSON.parse(user) : user;
          window.user = user;
          setInvalidOTP(true);
        }

      })
      .catch(error => {
        setInvalidOTP(true);
      });
  };


  return (
    <div>
      <FormGroup>
        <Label for={'password'}>One-Time-Password</Label>
        {/* <Input
          value={otp}
          type='text'
          onChange={event => setOTP(event.target.value)}
          placeholder={'Enter OTP'}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              event.stopPropagation();
              verifyOTP();
            }
          }}
        /> */}
        <OtpInput
                value={otp}
                isInputNum={true}
                isInputSecure={true}
                onChange={(otp) => {
                  setOTP(otp);
                }}
                numInputs={6}
                inputStyle={{
                  width: '40px',
                  height: '40px',
                  paddingLeft: 0
                }}
                containerStyle={{
                  marginTop: '30px',
                  justifyContent: 'space-around',
                  padding: '0 5%'
                }}
                separator={<span className="otp-spacer"> </span>}
              />
      </FormGroup>
      {invalidOTP && <div style={{ color: '#f00' }}>Invalid OTP</div>}
      <div className='d-flex justify-content-end template3LoginButton'>
        <Button
          size='sm'
          className='pr-2 pl-2'
          color='primary'
          onClick={verifyOTP}
          disabled={otp.length !== 6}
        >
          <i className='fa fa-lock pr-2' aria-hidden='true'></i>
          Verify
        </Button>
      </div>
      <div>
       <Row style={{ marginTop: 10, marginLeft: 10, marginBottom: -10, alignItems: "center", justifyContent: "center" }}>
          <span style={{ color: "red", marginRight: 10, fontSize: 14,display:'flex' }}>{`Didn't get the code ?`}  <Button className='pr-2 pl-2 btn btn-link otpResendButton' onClick={() => generateOTP()} disabled={!resendButton} size="sm">Click to resend</Button></span>
          {!resendButton ?   <CountdownCircleTimer
            isPlaying
            strokeLinecap="square"
            key={key}
            size={50}
            strokeWidth={3}
            duration={120}
            colors={[
              ['#004777', 0.33],
              ['#F7B801', 0.33],
              ['#A30000', 0.33],
            ]}
          >
            {renderTime}
          </CountdownCircleTimer>:<div className='noTimerDivOTP'></div>}
        </Row>
      </div>
      
    </div>
  );
};

export default OTPScreen;
