import React, { Component } from 'react';
import * as CardTemplates from '../templates/card/index';
import { Row, Col, Alert } from 'reactstrap';
import { ModelPropertiesParser } from '../commons/modelPropertiesParser';
import NoDataAlertBox from '../commons/NoDataAlertBox';

const getCardData = (key, data) => {
    let _key = null;
    if (typeof key === 'string') {
        _key = key;
    }
    if (typeof key === 'object' && key.hasOwnProperty('path')) {
        _key = key.path;
    }
    if (_key !== null) {
        return ModelPropertiesParser(_key, data);
    }
    return 'Not Available';
}

class CardLayout extends Component {
    render() {
        const { cardConfig, cards, inlineActions, workflowId } = this.props;
        if (!cardConfig) return <Alert color='warning'>Looks like the Card Layout has not been configured. Please try switching to the Table layout.</Alert>
        const { template, config } = cardConfig;
        const LoadTemplate = CardTemplates[template.value];
        const isFullWidth = config.hasOwnProperty('isFullWidth') && config.isFullWidth === true ? true : false;
        return (
            <MakeCards {...this.props} LoadTemplate={LoadTemplate} isFullWidth={isFullWidth} />
        )
    }
}


const MakeCards = (props) => {
    const { LoadTemplate, isFullWidth, cardConfig, cards, workflow: { currentItem: { typeModel: { name } } }, noDataText,noDataTextHeading } = props;
    const { config } = cardConfig;
    const _CARDS = [];
    const cardsSize = cards.getSize();
    if (!cardsSize || cardsSize === 0) {
        return <NoDataAlertBox typeModelName={name} noDataText={noDataText} noDataTextHeading={noDataTextHeading} {...props} />
    }
    const xs = isFullWidth ? '12' : '12';
    const md = isFullWidth ? '12' : (config.cardSize?(parseInt(config.cardSize)*3/2) + '': '6');
    const lg = isFullWidth ? '12' : (config.cardSize?config.cardSize: '4');
    for (let i = 0; i < cardsSize; i += 1) {
        _CARDS.push(
            <Col xs={xs} md={md} lg={lg} key={i} className={`mb-4 ${isFullWidth ? 'player-card-full-width-container' : 'player-card-container'}`}>
                <LoadTemplate
                    title={getCardData(config.cardTitle, cards.getObjectAt(i))}
                    subtitle={getCardData(config.cardSubtitle, cards.getObjectAt(i))}
                    description={getCardData(config.cardDescription, cards.getObjectAt(i))}
                    label={getCardData(config.cardLabel, cards.getObjectAt(i))}
                    data={cards.getObjectAt(i)}
                    image={getCardData(config.cardImage, cards.getObjectAt(i))}
                    {...props}
                />
            </Col>)
    }
    return <Row className={config.isTimelineView ? 'timeline-view' : ''}>
        {_CARDS}
    </Row>

}

export default CardLayout;