import { connect } from 'react-redux';
import ForgotPassword from '../components/pages/login/ForgotPassword';
import { getTranslate } from 'react-localize-redux';
import { toJS } from './to-js';
import { resetPassword, resetForgotPasswordState } from '../actions/user';
import { getCompanyDetails } from '../actions/company';

const mapStateToProps = (state) => {
    return {
        forgotPasswordState: state.getIn(['user', 'resetPassword']),
        translate: getTranslate(state.get('locale')),
		companyDetails: state.getIn(['company', 'details']),
        user: state.get('user'),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSubmit: (values) => {
            let reCaptchaDetails = localStorage.getItem("_captcha_details_forgot");
            reCaptchaDetails = JSON.parse(reCaptchaDetails);
            if(!reCaptchaDetails) {
              localStorage.setItem("_captcha_details_forgot", JSON.stringify({
                count: 0,
                date: new Date()
              }));
            } else {
              const lastSetDate = reCaptchaDetails.date;
              const currentDate = new Date();
              let diff = Math.abs(currentDate - lastSetDate);
              let minutes = Math.floor((diff/1000)/60);
              if(minutes > 30) {
                localStorage.setItem("_captcha_details_forgot", JSON.stringify({
                  count: 0,
                  date: new Date()
                }));
              } else {
                localStorage.setItem("_captcha_details_forgot", JSON.stringify({
                  count: reCaptchaDetails.count + 1,
                  date: new Date()
                }));
              }
      
            }
            dispatch(resetPassword(values));
            
        },
        resetForgotPasswordState: () => {
            dispatch(resetForgotPasswordState());
        },
		getCompanyDetails: () => {
            dispatch(getCompanyDetails());
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(ForgotPassword));